/**
 * @flow
 */
import React from 'react';

import {
    PageTitle,
  } from '../../../components';

import {
    Form,
    ContentGlobal,
    ActualTask,
    TitleBlock,
    Heading,
    ContentTask,
    HeaderTask,
    TypeTask,
    TypeNumber,
    InfoTask,
    TypeBlock,
    ExucutorTask,
    CarNumberContent,
    FlagNumber,
    CarNumber,
    Content,
    Paragraph,
    StatusFooter,
    ContentInfo,
    Loader,
 } from './ActualTaskStyled';

import {

} from '../../../components/index';

type ActualTaskPropsType = {
    counterTask: any,
    nameTask: any,
    openTask: (any) => void,
    isLoading: Boolean,
};

const renderCustList = ( counterTask, nameTask, openTask ) => (counterTask.map(item => (
    <ActualTask onClick={() => openTask(item.id)}>
        {console.log('counterTask', counterTask)}
        <div>
        <HeaderTask>
            <TypeBlock>
                {
                    (item.type === 'Погрузка сырья')
                    ? (
                        <TypeTask loadingMaterials>{item.type}</TypeTask>
                    ) : (item.type === 'Разгрузка товара')
                    ? (
                        <TypeTask unLoadingMaterials>{item.type}</TypeTask>
                    ) : (item.type === 'Водитель')
                    ? (
                        <TypeTask driver>{item.type}</TypeTask>
                    ) :
                    null
                }
            </TypeBlock>
            <TypeNumber>№{item.number}</TypeNumber>
        </HeaderTask>
        <InfoTask>
            <Content>
                <ContentInfo>
                    {
                        (item.type === 'Водитель')
                        ? (
                            <div style={{width: '65%'}}>
                                <Paragraph>{nameTask[`place.address`]}</Paragraph>
                                <Paragraph fontWord>
                                    {
                                        (item[`place.address`])
                                        ? (
                                            item[`place.address`]
                                        ) :
                                        'нет данных'
                                    }
                                </Paragraph>
                            </div>
                        ) :
                        <Content>
                          <div>
                              <Paragraph>Название задачи/продукта:</Paragraph>
                              <Paragraph fontWord>
                                  {
                                      (item[`requestProduct.name`])
                                          ? (
                                              item[`requestProduct.name`]
                                          ) :
                                          'нет данных'
                                  }
                              </Paragraph>
                          </div>
                        </Content>
                    }
                    <div>
                        <div>
                            <Paragraph position>{nameTask[`car.number`]}</Paragraph>
                        </div>
                        <CarNumberContent>
                            <FlagNumber>
                                <img
                                    src={require('../../../assets/icons/flag.svg')}
                                    alt="flag"
                                />
                            </FlagNumber>
                            <CarNumber>
                                {
                                    (item[`car.number`])
                                        ? (
                                            item[`car.number`]
                                        ) :
                                        'нет данных'
                                }
                            </CarNumber>
                        </CarNumberContent>
                    </div>
                </ContentInfo>
            </Content>
            <Content>
              <div>
                <Paragraph>Исполнитель:</Paragraph>
                <Paragraph fontWord>
                  {item[`executor.firstName`]} {item[`executor.lastName`]}
                </Paragraph>
              </div>
            </Content>
            {
                (item.type !== 'Водитель')
                ? (
                    <div>
                        <Content>
                            <Paragraph>Целевой адрес:</Paragraph>
                            <Paragraph fontWord>
                                {
                                (item[`place.address`])
                                    ? (
                                        item[`place.address`]
                                    ) :
                                    'нет данных'
                                }
                            </Paragraph>
                        </Content>
                        <Content>
                            <Paragraph>Описание задачи:</Paragraph>
                            <Paragraph fontWord>
                                {
                                (item.description)
                                    ? (
                                        item.description
                                    ) :
                                    'нет данных'
                                }
                            </Paragraph>
                        </Content>
                        {
                            (item.type === 'Погрузка сырья')
                            ? (
                                <Content>
                                    <Paragraph>Номер доверенности:</Paragraph>
                                    <Paragraph fontWord>
                                        {
                                        (item.powerOfAttorneyNumber)
                                            ? (
                                                item.powerOfAttorneyNumber
                                            ) :
                                            'нет данных'
                                        }
                                    </Paragraph>
                                </Content>
                            ) : (
                                <Content>
                                    <Paragraph>Номер ТТН:</Paragraph>
                                    <Paragraph fontWord>
                                        {
                                        (item.ttn)
                                            ? (
                                                item.ttn
                                            ) :
                                            'нет данных'
                                        }
                                    </Paragraph>
                                </Content>
                            )
                        }
                    </div>
                ) :
                (
                    <Content>
                        <Paragraph>Описание задачи:</Paragraph>
                            <Paragraph fontWord>
                                {
                                    (item.description)
                                        ? (
                                            item.description
                                        ) 
                                        : 'нет данных'
                                }
                            </Paragraph>
                    </Content>
                )
            }
            <Content >
                <div>
                    <Paragraph>Статус задачи:</Paragraph>
                    <Paragraph fontWord>
                        {item.status}
                    </Paragraph>
                </div>
            </Content>
            <Content >
                <div>
                    <Paragraph>Дата создания задачи:</Paragraph>
                    <Paragraph fontWord>
                    {item.created}
                    </Paragraph>
                </div>
            </Content>
            <Content >
                <div>
                    <Paragraph>Дата исполнения задачи:</Paragraph>
                    <Paragraph fontWord>
                    {(item.execution) ? item.execution : ''}
                    </Paragraph>
                </div>
            </Content>
            <Content creator>
                <div>
                    <Paragraph>Создатель задачи:</Paragraph>
                    <Paragraph fontWord>
                        {item[`creator.firstName`]} {item[`creator.lastName`]}
                    </Paragraph>
                </div>
            </Content>
        </InfoTask>
        </div>
    </ActualTask>
)));

export default function ActualTaskView(props: ActualTaskPropsType) {
    if (props.isLoading) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: '50%',
              left: '50%',
            }}
          >
            <Loader className='lds-circle' />
          </div>
        );
      }
    return (
        <ContentGlobal>
            <TitleBlock>
                <PageTitle dashboard>
                    Актуальные задачи
                </PageTitle>
            </TitleBlock>
            <ContentTask>
                {renderCustList(props.counterTask, props.nameTask, props.openTask)}
            </ContentTask>
        </ContentGlobal>
    );
}
