import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const Button = styled.button`
  box-sizing: border-box;
  width: auto;
  background-color: ${colors.primary};
  color: ${colors.white};
  border: none;
  border-radius: 6px;
  font-size: ${base + 2}px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  ${(props) => {
    switch (props.mode) {
      case 'blue':
        return css`
          background-color: ${colors.primary};
          border: 1px solid ${colors.primary};
          padding: 12px 24px;
          width: auto;
          font-size: ${base + 2}px;
        `;
      case 'white':
        return css`
          background-color: ${colors.white};
          color: ${colors.primary};
          border: 1px solid ${colors.primary};
          padding: 12px 24px;
          width: auto;
          font-size: ${base + 2}px;
        `;
      default:
        return css``;
    }
  }}
  ${props => props.maxWidth && css`
    width: 100%;
  `}
  ${props => props.circle && css`
    padding: 0;
    justify-content: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    font-size: 20px;

    & > span{
      text-align: center;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
    }
  `}
  ${props => props.iconExpand && css`
    width: 38px;
    height: 38px;
    border-radius: 6px;
  `}
  ${props => props.plus && css`
    background-color: ${colors.primary};
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25%;
  `}
  ${props => props.secondary && css`
    background-color: white;
    border: 1px solid ${colors.primary};
    color: ${colors.primary};
  `}

  ${props => props.cross && css`
    width: 100%;
    background-color: transparent;
    justify-content: center;
    background: url(${require('../../assets/icons/close-default.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25%;
    margin: 12px 12px;
  `}

  ${props => props.crossHover && css`
    width: 100%;
    background-color: transparent;
    justify-content: center;
    background: url(${require('../../assets/icons/close-hover.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25%;
  `}

  ${props => props.rectangle && css`
    height: ${base * 2.5}px;
    width: ${base * 10}px;
    padding: 0;
    background-color: ${colors.white};
    color: ${colors.black};
  `}
`;
