/**
 * @flow
 */
import React from 'react';
import Calendar from 'rc-calendar';
import ruRu from 'rc-calendar/lib/locale/ru_RU';
import moment from 'moment';

import InputMask from 'react-input-mask';
import { InputComponent, InputWrapper } from '../Input/InputStyled';
import {
  Form,
  ContainerTitle,
  ContainerValues,
  ContainerValue,
  ValueTitle,
  ValueOptions,
  OptionValues,
  TitleContainer,
  TitleText,
  TitleButton,
  CalcValue,
  StateContainer,
  StateButtons,
  StateButton,
  BasicInformationContainer,
  CargoInformationContainer,
  AdditionalServicesContainer,
  CountingContainer,
  AutocompleteItem,
  CalendarContainer,
  OuterFormCheckboxWrapper,
  InputData,
  InputTime,
} from './RequestFormStyles';

import {
  Input,
  Button,
  SelectDropDown,
  SelectDropDownItem,
  Checkbox,
  InputMaskAndCalendar,
  ModaleWindowPayerInfo,
  PageTitle,
} from '../index';

import { RequestsService, PaymentsService } from '../../services';

type RequestFormPropsType = {
  edit: boolean,
  state: String,
  returnToTheLastPage: () => void,
  save: () => void,
  update: () => void,
  accesses: Object,
  fieldsValue: Object,
  total: Number,
  payments: Array<Object>,
  autocompleteValues: Object,
  statusLoadCalendar: boolean,
  paymentDateCalendar: boolean,
  statusCustomerAccountDateCalendar: boolean,
  statusIssueCalendar: boolean,
  statusArrivalCalendar: boolean,
  stateClaim: string,
  statusPlannedIssueDateCalendar: boolean,
  selectedFieldValue: (any, any) => void,
  changeField: (string, string, any) => void,
  clearAutocompleteValues: () => void,
  setStatusLoadCalendar: (boolean) => void,
  setStatusArrivalCalendar: (boolean) => void,
  setStatusIssueCalendar: (boolean) => void,
  setStatusCustomerAccountDateCalendar: (boolean) => void,
  setStateClaim: (string) => void,
  changePaymentFields: (string, string, string) => void,
  setPaymentDateCalendar: (boolean) => void,
  setPayments: (Array<any>) => void,
  setStatusPlannedIssueDateCalendar: (boolean) => void,
  statusWindow?: boolean,
  createFormModal: (any, any) => void,
  nameForm: string,
  accessesHeader: any,
};

export default function RequestFormView(props: RequestFormPropsType) {
  const numberWithCommas = (x) => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '');
    if (parts[1]) {
      parts[1] = parts[1].slice(0,2);
    }
    return parts.join('.');
  };
  const autocompleteValues = {};
  const formatStr = 'YYYY-MM-DD HH:mm:ss';
  const changeFormatDate = (strDate) => {
    const changedDateArray = strDate.split('-');
    const [year, month, day] = strDate.split('-');
    return [day, month, year].join('-');
  };
  const setCalendarValue = (value) => {
    if (value === '' || value.indexOf('_') > -1) return moment(new Date());
    if (value !== undefined || value.indexOf('_') === -1) return moment(new Date(changeFormatDate(value)));
  };

  Object.keys(props.autocompleteValues).forEach((key) => {
    autocompleteValues[key] = [];
    props.autocompleteValues[key].forEach((value) => {
      autocompleteValues[key].push(
        <OptionValues
          key={value}
          onClick={() => { props.selectedFieldValue(key, value); }}
        >
          {value}
        </OptionValues>
      );
    });
  });
  return (
    <Form onClick={(e) => {
      if (e.target.tagName !== 'INPUT') {
        props.clearAutocompleteValues();
      }
    }}
    >

      <TitleContainer>
        <PageTitle dashboard>
          {(props.edit) ? `Редактирование задачи №${props.fieldsValue.number}` : 'Новая задача'}
        </PageTitle>
        {
          props.edit
            ? (
              <div style={{display: 'flex'}}>
                <TitleButton>
                  <Button mode="white" clicked={() => props.returnToTheLastPage()}>Вернуться</Button>
                </TitleButton>
                <TitleButton>
                  <Button mode="blue" clicked={() => props.update()}>Обновить</Button>
                </TitleButton>
              </div>
            ) : (
              <TitleButton>
                <Button mode="blue" clicked={() => props.save()}>Сохранить</Button>
              </TitleButton>
            )
        }
      </TitleContainer>

       <BasicInformationContainer id="customerservice">
         <ContainerValues>
          {/* {console.log('accessesHeader', props.accessesHeader)}
          {console.log('field', props.fieldsValue)}
          {console.log('executionData', props.executionData)} */}

         {(Object.keys(props.accessesHeader).length > 0 && Object.keys(props.fieldsValue).length > 0 && props.accessesHeader.type)
             ? (
               <ContainerValue>
                 <ValueTitle>Тип задачи:</ValueTitle>
                 <SelectDropDown
                   placeholder="Выберите тип"
                   selectedValue={props.fieldsValue.type}
                   clicked={() => {
                     props.clearAutocompleteValues();
                   }}
                 >
                   <SelectDropDownItem
                     title="Погрузка сырья"
                     selectOption={() => props.changeField('type', 'Погрузка сырья', 'select', 'type')}
                   />
                   <SelectDropDownItem
                     title="Разгрузка товара"
                     selectOption={() => props.changeField('type', 'Разгрузка товара', 'select', 'type')}
                   />
                   <SelectDropDownItem
                     title="Водитель"
                     selectOption={() => props.changeField('type', 'Водитель', 'select', 'type')}
                   />
                 </SelectDropDown>
               </ContainerValue>
             )
            : null
          }
          {(props.edit)
            ? (
                  <ContainerValue>
                    <ValueTitle>Дата, время создания:</ValueTitle>
                      <Input
                        type="text"
                        placeholder="Введите данные..."
                        value={moment(props.fieldsValue.created).format('DD-MM-YYYY HH:mm:ss')}
                        changed={(value) => { props.changeField('created', value, 'input-text', 'created'); }}
                        active={() => {
                          props.clearAutocompleteValues();
                        }}
                        readOnlyState
                      />
                  </ContainerValue>
              ) 
            : null
          }
            <ContainerValue>
              <ValueTitle>Дата исполнения:</ValueTitle>
              <InputWrapper>
                <InputData type="date" value={props.executionDate} onChange={(event) => {
                    props.setExecutionDate(event.target.value);
                    const dateTime = `${event.target.value} ${props.executionTime}`;
                    props.changeField('execution', dateTime, 'input-date');
                  }}
                />
              </InputWrapper>
            </ContainerValue>
            <ContainerValue>
              <ValueTitle>Время исполнения:</ValueTitle>
              <InputWrapper>
                <InputTime type="time" value={props.executionTime} onChange={(event) => {
                    props.setExecutionTime(event.target.value);
                    const dateTime = `${props.executionDate} ${event.target.value}`;
                    props.changeField('execution', dateTime, 'input-date');
                  }}
                />
              </InputWrapper>
            </ContainerValue>

            {(Object.keys(props.accessesHeader).length > 0 && Object.keys(props.fieldsValue).length > 0 && props.accessesHeader[`executor.firstName`])
             ? (
               <ContainerValue>
                 <ValueTitle>Исполнитель:</ValueTitle>
                 <Input
                   type="text"
                   placeholder="Выберите пункт..."
                   value={props.fieldsValue.executor.name}
                   changed={(value) => { props.changeField('executor', value, 'autocomplete', 'executor' ); }}
                   active={() => {
                     props.changeField('executor', props.fieldsValue.executor.name, 'autocomplete', 'executor');
                   }}
                 />
                 {(props.autocompleteValues.executor && props.autocompleteValues.executor.length)
                   ? (
                     <ValueOptions>
                       {
                         props.autocompleteValues.executor.map(value => (
                           <AutocompleteItem
                             key={value._id}
                             onClick={() => {
                               props.selectedFieldValue('executor', value);
                             }}
                           >
                             {value.lastName}
                           </AutocompleteItem>
                         ))
                       }
                     </ValueOptions>
                   )
                   : null }
                 {(props.autocompleteValues.executor && props.autocompleteValues.executor.length === 0) && (
                   <ValueOptions>
                     <AutocompleteItem>
                       Ни одного справочника не найдено
                     </AutocompleteItem>
                   </ValueOptions>
                 )}
               </ContainerValue>
             )
             : null
           }

         {(Object.keys(props.accessesHeader).length > 0 && Object.keys(props.fieldsValue).length > 0 && props.accessesHeader[`place.name`])
             ? (
               <ContainerValue>
                 <ValueTitle>Место:</ValueTitle>
                 <Input
                   type="text"
                   placeholder="Выберите пункт..."
                   value={props.fieldsValue.place.name}
                   changed={(value) => { props.changeField('place', value, 'autocomplete', 'place' ); }}
                   active={() => {
                     props.changeField('place', props.fieldsValue.place.name, 'autocomplete', 'place');
                   }}
                 />
                 {(props.autocompleteValues.place && props.autocompleteValues.place.length)
                   ? (
                     <ValueOptions>
                       {
                         props.autocompleteValues.place.map(value => (
                           <AutocompleteItem
                             key={value._id}
                             onClick={() => {
                               props.selectedFieldValue('place', value);
                             }}
                           >
                             {value.address}
                           </AutocompleteItem>
                         ))
                       }
                     </ValueOptions>
                   )
                   : null }
                 {(props.autocompleteValues.place && props.autocompleteValues.place.length === 0) && (
                   <ValueOptions>
                     <AutocompleteItem>
                       Ни одного справочника не найдено
                     </AutocompleteItem>
                   </ValueOptions>
                 )}
               </ContainerValue>
             )
             : null
           }

         {(Object.keys(props.accessesHeader).length > 0 && Object.keys(props.fieldsValue).length > 0 && props.accessesHeader[`car.name`])
             ? (
               <ContainerValue>
                 <ValueTitle>Машина:</ValueTitle>
                 <Input
                   type="text"
                   placeholder="Выберите пункт..."
                   value={props.fieldsValue.car.name}
                   changed={(value) => { props.changeField('car', value, 'autocomplete', 'car' ); }}
                   active={() => {
                     props.changeField('car', props.fieldsValue.car.name, 'autocomplete', 'car');
                   }}
                 />
                 {(props.autocompleteValues.car && props.autocompleteValues.car.length)
                   ? (
                     <ValueOptions>
                       {
                         props.autocompleteValues.car.map(value => (
                           <AutocompleteItem
                             key={value._id}
                             onClick={() => {
                               props.selectedFieldValue('car', value);
                             }}
                           >
                             {value.number}
                           </AutocompleteItem>
                         ))
                       }
                     </ValueOptions>
                   )
                   : null }
                 {(props.autocompleteValues.car && props.autocompleteValues.car.length === 0) && (
                   <ValueOptions>
                     <AutocompleteItem>
                       Ни одного справочника не найдено
                     </AutocompleteItem>
                   </ValueOptions>
                 )}
               </ContainerValue>
             )
             : null
           }

           {(props.accessesHeader.description)
             ? (
               <ContainerValue>
                 <ValueTitle>Описание задачи:</ValueTitle>
                 <Input
                   type="text"
                   placeholder="Введите данные..."
                   value={props.fieldsValue.description}
                   changed={(value) => { props.changeField('description', value, 'input-text', 'description'); }}
                   active={() => {
                     props.clearAutocompleteValues();
                   }}
                 />
               </ContainerValue>
             )
             : null
           }
          {(props.fieldsValue.type !== 'Водитель' && props.fieldsValue.type !== 'Разгрузка товара')
           ? (
           (props.accessesHeader.powerOfAttorneyNumber)
             ? (
               <ContainerValue>
                 <ValueTitle>Номер доверенности:</ValueTitle>
                 <Input
                   type="text"
                   placeholder="Введите данные..."
                   value={props.fieldsValue.powerOfAttorneyNumber}
                   changed={(value) => { props.changeField('powerOfAttorneyNumber', value, 'input-text', 'powerOfAttorneyNumber'); }}
                   active={() => {
                     props.clearAutocompleteValues();
                   }}
                 />
               </ContainerValue>
             )
             : null
           )
           : null
           }
          {(props.fieldsValue.type !== 'Водитель')
          ? (
            (Object.keys(props.accessesHeader).length > 0 && Object.keys(props.fieldsValue).length > 0 && props.accessesHeader[`requestProduct.name`])
              ? (
                <ContainerValue>
                  <ValueTitle>Продукты:</ValueTitle>
                  <Input
                    type="text"
                    placeholder="Выберите пункт..."
                    value={props.fieldsValue.requestProduct.name}
                    changed={(value) => { props.changeField('requestProduct', value, 'autocomplete', 'requestProduct' ); }}
                    active={() => {
                      props.changeField('requestProduct', props.fieldsValue.requestProduct.name, 'autocomplete', 'requestProduct');
                    }}
                  />
                  {(props.autocompleteValues.requestProduct && props.autocompleteValues.requestProduct.length)
                    ? (
                      <ValueOptions>
                        {
                          props.autocompleteValues.requestProduct.map(value => (
                            <AutocompleteItem
                              key={value._id}
                              onClick={() => {
                                props.selectedFieldValue('requestProduct', value);
                              }}
                            >
                              {value.name}
                            </AutocompleteItem>
                          ))
                        }
                      </ValueOptions>
                    )
                    : null }
                  {(props.autocompleteValues.requestProduct && props.autocompleteValues.requestProduct.length === 0) && (
                    <ValueOptions>
                      <AutocompleteItem>
                        Ни одного справочника не найдено
                      </AutocompleteItem>
                    </ValueOptions>
                  )}
                </ContainerValue>
              )
              : null
            )
            : null
          }
          {(props.fieldsValue.type !== 'Водитель')
           ? (
            (props.accessesHeader.requestCountQuantity)
              ? (
                <ContainerValue>
                  <ValueTitle>Количество продуктов:</ValueTitle>
                  <Input
                    type="text"
                    placeholder="Введите данные..."
                    value={props.fieldsValue.requestCountQuantity}
                    changed={(value) => { props.changeField('requestCountQuantity', value, 'input-text', 'requestCountQuantity'); }}
                    active={() => {
                      props.clearAutocompleteValues();
                    }}
                  />
                </ContainerValue>
              )
              : null
           )
           : null
          }
          {(props.fieldsValue.type !== 'Водитель')
           ? (
           (props.accessesHeader.requestCountWeight)
             ? (
               <ContainerValue>
                 <ValueTitle>Вес продуктов, кг:</ValueTitle>
                 <Input
                   type="text"
                   placeholder="Введите данные..."
                   value={props.fieldsValue.requestCountWeight}
                   changed={(value) => { props.changeField('requestCountWeight', value, 'input-text', 'requestCountWeight'); }}
                   active={() => {
                     props.clearAutocompleteValues();
                   }}
                 />
               </ContainerValue>
             )
             : null
           )
           : null
           }
          {(props.fieldsValue.type === 'Разгрузка товара')
          ? (
            (props.accessesHeader.requestPriceAll)
                ? (
                  <ContainerValue>
                    <ValueTitle>Общая сумма, руб.:</ValueTitle>
                    <Input
                      type="text"
                      placeholder="Введите данные..."
                      value={numberWithCommas(props.fieldsValue.requestPriceAll)}
                      changed={(value) => { props.changeField('requestPriceAll', value, 'input-text', 'requestPriceAll'); }}
                      active={() => {
                        props.clearAutocompleteValues();
                      }}
                    />
                  </ContainerValue>
                )
                : null
            )
            : null
          }

          {(props.fieldsValue.type === 'Разгрузка товара')
          ? (
            (props.accessesHeader.requestPriceNds)
              ? (
                <ContainerValue>
                  <ValueTitle>НДС, руб.:</ValueTitle>
                    <Input
                      type="text"
                      placeholder="Введите данные..."
                      value={props.fieldsValue.requestPriceNds}
                      changed={(value) => { props.changeField('requestPriceNds', value, 'input-text', 'requestPriceNds'); }}
                      active={() => {
                        props.clearAutocompleteValues();
                      }}
                      readOnlyState
                    />
                  </ContainerValue>
                )
                : null
            )
          : null
          }
          {(props.fieldsValue.type === 'Разгрузка товара')
          ? (
            (props.accessesHeader.ttn)
              ? (
                <ContainerValue>
                  <ValueTitle>ТТН:</ValueTitle>
                    <Input
                      type="text"
                      placeholder="Введите данные..."
                      value={props.fieldsValue.ttn}
                      changed={(value) => { props.changeField('ttn', value, 'input-text', 'ttn'); }}
                      active={() => {
                        props.clearAutocompleteValues();
                      }}
                    />
                  </ContainerValue>
                )
                : null
            )
          : null
          }

         </ContainerValues>
       </BasicInformationContainer>
    </Form>
  );
}

RequestFormView.defaultProps = {
  statusWindow: false,
};
