import styled, { css } from 'styled-components';

import {
    base,
    colors,
    screens,
} from '../../../styles';

export const ContentGlobal = styled.div`
    width: 100%;
`;
export const HeaderTask = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (max-width: ${screens.screen_2xs_min}) {
   flex-direction: column;
  }
`;

export const Loader = styled.div`
  display: none;
  transform: translateZ(1px);
  margin-left: 20px;
  display: inline-block;
  width: ${base + 4}px;
  height: ${base + 4}px;
  border-radius: 50%;
  background: #2E83EC;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  opacity: 0.65;
  @keyframes lds-circle {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
      }
    }
`;

export const TypeTask = styled.div`
  display: inline-block;
  border-radius: 0px 4px 4px 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  padding: 6px 22px;
  color: white;
  ${props => props.loadingMaterials && css`
    background: #4a90e8;
  `}
  ${props => props.unLoadingMaterials && css`
    background: #f57d4f;
  `}
  ${props => props.driver && css`
    background: #b253d6;
  `}
`;
export const TypeNumber = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  padding: 0 22px;
  @media (max-width: ${screens.screen_2xs_min}) {
    margin-top: 12px;
   }
`;
export const InfoTask = styled.div`
  padding: 0 22px;
`;
export const ExucutorTask = styled.div`
  padding: 0 12px;
`;
export const TypeBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const CarNumber = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;
export const Paragraph = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 4px;
  ${props => props.fontWord && css`
    color: #252525;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  `}
  @media (max-width: ${screens.screen_2xs_min}) {
    ${props => props.position && css`
    text-align: left;
  `}
  }
`;
export const FlagNumber = styled.div`
  margin-right: 4px;
`;
export const StatusFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 100%;
  background: #4a90e8;
  border-radius: 0 0 12px 12px;
  color: white;
  ${props => props.status === 'Необходимо сделать' && css`
    background: #40516e;
  `}
  ${props => props.status === 'В работе' && css`
    background: #0051cd;
  `}
  ${props => props.status === 'Выполнена' && css`
    background-color: #008859;
  `}
`;
export const Content = styled.div`
  margin-bottom: 12px;
  ${props => props.creator && css`
    margin-top: 20px;
    opacity: 0.5;
  `}
`;
export const CarNumberContent = styled.div`
  display: flex;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
`;

export const ActualTask = styled.div`
    width: calc(50% - 24px);
    border-radius: 12px;
    margin: 0 24px 24px 0;
    padding: 24px 0;
    cursor: pointer;
    box-shadow: 0 0 24px 0 #dedede;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const ContentTask = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
`;
export const ContentInfo = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${screens.screen_2xs_min}) {
      display: flex;
      flex-direction: column;
    }
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
  @media (min-width: ${screens.screen_xs_max}) {
    margin-top: -12px;
  }
`;
