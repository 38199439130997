import {
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'recompose';

import { connect } from 'react-redux';

import CustColumnsView from './CustColumnsView';

// services
import UserService from '../../../../services/UserService';

// actions
import { saveUserData } from '../../../../store/actions/auth';
import {
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setSecondaryBarItems,
} from '../../../../store/actions/dashboard';
import { REQUEST_FIELDS as CustListFull } from '../../../../constants';

export default compose(
  withState('custList', 'setCustList', []),
  withState('saveName', 'setSaveName', []),
  withState('custSaved', 'setCustSaved', false),
  connect(
    state => ({
      user: state.auth.currentUser,
      userAccesses: state.auth.userAccesses,
    }),
    dispatch => ({
      saveUserData: data => dispatch(saveUserData(data)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
    }),
  ),
  withHandlers({
    selectAllInSection: props => (sectionName, value) => {
      const newCustList = [...props.custList];
      const newSaveName = [];
      newCustList.forEach((item) => {
        item.items.forEach((itemCheckBox) => {
          if (item.name !== sectionName && itemCheckBox.value) {
            newSaveName.push(itemCheckBox.name);
            return;
          }
          if (item.name !== sectionName) return;
          itemCheckBox.value = value;
          if (itemCheckBox.value) {
            newSaveName.push(itemCheckBox.name);
          } else if (newSaveName.indexOf(itemCheckBox.name) !== -1) {
            newSaveName.splice(newSaveName.indexOf(itemCheckBox.name), 1);
          }
        });
      });
      props.setCustList(newCustList);
      props.setSaveName(newSaveName);
    },
    openProfileHandler: props => () => {
      props.history.replace('/dashboard/profile/personalInfo');
    },
    setCheckValue: props => (itemCheck) => {
      const newCustList = props.custList;
      const newSaveName = [];
      newCustList.forEach((item) => {
        item.items.forEach((itemCheckBox) => {
          if (itemCheck === itemCheckBox.name) {
            itemCheckBox.value = !itemCheckBox.value;
          }
          if (itemCheckBox.value) {
            newSaveName.push(itemCheckBox.name);
          } else if (newSaveName.indexOf(itemCheckBox.name) !== -1) {
            newSaveName.splice(newSaveName.indexOf(itemCheckBox.name), 1);
          }
        });
      });
      props.setCustList(newCustList);
      props.setSaveName(newSaveName);
    },
    saveCustColumnsData: props => async () => {
      const body = {
        fieldsRequests: props.saveName,
      };
      try {
        const result = await UserService.updateUser(props.user._id, body);
        if (result.status === 200) {
          props.setCustSaved(true);
          setTimeout(() => {
            props.setCustSaved(false);
          }, 3000);
        }
      } catch (err) {
        setTimeout(() => {
          props.setCustSaved(false);
        }, 3000);
      }
    },
  }),
  lifecycle({
    async  componentDidMount() {
      try {
        const userById = await UserService.getUserById(this.props.user._id);
        let { requests = {} } = this.props.userAccesses;

        if (Object.keys(requests).length === 0) {
          requests = this.props.userAccesses;
        }

        const userFields = Object.keys(requests)
          .filter(item => requests[item]);

        CustListFull.forEach((itemCustList) => {
          itemCustList.items.forEach((itemNameCust) => {
            if (userById.fieldsRequests.indexOf(itemNameCust.name) !== -1) {
              itemNameCust.value = true;
            } else {
              itemNameCust.value = false;
            }
          });
        });

        const fields = CustListFull
          .map(item => ({
            title: item.title,
            name: item.name,
            items: item.items.filter(field => userFields.indexOf(field.name) !== -1),
          }));

        this.props.setCustList(fields);
      } catch (error) {
        console.log('error', error);
      }

      this.props.setSecondaryBarItems(
        [
          {
            id: '123',
            name: 'Личная информация',
            url: '/dashboard/profile/personalInfo',
          },
          {
            id: '1234',
            name: 'Смена пароля',
            url: '/dashboard/profile/changePassword',
          },
          {
            id: '12345',
            name: 'Настройка колонок',
            url: '/dashboard/profile/custColumns',
          },
        ]);
    },
  }),
)(CustColumnsView);
