/**
 * @flow
 */

import React from 'react';

import {
  Logo,
  Button,
  Dropdown,
  DropdownItem,
} from '../index';
import {
  NavBarWrapper,
  LogoWrapper,
  ActionsWrapper,
  MobileNavbar,
  HideComponent,
  AddButtonWrapper,
} from './NavBarStyled';

type NavbarViewPropsType = {
  onClickMobile: (any) => void,
  userName: string,
  avatar?: string,
  logout: (any) => void,
  openProfile: (any) => void,
  openDashboard: (any) => void,
  openRequestForm: () => void,
};

export default function NavBar(props: NavbarViewPropsType) {
  return (
    <NavBarWrapper className="nav-bar">
      <LogoWrapper>
        <Logo
          className="logo"
          clicked={() => props.openDashboard()}
        />
      </LogoWrapper>
      <MobileNavbar onClick={props.onClickMobile}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
        </svg>
      </MobileNavbar>
      <ActionsWrapper>
        <Dropdown
          name={props.userName}
          // avatar={
          //   props.avatar
          //     ? props.avatar
          //     : require('../../assets/images/default_avatar.png')
          // }
        >
          {/**<DropdownItem
            link="#"
            title="Личный кабинет"
            clicked={() => props.openProfile()}
          />**/}
          <DropdownItem
            clicked={() => props.logout()}
            title="Выход"
          />
        </Dropdown>
      </ActionsWrapper>
    </NavBarWrapper>
  );
}

NavBar.defaultProps = {
  avatar: '',
};
