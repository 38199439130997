import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const InputComponent = styled.input`
  border-radius: 6px;
  border: 1px solid #efefef;
  padding: 10px 12px;
  font-size: 16px;
  color: ${colors.black};
  outline: none;
  width: 100%;
  background: ${colors.white};
  &::placeholder {
    color: ${colors.light_gray};
  }
  ${props => props.borderLogin && css`
    border: 1px solid red;
  `}
  ${props => !props.valid && css`
    border: 1px solid ${colors.primary};
    border-radius: 6px;
  `};
  ${props => props.readOnly && css`
    cursor: pointer;
  `};
`;

export const Form = styled.div`
  ${props => props.search && css`
    display: flex;
  `};
`;

export const SearchIcon = styled.div`
  height: 36px;
  width: 30px;
  margin: 0 0 0 12px;
  background-image: url(${require('../../assets/icons/searchsvg.svg')});
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: center;
`;
