import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

// view
import MainView from './MainView';

// actions
import {
  setActiveSidebarItem,
  setSecondaryBarItems,
} from '../../../store/actions/dashboard';

export default compose(
  connect(
    state => ({
      user: state.auth.currentUser,
    }),
    dispatch => ({
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
    }),
  ),

  lifecycle({
    componentDidMount() {
      this.props.setActiveSidebarItem('');
      this.props.setSecondaryBarItems([]);
    },
  }),
)(MainView);
