/**
 * @flow
 */
import React from 'react';

import {
  Input,
  Button,
} from '../index';
import {
  Form,
  InputContainer,
  ButtonContainer,
  FormHeading,
  InputMargin,
} from './LoginFormStyled';

type LoginFormViewPropsType = {
  setLogin: (any) => void,
  setPassword: (any) => void,
  submitForm: (any) => void,
  submitEvent: (any) => void,
  borderLogin: Boolean,
};

export default function LoginFormView(props: LoginFormViewPropsType) {
  return (
    <Form onKeyDown={props.submitEvent}>
      <InputContainer>
        <FormHeading>
          <strong>Авторизация</strong>
        </FormHeading>
        <InputMargin>
          <Input
            type="text"
            placeholder="Логин"
            changed={props.setLogin}
            borderLogin={(props.borderLogin) ? true : false}
          />
        </InputMargin>
        <Input
          type="password"
          placeholder="Пароль"
          changed={props.setPassword}
          borderLogin={(props.borderLogin) ? true : false}
        />
        <ButtonContainer>
          <Button
            clicked={props.submitForm}
          >
            Войти
          </Button>
        </ButtonContainer>
      </InputContainer>
    </Form>
  );
}
