import moment from 'moment';
import {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
  } from '../ApiService';

  export default {
    getApiName() {
      return 'messages';
    },

    getItems(p = 1, params) {
      return apiGet(`/messages?page=${p}`, { params })
        .then(response => ({
            data: response.data.docs.map(messages => ({
                ...messages,
                id: messages._id,
                name: `Дата отправки: ${moment(messages.created).format('DD-MM-YYYY HH:mm:ss')}, Создатель сообщения: ${messages.creator.firstName} ${messages.creator.lastName}`,
            })),
          records: response.data.records.all,
        }))
        .catch((error) => {
          throw new Error(error);
        });
    },

    getById(id) {
      return apiGet(`/messages/${id}`)
        .then((response) => {
          const result = {
            id: response.data._id,
            name: `${response.data.creator.firstName} ${response.data.creator.lastName}`,
            relations: [],
            inputs: [],
            checkboxes: [],
            dropdowns: [],
            textarea: [],
          };
          result.inputs.push({
            title: 'Дата отправки',
            name: 'fuel',
            value: moment(response.data.created).format('DD-MM-YYYY HH:mm:ss'),
            readOnly: true,
          });
          result.inputs.push({
            title: 'Имя',
            name: 'firstName',
            value: response.data.creator.firstName,
            readOnly: true,
          });
          result.inputs.push({
            title: 'Фамилия',
            name: 'lastName',
            value: response.data.creator.lastName,
            readOnly: true,
          });
          result.inputs.push({
            title: 'Телефон',
            name: 'phone',
            value: response.data.creator.phone,
            readOnly: true,
          });
          result.inputs.push({
            title: 'Электронная почта',
            name: 'email',
            value: response.data.creator.email,
            readOnly: true,
          });
          
          result.textarea.push({
            title: 'Сообщение',
            name: 'name',
            value: response.data.name,
            readOnly: true,
          });
          console.log('response123123123', response.data.name);
          return result;
        })
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },

    removeLinkedItem() {
      return null;
    },

    addLinkedItem() {
      return null;
    },

    deleteItem(id) {
      return apiDelete(`/messages/${id}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },

    getFields() {
      return {
        relations: [],
        dropdowns: [],
        inputs: [
          {
            title: 'Дата отправки',
            name: 'created',
          },
          {
            title: 'Сообщение',
            name: 'name',
          },
          {
            title: 'Имя',
            name: 'firstName',
          },
          {
            title: 'Фамилия',
            name: 'lastName',
          },
          {
            title: 'Номер телефона',
            name: 'phone',
          },
          {
            title: 'Электронная почта',
            name: 'email',
          },
        ],
        checkboxes: [],
      };
    },

    // updateItem(id, body) {
    //   return apiPut(`/refueling/${id}`, body)
    //     .then(response => response)
    //     .catch((error) => {
    //       throw new Error(error.response.data.error.message);
    //     });
    // },

    // createItem(body) {
    //   return apiPost('/refueling', body)
    //     .then(response => ({
    //       status: response.status,
    //       data: {
    //         id: response.data._id,
    //         name: ` ${response.data.fuel}, ${response.data.liters} лт, ${response.data.cost} руб, ${response.data.user.firstName} ${response.data.user.lastName}, ${response.data.car.name}-${response.data.car.number}`,
    //         ...response.data,
    //       },
    //     }))
    //     .catch((error) => {
    //       console.log('error', error);
    //       throw new Error(error.response.data.error.message);
    //     });
    // },

    searchItem(body) {
      return apiGet(`/messages?search=${body.search}`)
        .then(response => response.data.docs.map(messages => ({
          ...messages,
          id: messages._id,
          name: `${moment(messages.created).format('DD-MM-YYYY HH:mm:ss')}, ${messages.creator.firstName} ${messages.creator.lastName}`,
        })))
        .catch(error => console.log('Not Search', error));
    },
  };
