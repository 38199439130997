import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../../styles';

export const ContentPage = styled.div`
  width: 100%;
`;

export const FormCardInfo = styled.div`
  position: fixed;
  right: 0;
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
  @media (min-width: ${screens.screen_xs_max}) {
    margin-top: -12px;
  }
`;

export const AddSearch = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
`;

export const MobileTitleBlock = styled.div`
  display: none;
  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const ButtonBlock = styled.div`
  margin-right: ${base * 6}px;
  display: flex;
  align-items: center;
  margin: auto auto 12px 24px;
`;

export const InputSearchBlock = styled.div`
  // width: ${base * 20}px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ContentGlobal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PopupMessage = styled.div`
  display: none;
  position: fixed;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  border-radius: 12px;
  transition: bottom .3s ease-in-out;
  div:first-child {
    border-radius: 12px;
    padding: 16px ${base * 3.5}px;
  }
  div:nth-child(2n) {
    margin-top: ${base - 6}px;
  }
  button {
    background: transparent;
    border: none;
    color: ${colors.black};
    font-size: 12px;
    /* background-color: ${colors.whi}; */
    cursor: pointer;
    margin: 0 12px;
    outline: none;
    font-size: 18px;
    &:first-child:hover{
      color: green;
    }
    &:last-child:hover{
      color: ${colors.primary};;
    }
    &:active {
      outline: none;
    }
  }
  ${props => props.show && css`
    bottom: 20px;
    display: block;
  `}
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

export const InfoBlock = styled.div`
  margin-top: 18px;
  &:first-child{
    margin-top: 0;
  }
  &:last-child{
    margin-bottom: 12px;
  }
`;

export const Tabs = styled.p`
  flex-direction: row;
  padding: 0 32px;
`;

export const Tab = styled.button`
  margin-right: ${base * 6}px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: ${colors.light_gray};
  outline: none;
  &:hover {
    color: ${colors.primary};
  }
  &:active {
    color: ${colors.primary};
    outline: none;
    border: none;
  }
  ${props => props.active && css`
    color: ${colors.primary};
  `}
`;

export const FromList = styled.div`
  padding: 0 32px;
  margin: 18px 0 18px;

  p {
    padding: 0;
    padding-bottom: 6px;
  }

  input {
    font-size: ${base + 4}px;
  }
  div {
    /* width: 100%; */
  }
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const FormListInputWrapper = styled.div`
  margin-bottom: 12px;
`;

export const Paragraph = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: ${base * 2.5}px;
  ${props => props.smallParagraph && css`
    font-size: 18px;
    color: ${colors.light_gray};
    padding: 0 32px 3px 32px;
  `}
`;

export const ListBlock = styled.div`
  height: ${base * 23}px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const ListItem = styled.div`
  padding: 0 32px 0 32px;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  display: flex;
  p {
    font-size: 18px;
    color: ${colors.black};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ${props => props.withoutHidden && css`
    display: block;
    p {
      overflow: visible;
      white-space: normal;
    }
  `}
  &:hover {
    background-color: ${colors.white_smoke};
  }
`;

export const TextArea = styled.textarea`
  background-color: ${colors.white_smoke};
  border: none;
  border-radius: 6px;
  font-size: 12px;
  color: ${colors.light_gray};
  padding: 12px 16px;
  resize: none;
  outline: none;
  width: calc(100% - ${base * 2.5}px);
  height: 84px;
`;

export const InputWrapper = styled.div`
  padding: 0 32px;
  margin: 6px auto 0 auto;
  input{
    font-size: 16px;
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 12px auto;
`;

export const Loaders = styled.div`
    display: none;
    transform: translateZ(1px);
    margin-left: 20px;
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #2E83EC;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    opacity: 0.65;
    @keyframes lds-circle {
      0%, 100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
      }
      0% {
        transform: rotateY(0deg);
      }
      50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
      }
      100% {
        transform: rotateY(3600deg);
      }
    }
`;

export const AdditionalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const AdditionalButton = styled.span`
  color: ${colors.light_gray};
  font-size: 18px;
  cursor: pointer;
  &:hover {
    color: ${colors.primary};
  }
`;
