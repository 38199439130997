/**
 * @flow
 */
import React from 'react';
import Calendar from 'rc-calendar';
import ruRu from 'rc-calendar/lib/locale/ru_RU';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import UpdatedColumn from './TableColumn/TableColumnContainer';
import TableHeader from './TableHeader/TableHeaderContainer';
import {
  Table,
  HeaderRow,
  TableColumn,
  TextTableColumn,
  EmptyTableRow,
  HeaderRowFixed,
  EditIcon,
  Tbody,
  TableEdit,
  CalendarContainer,
  ContextMenuWrapper,
} from './RequestTableStyles';
import { BUH_TOTAL_INPUT } from '../../constants';

type RequestTablePropsType = {
  columnTitles: Object,
  data: Array<Object>,
  selectedRequests: Array<any>,
  tableSorting: Object,
  editField: Object,
  updateTable: boolean,
  deleteRequests: string,
  amountSuccessfull: string,
  selectRequest: (any, any) => void,
  sortTable: (any) => void,
  addFilter: (any, any) => void,
  openRequest: (any) => void,
  selectAllRequests: (boolean) => void,
  checkboxView: boolean,
  openEditPage: (any) => void,
  editRequest: (any) => void,
  setEditField: (Object, Function) => void,
  handleClick: (string, string) => void,
  handleDoubleClick: (string, string, string) => void,
  copyRequest: (string) => void,
};

const formatStr = 'DD-MM-YYYY';

export default function RequestTableView(props: RequestTablePropsType) {
  const data = [];
  // формирование данных таблицы
  const { name: cellName, edit: cellEdit, requestId } = props.editField;
  for (let i = 0; i < props.data.length; i += 1) {
    const dataRow = [];
    let colorText = 'black'; // цвет текста строки заявки
    let color = '#A9D08E'; // цвет границы строки заявки
    let colorRow = '#fff'; // цвет строки заявки
    let negativeRow = false;
    const colorRowNegative = '#ffb3b3';
    let classes = '';
    let checkedRow = false; // состояние чекбокса
    // формирование цвета границы заявки
    switch (props.data[i].status) {
      case 'Формирование':
        color = '#A9D08E';
        break;
      case 'Необходимо сделать':
        color = '#FFFF00';
        break;
      case 'В работе':
        color = '#FFD966';
        break;
      case 'Выполнена':
        color = '#F4B084';
        break;
      case 'Закрыта':
        color = '#808080';
        colorText = '#b3b3b3';
        break;
      default:
        color = '#A9D08E';
        break;
    }
    // определяем выбрана ли заявка
    props.selectedRequests.forEach((value) => {
      if (props.data[i].id === value) {
        colorRow = '#f2f2f2';
        checkedRow = true;
        classes = 'react-contextmenu-selected';
      }
    });
    // row: checkbox
    dataRow.push(
      <td style={{ background: '#ffffff' }} key="checkbox">
        <TextTableColumn>
          <input
            type="checkbox"
            checked={checkedRow}
            onChange={event => props.selectRequest(props.data[i].id, event.target.checked)}
          />
        </TextTableColumn>
      </td>);
    // row: number
    dataRow.push(
      <td
        key="number"
        style={{
          borderLeft: `1px solid #fbfbfb`, position: 'sticky', left: '0', backgroundColor: 'white', zIndex: '1',
        }}
      >
        <EditIcon
          onClick={() => props.openEditPage(props.data[i].id)}
        />
        <TextTableColumn
          style={{
            width: 'auto',
            outline: 'none',
            cursor: 'pointer',
          }}
          onClick={() => props.openRequest(props.data[i].id)}
        >
          {`№${props.data[i].number}`}
        </TextTableColumn>
      </td>);
    // other row
    // eslint-disable-next-line no-loop-func
    Object.keys(props.columnTitles).forEach((key) => {
      let fontWeight = 'normal';
      if (key === BUH_TOTAL_INPUT) {
        negativeRow = props.data[i][key] < 0;
        fontWeight = 'bolder';
      }

      if (key !== 'number') {
        const withColumn = (String(props.columnTitles[key]).length + 10 < String(props.data[i][key]).length)
          ? '300px' : 'auto';

        if (typeof props.data[i][key] === 'object' && props.data[i][key]) {
          dataRow.push(
            <UpdatedColumn
              key={key}
              onClick={() => props.addFilter(key, props.data[i][key])}
              update={props.updateTable}
            >
              <TextTableColumn style={{ width: `${withColumn}` }}>{props.data[i][key]}</TextTableColumn>
            </UpdatedColumn>,
          );
        } else if (typeof props.data[i][key] === 'boolean') {
          dataRow.push(
            <UpdatedColumn
              key={key}
              onClick={() => props.addFilter(key, props.data[i][key])}
              update={props.updateTable}
            >
              <TextTableColumn style={{ width: `${withColumn}`, justifyContent: 'center' }}>
                <input
                  type="checkbox"
                  defaultChecked={props.data[i][key]}
                  disabled
                />
              </TextTableColumn>
            </UpdatedColumn>,
          );
        } else if (key !== 'created') {
          let toRender = null;
          if (key === 'tracking') {
            toRender = (
              <TableEdit
                type="text"
                value={props.editField.value}
                onBlur={() => {
                  props.editRequest(
                    () => props.setEditField({
                      requestId: null,
                      name: '',
                      value: '',
                      edit: false,
                    }));
                }}
                onChange={(e) => {
                  const field = { ...props.editField };
                  field.value = e.target.value;
                  props.setEditField(field);
                }}
                autoFocus
              />
            );
          }
          if (key === 'dateArrival') {
            toRender = (
              <CalendarContainer>
                <Calendar
                  onChange={(value) => {
                    const field = { ...props.editField };
                    field.value = value.format('DD-MM-YYYY');
                    props.setEditField(field, () => {
                      props.editRequest(
                        () => props.setEditField({
                          requestId: null,
                          name: '',
                          value: '',
                          edit: false,
                        }));
                    });
                  }}
                  onClear={() => props.setEditField({
                    requestId: null,
                    name: '',
                    value: '',
                    edit: false,
                  })}
                  locale={ruRu}
                  format={formatStr}
                />
              </CalendarContainer>
            );
          }
          if (key === 'buh_amount') {
            return dataRow.push(
              <UpdatedColumn
                key={key}
                onClick={() => props.handleClick(key, props.data[i][key])}
                onDoubleClick={(e) => {
                  e.preventDefault();
                  props.handleDoubleClick(props.data[i].id, key, props.data[i][key]);
                }}
                update
              >
                {
                  (cellName === key && cellEdit && requestId === props.data[i].id && toRender)
                    ? (
                      toRender
                    ) : (
                      <TextTableColumn
                        style={{
                          width: `${withColumn}`,
                          fontWeight: `${fontWeight}`,
                          background: (props.data[i].paymentState) ? '#ADFF2F' : '#ffb3b3',
                        }}
                        onClick={() => console.log('click', props.data[i])}
                      >
                        {(key.includes('buh')) ? (props.data[i][key]).toLocaleString() : props.data[i][key]}
                      </TextTableColumn>
                    )
                  }
              </UpdatedColumn>,
            );
          }
          dataRow.push(
            <UpdatedColumn
              key={key}
              onClick={() => props.handleClick(key, props.data[i][key])}
              onDoubleClick={(e) => {
                e.preventDefault();
                props.handleDoubleClick(props.data[i].id, key, props.data[i][key]);
              }}
              update={(cellEdit && cellName === key && requestId === props.data[i].id) || props.updateTable}
            >
              {
                (cellName === key && cellEdit && requestId === props.data[i].id && toRender)
                  ? (
                    toRender
                  ) : (
                    <TextTableColumn
                      style={
                        (props.data[i][key] && props.data[i][key].length > 50)
                          ? (
                            {
                              alignItems: 'normal',
                              width: `${withColumn}`,
                              fontWeight: `${fontWeight}`,
                            }
                          )
                          : (
                            {
                              fontWeight: `${fontWeight}`,
                              width: `${withColumn}`,
                            }
                          )
                      }
                      onClick={() => console.log('click', props.data[i])}
                    >
                      {(key.includes('buh')) ? (props.data[i][key]).toLocaleString() : props.data[i][key]}
                    </TextTableColumn>
                  )
              }
            </UpdatedColumn>,
          );
        } else {
          dataRow.push(
            <UpdatedColumn
              key={key}
              update={props.updateTable}
            >
              <TextTableColumn
                style={{
                  width: `${withColumn}`,
                  fontWeight: `${fontWeight}`,
                }}
              >
                {props.data[i][key]}
              </TextTableColumn>
            </UpdatedColumn>,
          );
        }
      }
    });

    data.push(
      <ContextMenuTrigger
        id={props.data[i].id}
        renderTag="tr"
        attributes={{
          className: negativeRow ? 'react-contextmenu-negative' : classes,
        }}
      >
        {dataRow}
      </ContextMenuTrigger>,
    );
  }
  return (
    <Table className="table">
      <HeaderRowFixed className="table-header-wrapper">
        <TableHeader
          trName="table-header"
          tableSorting={props.tableSorting}
          checkboxView={props.checkboxView}
          columnTitles={props.columnTitles}
          selectAllRequests={event => props.selectAllRequests(event.target.checked)}
          sortTable={props.sortTable}
        />
      </HeaderRowFixed>
      <HeaderRow>
        <TableHeader
          trName="table-header-original"
          tableSorting={props.tableSorting}
          checkboxView={props.checkboxView}
          columnTitles={props.columnTitles}
          selectAllRequests={event => props.selectAllRequests(event.target.checked)}
          sortTable={props.sortTable}
        />
      </HeaderRow>
      <Tbody className="table-row">
        <EmptyTableRow />
        {data}
      </Tbody>
    </Table>
  );
}

RequestTableView.defaultProps = {
};
