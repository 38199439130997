import React from 'react';

import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';

import DropdownView from './DropdownView';
import DropdownItem from './DropdownItem/DropdownItemContainer';

export default compose(
  withState('toggledOn', 'toggle', false),
  withHandlers({
    toggle: ({ toggle }) => () => toggle(current => !current),
    convertToArray: props => () => React.Children.toArray(props.children),
  }),
  lifecycle({
    componentDidMount() {
      let { children } = this.props;

      React.Children.forEach(children, (child) => {
        if (child.type !== DropdownItem) {
          throw new Error('Dropdown children should be of type `DropdownItem`.');
        }
      });

      const isArray = Array.isArray(children);

      if (!isArray) {
        children = this.props.convertToArray();
      }
    },
  }),
)(DropdownView);
