/* eslint-disable no-underscore-dangle */
import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';

import { connect } from 'react-redux';
import moment from 'moment';

// view
import RequestTableView from './RequestsTableView';

// services
import {
  AddressService,
  AutoagentService,
  CarService,
  ConsigneeService,
  CounterpartyService,
  DriverService,
  LoadService,
  PayerService,
  RequestsService,
  ShipperService,
  StationService,
  TerminalService,
  TrailerService,
  UserService,
  DirectoryService,
} from '../../../services';

// socket service
import {
  socket,
} from '../../../services/socket';

// actions
import {
  setSecondaryBarItems,
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setExpand,
} from '../../../store/actions/dashboard';

export default compose(
  connect(
    state => ({
      activeSidebarItem: state.dashboard.activeItem,
      user: state.auth.currentUser,
      expand: state.dashboard.expand,
    }),
    dispatch => ({
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      setExpand: state => dispatch(setExpand(state)),
    }),
  ),
  withState('filtersActivePoint', 'setFiltersActivePoint', false),
  withState('checkboxView', 'setCheckboxView', false),
  withState('requests', 'setRequests', []),
  withState('availableFields', 'setAvailableFields', {}),
  withState('viewCalendar', 'setViewCalendar', false),
  withState('dateFilters', 'setDateFilters', ''),
  withState('dateFiltersActive', 'setDateFiltersActive', false),
  withState('filters', 'setFilters', {}),
  withState('filtersActive', 'setFiltersActive', false),
  withState('sorting', 'setSorting', ''),
  withState('isLoading', 'setIsLoading', false),
  withState('updateTable', 'setUpdateTable', false),
  withState('searchValue', 'setSearchValue', ''),
  withState('selectedRequests', 'setSelectedRequests', []),
  withState('showAutocompleteValues', 'setShowAutocompleteValues', {}),
  withState('autocompleteValues', 'setAutocompleteValues', {}),
  withState('typeFields', 'setTypeFields', {
    // car: {
    //   country: {
    //     type: 'input',
    //     inputType: 'text',
    //   },
    //   name:{
    //     type: 'input',
    //     inputType: 'text',
    //   },

    // },
    number: {
      type: 'input',
      inputType: 'number',
    },
    state: {
      type: 'select',
      values: ['Предварительное', 'В пути', 'Прибыл',
        'Выдан клиенту', 'Прогон', 'Закрыта', 'Вывоз'],
    },
    dateLoad: {
      type: 'input',
      inputType: 'date',
    },
    execution: {
      type: 'input',
      inputType: 'date',
    },
    plannedIssueDate: {
      type: 'input',
      inputType: 'date',
    },
    timeLoad: {
      type: 'input',
      inputType: 'text',
    },
    typeSend: {
      type: 'select',
      values: ['Контейнерная', 'Авто', 'Вагонная', 'Сборка'],
    },
    typeContainer: {
      type: 'select',
      values: ['20FT', '20FT(HC)', '40FT', '40FT(HC)', '40FT(HC)PW'],
    },
    typeElivery: {
      type: 'select',
      values: ['До двери', 'До Станции', 'Вывоз'],
    },
    numberContainer: {
      type: 'input',
      inputType: 'text',
    },
    stopSheet: {
      type: 'checkbox',
    },
    companyAmixgroup: {
      type: 'checkbox',
    },
    numberSeal: {
      type: 'input',
      inputType: 'text',
    },
    payer: {
      type: 'autocomplete',
      service: PayerService,
    },
    shipper: {
      type: 'autocomplete',
      service: ShipperService,
    },
    consignee: {
      type: 'autocomplete',
      service: ConsigneeService,
    },
    railCarrier: {
      type: 'autocomplete',
      service: ConsigneeService,
    },
    agentAutoLoad: {
      type: 'autocomplete',
      service: AutoagentService,
    },
    ownerContainer: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    agentAutoCall: {
      type: 'autocomplete',
      service: AutoagentService,
    },
    addressLoading: {
      type: 'autocomplete',
      service: AddressService,
    },
    addressUnloading: {
      type: 'autocomplete',
      service: AddressService,
    },
    stationDeparture: {
      type: 'autocomplete',
      service: StationService,
    },
    destinationCity: {
      type: 'autocomplete',
      service: StationService,
    },
    stationDestination: {
      type: 'autocomplete',
      service: StationService,
    },
    loadActual: {
      type: 'autocomplete',
      service: LoadService,
    },
    loadDocumented: {
      type: 'autocomplete',
      service: LoadService,
    },
    weight: {
      type: 'input',
      inputType: 'text',
    },
    numberSeats: {
      type: 'input',
      inputType: 'text',
    },
    driver: {
      type: 'autocomplete',
      service: DriverService,
    },
    companyMoversUnloading: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    companyMoversLoading: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    companyNameForwarder: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    car: {
      type: 'autocomplete',
      service: CarService,
    },
    trailer: {
      type: 'autocomplete',
      service: TrailerService,
    },
    terminalStaging: {
      type: 'autocomplete',
      service: TerminalService,
    },
    instructionsDelivery: {
      type: 'input',
      inputType: 'text',
    },
    releases: {
      type: 'input',
      inputType: 'text',
    },
    company: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    manager: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    security: {
      type: 'checkbox',
    },
    warming: {
      type: 'input',
      inputType: 'text',
    },
    warmingCompany: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    forwarderLoading: {
      type: 'checkbox',
    },
    forwarderUnloading: {
      type: 'checkbox',
    },
    movers: {
      type: 'input',
      inputType: 'text',
    },
    companyMovers: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    veterinaryCertificate: {
      type: 'checkbox',
    },
    loadFastening: {
      type: 'checkbox',
    },
    agentDocuments: {
      type: 'input',
      inputType: 'text',
    },
    returnOfDocumentsFromUnloading: {
      type: 'input',
      inputType: 'text',
    },
    dateArrival: {
      type: 'input',
      inputType: 'date',
    },
    dateIssue: {
      type: 'input',
      inputType: 'date',
    },
    tracking: {
      type: 'input',
      inputType: 'text',
    },
    railwayInvoices: {
      type: 'checkbox',
    },
    loading: {
      type: 'checkbox',
    },
    loadingTN: {
      type: 'checkbox',
    },
    unloadingTN: {
      type: 'checkbox',
    },
    act: {
      type: 'checkbox',
    },
    od: {
      type: 'input',
      inputType: 'text',
    },
    shipment: {
      type: 'input',
      inputType: 'text',
    },
    customerApplicationNumber: {
      type: 'input',
      inputType: 'text',
    },
    additionalServices: {
      type: 'input',
      inputType: 'text',
    },
    buh_amount: {
      type: 'input',
      inputType: 'number',
    },
    buh_carrier: {
      type: 'input',
      inputType: 'number',
    },
    buh_autoBeforeLoading: {
      type: 'input',
      inputType: 'number',
    },
    buh_forMKAD: {
      type: 'input',
      inputType: 'number',
    },
    buh_plain: {
      type: 'input',
      inputType: 'number',
    },
    buh_overload: {
      type: 'input',
      inputType: 'number',
    },
    buh_containerAgent: {
      type: 'input',
      inputType: 'number',
    },
    buh_aboveTheNorm: {
      type: 'input',
      inputType: 'number',
    },
    buh_forwarderOnLoading: {
      type: 'input',
      inputType: 'number',
    },
    buh_moversOnLoading: {
      type: 'input',
      inputType: 'number',
    },
    buh_moversAtUnloading: {
      type: 'input',
      inputType: 'number',
    },
    buh_veterinaryCertificate: {
      type: 'input',
      inputType: 'number',
    },
    buh_loadFastening: {
      type: 'input',
      inputType: 'number',
    },
    buh_warming: {
      type: 'input',
      inputType: 'number',
    },
    buh_additionalServices: {
      type: 'input',
      inputType: 'number',
    },
    buh_exportAgent: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToUnload: {
      type: 'input',
      inputType: 'number',
    },
    buh_storageAtTheDestinationStation: {
      type: 'input',
      inputType: 'number',
    },
    buh_seal: {
      type: 'input',
      inputType: 'number',
    },
    buh_bills: {
      type: 'input',
      inputType: 'number',
    },
    buh_agentToClient: {
      type: 'input',
      inputType: 'number',
    },
    buh_managerial: {
      type: 'input',
      inputType: 'number',
    },
    buh_agentSuppliers: {
      type: 'input',
      inputType: 'number',
    },
    buh_OBN: {
      type: 'input',
      inputType: 'number',
    },
    buh_total: {
      type: 'input',
      inputType: 'number',
    },
    buh_gasketMaterial: {
      type: 'input',
      inputType: 'number',
    },
    customerAccountNumber: {
      type: 'input',
      inputType: 'text',
    },
    customerAccountAmount: {
      type: 'input',
      inputType: 'text',
    },
    customerAccountDate: {
      type: 'input',
      inputType: 'text',
    },
    carrierAccount: {
      type: 'input',
      inputType: 'text',
    },
    accountsAutoServices: {
      type: 'input',
      inputType: 'text',
    },
    containerAgentAccount: {
      type: 'input',
      inputType: 'text',
    },
    exportAgentAccount: {
      type: 'input',
      inputType: 'text',
    },
    moversAccountOnLoading: {
      type: 'input',
      inputType: 'text',
    },
    moversAccountOnUnloading: {
      type: 'input',
      inputType: 'text',
    },
    accountSecuringCargo: {
      type: 'input',
      inputType: 'text',
    },
    billCargoWeatherization: {
      type: 'input',
      inputType: 'text',
    },
    moversOnLoading: {
      type: 'checkbox',
    },
    moversAtUnloading: {
      type: 'checkbox',
    },
    scheduledIssueTime: {
      type: 'input',
      inputType: 'text',
    },
    documentStatus: {
      type: 'input',
      inputType: 'text',
    },
    timeIssue: {
      type: 'input',
      inputType: 'text',
    },
  }),
  withState('page', 'setPage', 1),
  withState('moreLoading', 'setMoreLoading', false),
  withHandlers({
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    setFixTableHeader: () => () => {
      const fixedHeader = document.querySelector('.table-header');
      const originalHeader = document.querySelector('.table-header-original');
      if (originalHeader && fixedHeader) {
        originalHeader.childNodes.forEach((item, i) => {
          fixedHeader.childNodes[i].style.minWidth = `${item.offsetWidth}px`;
        });
      }
    },
  }),
  withHandlers({
    generateTable: props => async (page = 1) => {
      props.setMoreLoading(true);
      let params = {};
      // check search value
      if (props.searchValue !== '') {
        params.search = props.searchValue;
      } else if (props.dateFiltersActive) {
        // check date filter
        params.daterange = props.dateFilters;
      } else {
        // check filters
        if (props.filtersActive) {
          params = Object.assign(params, props.filters);
          Object.keys(params).forEach((key) => {
            if (typeof params[key] === 'object') {
              if (props.typeFields[key].type === 'input') {
                if (props.typeFields[key].inputType === 'number') {
                  params[key] = `${params[key].begin};${params[key].end}`;
                } else {
                  params[key] = params[key].id || params[key]._id;
                }
              } else {
                params[key] = params[key].id || params[key]._id;
              }
            }
          });
        }
        // check sorting
        if (props.sorting !== '') {
          params.sort = props.sorting;
        }
      }
      // get requests from API
      try {
        const requests = await RequestsService.getAllRequests(page, 10, params, 'Закрыта');
        const result = [];
        Object.keys(requests).forEach((key) => {
          if(requests[key].status === 'Закрыта'){
          const counter = {};
          Object.keys(requests[key]).forEach((item) => {
              if( typeof requests[key][item] === 'object'){
                if (requests[key][item]){
                  Object.keys(requests[key][item]).forEach((keyItem) => {
                    if( typeof requests[key][item][keyItem] === 'object'){
                      if(requests[key][item][keyItem]){
                        Object.keys(requests[key][item][keyItem]).forEach((element) => {
                          if (typeof requests[key][item][keyItem] === 'object'){
                          } else {
                            counter[element] = requests[key][item][keyItem][element];
                          }
                        });
                      }
                    } else {
                      counter[keyItem] = requests[key][item][keyItem];
                    }
                  });
                }
              } else {
                  counter[item] = requests[key][item];
              }          
          })
          result.push(counter);
          }
        });
        props.setIsLoading(false);
        // боковое меню
        // props.setSecondaryBarItems([{id: '1', name: 'Актуальные задачи', url: '',}, {id: '2', name: 'Архив задач', url: '',}]);
        props.setPage(page);
        // render table
        if (page === 1) {
          props.setRequests([], () => {
            props.setRequests(result, () => {
              props.setFixTableHeader();
              props.setMoreLoading(false);
            });
          });
        } else {
          props.setRequests([...props.requests, ...result], () => {
            props.setFixTableHeader();
            props.setMoreLoading(false);
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    editRequest: props => async (id, field, value) => {
      const result = await RequestsService.updateRequest(id, { [field]: value });
      if (result.status !== 200) return;
      const requests = [...props.requests];
      requests.forEach((request) => {
        if (request.id === id) {
          request[field] = value;
        }
      });

      props.setRequests(requests);
    },
  }),
  withHandlers({
    selectAllRequests: props => (checked) => {
      let currentRequestsId = [];
      if (checked) {
        currentRequestsId = props.requests.map(request => request.id);
        props.setCheckboxView(true);
        props.setSelectedRequests(currentRequestsId);
      } else {
        props.setCheckboxView(false);
        props.setSelectedRequests([]);
      }
    },
    selectRequest: props => (id, checked) => {
      const updateSelectedRequests = props.selectedRequests;
      if (checked) {
        updateSelectedRequests.push(id);
      } else {
        for (let i = 0; i < updateSelectedRequests.length; i += 1) {
          if (updateSelectedRequests[i] === id) {
            updateSelectedRequests.splice(i, 1);
          }
        }
      }
      props.setSelectedRequests(updateSelectedRequests);
    },
    clickCalendar: props => () => {
      if (props.viewCalendar) {
        props.setViewCalendar(false);
        return;
      }
      if (props.dateFilters === '') {
        props.setViewCalendar(true);
      } else {
        props.setDateFiltersActive(!props.dateFiltersActive, () => {
          props.generateTable();
        });
      }
    },
    changeDateRange: props => (dates) => {
      if (dates) {
        props.setViewCalendar(false);
        const dateRange = [
          `${dates[0]._d.getFullYear()}-${dates[0]._d.getMonth() + 1}-${dates[0]._d.getDate()}`,
          `${dates[1]._d.getFullYear()}-${dates[1]._d.getMonth() + 1}-${dates[1]._d.getDate()}`,
        ];
        try {
          const dateFilters = JSON.stringify(dateRange);
          props.setDateFilters(dateFilters, () => {
            if (props.dateFiltersActive) {
              props.generateTable();
            }
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        props.setDateFilters('');
      }
    },
    deleteDateFilter: props => () => {
      props.setDateFiltersActive(false, () => {
        props.setDateFilters('', () => {
          props.generateTable();
        });
      });
    },
    addFilter: props => (field, value) => {
      const currentFilters = props.filters;
      console.log('props.typeFields[field]', props.typeFields[field]);
      if (props.typeFields[field].type === 'input') {
        if (props.typeFields[field].inputType === 'number') {
          currentFilters[field] = {
            begin: value,
            end: value,
          };
        } else if ((props.typeFields[field].inputType !== 'text')) {
          currentFilters[field] = value;
        }
      } else {
        currentFilters[field] = value;
      }
      props.setFilters(currentFilters, () => {
        if (props.filtersActive) {
          props.generateTable();
        }
      });
    },
    deleteFilter: props => (field) => {
      const updatetableFilters = props.filters;
      delete updatetableFilters[field];
      props.setFilters(updatetableFilters, () => {
        if (props.filtersActive) {
          props.generateTable();
        }
        if (Object.keys(props.filters).length === 0) {
          props.setFiltersActive(false);
          props.setFiltersActivePoint(false);
        }
      });
    },
    changeFilters: props => async (field, value) => {
      const currentFilters = props.filters;
      const currentTypeFields = props.typeFields;
      const currentShowAutocompleteValues = props.showAutocompleteValues;
      const currentAutocompleteValues = props.autocompleteValues;
      if (props.typeFields[field].type === 'input') {
        if (props.typeFields[field].inputType === 'number') {
          currentFilters[field] = Object.assign(currentFilters[field], value);
        } else {
          currentFilters[field] = value;
        }
      } else {
        currentFilters[field] = value;
      }
      if (currentTypeFields[field].type === 'autocomplete') {
        currentFilters[field] = value;
        try {
          const records = await props.typeFields[field].service.getItems(1, { search: value });
          currentAutocompleteValues[field] = records.data;
        } catch (err) {
          console.log(err);
        }
        currentShowAutocompleteValues[field] = true;
      }
      props.setAutocompleteValues(currentAutocompleteValues);
      props.setShowAutocompleteValues(currentShowAutocompleteValues);
      props.setFilters(currentFilters, () => {
        if (props.filtersActive) {
          props.generateTable();
        }
      });
    },
    selectedFiltersValue: props => (field, value) => {
      const currentFilters = props.filters;
      const currentShowAutocompleteValues = props.showAutocompleteValues;
      currentFilters[field] = value;
      currentShowAutocompleteValues[field] = false;
      props.setShowAutocompleteValues(currentShowAutocompleteValues);
      props.setFilters(currentFilters, () => {
        props.generateTable();
      });
    },
    applyFilters: props => () => {
      props.setFiltersActive(!props.filtersActive, () => {
        props.generateTable();
        props.setFiltersActivePoint(!props.filtersActivePoint);
      });
    },
    updateSorting: props => (sorting) => {
      let sort = '';
      Object.keys(sorting).forEach((field) => {
        sort += `${field}:${sorting[field]};`;
      });
      props.setSorting(sort, () => {
        props.generateTable();
      });
    },
    search: props => (value) => {
      props.setSearchValue(value, () => {
        props.generateTable();
      });
    },
    getExcel: props => async (debet = false) => {
      let params = {};
      // check selected requests
      if (props.selectedRequests.length !== 0) {
        params.id = JSON.stringify(props.selectedRequests);
      } else if (props.filtersActive) {
        // check filters
        params = Object.assign(params, props.filters);
      }
      // check sorting
      if (props.sorting !== '') {
        params.sort = props.sorting;
      }

      Object.keys(params).forEach((key) => {
        if (typeof params[key] === 'object') {
          params[key] = params[key].id || '';
        }
      });

      if (debet) {
        params.typereport = 'debet';
      }

      // get excel from API
      try {
        const response = await RequestsService.getRequestsExcel(params);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log(err);
      }
    },
    addRequest: props => () => {
      props.history.push('/dashboard/task/create');
    },
    deleteRequests: props => async (id = null) => {
      const deleteRequests = [];
      if (id) {
        const result = await RequestsService.deleteRequest(id);
        if (result.status === 200) {
          const requests = props.requests.filter(request => request.id !== id);
          props.setRequests(requests);
        }
        return null;
      }
      props.selectedRequests.forEach((element) => {
        deleteRequests.push(RequestsService.deleteRequest(element));
      });
      
      return Promise.all(deleteRequests)
        .then(() => {
          props.setSelectedRequests([]);
          props.setCheckboxView(false);
          props.generateTable();
          socket.emit('table', 'delete');
        })
        .catch((err) => {
          console.log(err);
          props.setSelectedRequests([]);
          props.setCheckboxView(false);
          props.generateTable();
        });
    },
    amountSuccessfull: props => async (id, value) => {
      if (value) {
        value = false;
      } else {
        value = true;
      }
      try {
        const result = await RequestsService.updateRequest(id, { paymentState: value });
        if (result.status === 200) {
          const requests = props.requests.map((request) => {
            if (request.id === id) {
              request.paymentState = value;
            }
            return request;
          });
          props.setRequests(requests);
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    cancel: props => () => {
      props.setSelectedRequests([], () => {
        const { filters } = props;
        Object.keys(filters).forEach(key => delete filters[key]);
        props.setFilters(filters, () => {
          props.setFiltersActive(false, () => {
            props.setFiltersActivePoint(false);
            props.setCheckboxView(false);
            props.generateTable();
          });
        });
      });
    },
    openRequest: props => (id) => {
      props.history.push(`/dashboard/task/view/${id}`);
    },
    copyRequest: props => async (id) => {
      props.setUpdateTable(true);
      const copiedResponse = await RequestsService.copyRequest(id);
      if (copiedResponse.status === 200) {
        const { _id: newRequestId } = copiedResponse.result;
        const requests = [...props.requests];
        const reqIndex = requests.findIndex((request) => {
          if (request.id === id) {
            return true;
          }
          return false;
        });
        const requestData = await RequestsService.getRequestById(newRequestId);
        // нужно убрать лишние поля
        // поэтому смотрим какие есть в первой заявке
        const neededFields = Object.keys(requests[0]);
        Object.keys(requestData.doc).forEach((field) => {
          if (!neededFields.includes(field)) {
            delete requestData.doc[field];
          }
        });
        requests.splice(reqIndex + 1, 0, { ...requestData.doc, id: newRequestId });
        props.setRequests(requests, () => props.setUpdateTable(false));
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setActiveSidebarItem('task');
      this.props.setSecondaryBarItems([{id: '1', name: 'Актуальные задачи', url: '/dashboard/task/actual',}, {id: '2', name: 'Архив задач', url: '/dashboard/task/archive',}, {id: '3', name: 'Добавление задачи', url: '/dashboard/task/create',}]);
      this.props.setActiveSecondarySidebarItem({id: '2', name: 'Архив задач', url: '/dashboard/task/archive',});
      this.props.setIsLoading(true);
      
      // get available fields from API
      RequestsService.getAvailableFields()
        .then((availableFields) => {
          this.props.setAvailableFields(availableFields, () => {
            this.props.generateTable();
          });
        })
        .catch((err) => {
          console.log(err);
        });
      // сокет, прослушиваем изменения в таблице
      socket.on('table', (message) => {
        console.log(`socket message, request: ${message.event}`);
        this.props.generateTable();
      });
    },
  }),
)(RequestTableView);
