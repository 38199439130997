import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RequestFormView from './RequestFormView';

import {
  AuthService,
  DirectoryService,
  UserService,
  directoryServiceMap,
} from '../../services';

import {
  checkStatusPopup,
} from '../../store/actions/dashboard';

export default compose(
  connect(
    state => ({
      checkStatus: state.dashboard.checkStatusPopup,
    }),
    dispatch => ({
      checkStatusPopup: item => dispatch(checkStatusPopup(item)),
    }),
  ),
  withRouter,
  withState('state', 'setState', ''),
  withState('intervalRequest', 'setIntervalRequest', ''),
  withState('stateClaim', 'setStateClaim', 'Предварительное'),
  withState('showAutocompleteValues', 'setShowAutocompleteValues', {}),
  withState('statusLoadCalendar', 'setStatusLoadCalendar', false),
  withState('statusArrivalCalendar', 'setStatusArrivalCalendar', false),
  withState('statusIssueCalendar', 'setStatusIssueCalendar', false),
  withState('statusCustomerAccountDateCalendar', 'setStatusCustomerAccountDateCalendar', false),
  withState('statusPlannedIssueDateCalendar', 'setStatusPlannedIssueDateCalendar', false),
  withState('paymentDateCalendar', 'setPaymentDateCalendar', false),
  withState('toggleComp', 'setToggleComp', false),
  withState('amountComp', 'setAmountComp', {}),
  withState('statusWindow', 'setStatusWindow', false),

  // устанавливае какой сервис будет текущим
  withState('service', 'setService', null),

  // хуй знает)))
  withState('showCreateForm', 'setShowCreateForm', false),
  withState('hasMore', 'setHasMore', true),

  // активный таб в сайдбаре. Нужно когда у сущности есть привязки
  withState('activeID', 'setActiveID', ''),

  // содержит в себе данные для отображения в правом сайдбаре
  // инпуты, чекбоксы, линки на другие сущности
  withState('blockData', 'setBlockData', {}),


  // опять, когда у сущности есть привязки,
  // то нам надо знать с какой сущностью мы связаны
  withState('linkedService', 'setLinkedService', null),


  // список существующих сущностей
  withState('itemsForLink', 'setItemsForLink', []),

  // структура формы сущности для привязки
  withState('linkedServiceForm', 'setLinkedServiceForm', {}),

  // когда в карточке создаем новую сущность для привязке,
  // то здесь мы храним значения инпутов которые ввели
  withState('linkedServiceInputState', 'setLinkedServiceInputState', {}),

  // следим за инпутами в новой карточке
  withState('newItemData', 'setNewItemData', {}),

  // следим за инпутами в карточке с данными
  // бля, зачем выше тогда отдельный для новой?
  withState('inputs', 'setInputs', {}),

  withState('nameForm', 'setNameForm', ''),

  withHandlers({
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    returnToTheLastPage: props => () => {
      // props.history.push('/dashboard/task/');
      props.history.goBack();
      
    },
    save: props => () => {
      const saveFields = {};
      Object.keys(props.fieldsValue).forEach((key) => {
        if (Object.entries(props.fieldsValue[key]).length === 0) return;
        switch (key) {
          case 'type':
          case 'description':
          case 'powerOfAttorneyNumber':
          case 'requestCountQuantity':
          case 'requestCountWeight':
          case 'requestProduct':
          case 'requestPriceAll':
          case 'requestPriceNds':
          case 'ttn':
          case 'place':
          case 'payer':
          case 'consignee':
          case 'shipper':
          case 'railCarrier':
          case 'agentAutoLoad':
          case 'agentAutoCall':
          case 'ownerContainer':
          case 'company':
          case 'companyMovers':
          case 'addressLoading':
          case 'addressUnloading':
          case 'stationDeparture':
          case 'stationDestination':
          case 'driver':
          case 'car':
          case 'trailer':
          case 'terminalStaging':
          case 'manager':
          case 'loadActual':
          case 'loadDocumented':
          case 'warmingCompany':
          case 'companyNameForwarder':
          case 'companyMoversUnloading':
          case 'companyMoversLoading':
          case 'destinationCity':
            if(typeof props.fieldsValue[key] === 'object'){
              if ('_id' in props.fieldsValue[key]) {
                saveFields[key] = props.fieldsValue[key];
              }
            } else {
              saveFields[key] = props.fieldsValue[key];
            }
            break;
          default:
            if (props.fieldsValue[key].name !== '') {
              saveFields[key] = props.fieldsValue[key];
            }
            break;
        }
      });
      props.saveHandler(saveFields);
      // let error = false;
      // const savePaymentFields = props.payments.filter((item) => {
      //   const nameFields = Object.keys(item);
      //   let flag = true;
      //   nameFields.forEach((name) => {
      //     if (item[name].length === 0) flag = false;
      //   });
      //   return flag;
      // });
      // savePaymentFields.forEach((field) => { if (!field) error = true; });
      // if (error) {
      //   props.functionCheckTime('Error', 'Поля не заполнены');
      // } else {
      //   props.saveHandler(saveFields, savePaymentFields);
      // }
    },
    update: props => () => {
      console.log('update');
      const saveFields = {};
      Object.keys(props.fieldsValue).forEach((key) => {
        // console.log('key', key);
        // if (Object.entries(props.fieldsValue[key]).length === 0) return;
        switch (key) {
          case 'payer':
          case 'executor':
          case 'description':
          case 'ttn':
          case 'powerOfAttorneyNumber':
          case 'requestCountQuantity':
          case 'requestCountWeight':
          case 'requestProduct':
          case 'requestPriceAll':
          case 'requestPriceNds':
          case 'place':
          case 'consignee':
          case 'shipper':
          case 'railCarrier':
          case 'agentAutoLoad':
          case 'agentAutoCall':
          case 'ownerContainer':
          case 'company':
          case 'companyMovers':
          case 'addressLoading':
          case 'addressUnloading':
          case 'stationDeparture':
          case 'stationDestination':
          case 'driver':
          case 'car':
          case 'trailer':
          case 'terminalStaging':
          case 'manager':
          case 'loadActual':
          case 'loadDocumented':
          case 'warmingCompany':
          case 'companyNameForwarder':
          case 'companyMoversUnloading':
          case 'companyMoversLoading':
          case 'destinationCity':
            // console.log('props.fie', props.fieldsValue);
            if ('_id' in props.fieldsValue) {
              if (props.fieldsValue === '') {
                saveFields[key] = '';
                return;
              }
              saveFields[key] = props.fieldsValue[key];
            }
            break;
          default:
            if (props.fieldsValue[key] !== '') {
              saveFields[key] = props.fieldsValue[key];
            }
            break;
        }
      });
      // console.log('save', saveFields);
      props.updateHandler(saveFields);
    },
    createFormModal: props => (service, name) => {
      props.setStatusWindow(true);
      props.setNameForm(name);
      props.setService(directoryServiceMap[service]);
    },
  }),
  lifecycle({
    componentWillUnmount() {
      clearInterval(this.props.intervalRequest);
    },
    componentDidMount() {
      if (this.props.location.pathname.indexOf('edit') !== -1) {
        const intervalUpdateRequest = setInterval(() => {
          this.props.update();
        }, 15000);
        this.props.setIntervalRequest(intervalUpdateRequest);
      }
      if (this.props.values) {
        Object.keys(this.props.values).forEach((key) => {
          if (key !== 'state') {
            this.props.fieldsValue[key] = this.props.values[key];
          } else {
            this.props.setState(this.props.values[key]);
          }
        });
      }
    },
  }),
)(RequestFormView);
