// Auth
export const UPDATE_REFRESH_TOKEN = 'UPDATE_REFRESH_TOKEN';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const SAVE_USER_ACCESSES = 'SAVE_USER_ACCESSES';
export const CLEAN_USER_DATA = 'CLEAN_USER_DATA';

// Users
export const SAVE_USERINFO_FIRSTNAME_DATA = 'SAVE_USERINFO_FIRSTNAME_DATA';
export const SAVE_USERINFO_LASTNAME_DATA = 'SAVE_USERINFO_LASTNAME_DATA';
export const SAVE_USERINFO_LOGIN_DATA = 'SAVE_USERINFO_LOGIN_DATA';
export const SAVE_USERINFO_PHONE_NUMBER_DATA = 'SAVE_USERINFO_PHONE_NUMBER_DATA';
export const SAVE_USERINFO_EMAIL_DATA = 'SAVE_USERINFO_EMAIL_DATA';
export const SAVE_USERINFO_POSITION_DATA = 'SAVE_USERINFO_POSITION_DATA';
export const SAVE_USERINFO_AVATAR_DATA = 'SAVE_USERINFO_AVATAR_DATA';
export const SAVE_USERINFO_PASSWORD_DATA = 'SAVE_USERINFO_PASSWORD_DATA';
export const SAVE_USERINFO_PASSWORD_REPEAT_DATA = 'SAVE_USERINFO_PASSWORD_REPEAT_DATA';

// Dashboard
export const SET_ACTIVE_SIDEBAR_ITEM = 'SET_ACTIVE_SIDEBAR_ITEM';
export const SET_ACTIVE_SECONDARY_SIDEBAR_ITEM = 'SET_ACTIVE_SECONDARY_SIDEBAR_ITEM';
export const SET_SECONDARY_BAR_ITEMS = 'SET_SECONDARY_BAR_ITEMS';
export const CHECK_STATUS_POPUP = 'CHECK_STATUS_POPUP';
export const NEED_PADDING = 'NEED_PADDING';
export const SET_EXPAND = 'SET_EXPAND';
