import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const Box = styled.form`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 112px);
  width: 426px;
  background-color: #fff;
  padding: 40px 0 0;
  margin-top: -${base * 3.5}px;
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100vw;
    padding-top: 48px;
  }
  ${props => props.modaleWindow && css`
    padding: 0;
    margin-top: 24px;
    align-items: center;
  `}
`;

export const Content = styled.div`
  overflow-y: auto;
  width: 100%;
`;

export const FormListInputWrapper = styled.div`
  padding: 0 32px;
  // margin-bottom: 12px;
`;

export const InputWrapper = styled.div`
  input{
    font-size: 16px;
  }
`;

export const Top = styled.div`
  display: flex;
  padding: 0 32px;
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${base - 4}px;
`;

export const Bottom = styled.div`
  padding: 20px 32px 30px;
  border-top: 1px solid #f8f8f8;
  justify-content: space-between;
  display: flex;
  margin: auto 0 0 0;
  button {
    display: inline;
    width: calc(33.33% - 3px);
    text-align: center;
    font-size: 14px;
  }
`;

export const UserAvatar = styled.div`
  width: ${base * 8}px;
  height: ${base * 8}px;
  border-radius: 50%;
  background: url(${props => props.avatar});
  background-size: cover;
  background-color: #fff;
  background-position: 50%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 32px;
`;

export const Paragraph = styled.p`
  margin: 0;
  font-size: 18px;

  ${props => props.firstname && css`
    font-size: 24px;
    font-weight: bold;
  `}

  ${props => props.lastname && css`
    color: ${colors.primary};
    font-size: 24px;
    font-weight: bold;
  `}

  ${props => props.position && css`
    /* font-family: 'MuseoSansCyrl light',sans-serif; */
    color: ${colors.light_gray};
    font-size: 18px;
    margin-top: ${base / 4}px;
  `}

  ${props => props.smallParagraph && css`
    margin: 0;
    color: #000;
    font-size: 24px;
    margin: 6px 0;
    font-size: 14px;
    color: #b3b3b3;
  `}
`;
