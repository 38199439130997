/**
 * @flow
 */
import React from 'react';

import {
  PaymentWrapper,
  PaymentNumber,
  PaymentDate,
  PaymentAmount,
} from './PaymentStyled';

type PaymentViewPropsType = {
  number: string,
  date: String,
  amount: number,
};

export default function PaymentView(props: PaymentViewPropsType) {
  return (
    <PaymentWrapper>
      <PaymentNumber>{props.number}</PaymentNumber>
      <PaymentDate>{props.date}</PaymentDate>
      <PaymentAmount>Сумма: {(props.amount.toLocaleString())}</PaymentAmount>
    </PaymentWrapper>
  );
}
