/**
 * @flow
 */

import React from 'react';


import {
  InputComponent,
  SearchIcon,
  InputWrapper,
} from './InputStyled';

type InputViewPropsType = {
  placeholder?: string,
  valid?: boolean,
  search?: boolean,
  value: any,
  inputType?: string,
  readOnlyState: boolean,
  changed: (any) => void,
  active: (any) => void,
  borderLogin: Boolean,
};

export default function InputView(props: InputViewPropsType) {
  return (
    <InputWrapper>
      {/* {props.search && <SearchIcon search={props.search} />} */}
      <InputComponent
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(event) => {
          props.changed(event.target.value);
        }}
        valid={props.valid}
        search={props.search}
        readOnly={props.readOnlyState}
        onFocus={() => {
          if (props.active) {
            props.active();
          }
        }}
        onBlur={() => props.type === 'date' && props.setInputType('text')}
        borderLogin={props.borderLogin}
      />
    </InputWrapper>
  );
}

InputView.defaultProps = {
  inputType: 'text',
  placeholder: '',
  valid: true,
  search: false,
};
