import {
  apiGet,
  apiPut,
  apiDelete,
  apiPost,
} from '../ApiService';

export default {
  getApiName() {
    return 'counterparties';
  },

  getItems(p = 1, params) {
    return apiGet(`/counterparties?page=${p}`, { params })
      .then(response => ({
        data: response.data.docs.map(item => ({
          id: item._id,
          ...item,
        })),
        records: response.data.pages.allRecords,
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getById(id) {
    return apiGet(`/counterparty/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: response.data.name,
          relations: [],
          inputs: [],
          checkboxes: [],
        };

        result.inputs.push({
          title: 'Страна регистрации',
          name: 'countryRegistration',
          value: response.data.countryRegistration,
        });

        result.inputs.push({
          title: 'ИНН',
          name: 'INN',
          value: response.data.INN,
        });

        result.inputs.push({
          title: 'КПП',
          name: 'KPP',
          value: response.data.KPP,
        });

        result.inputs.push({
          title: 'ОГРН',
          name: 'OGRN',
          value: response.data.OGRN,
        });

        result.inputs.push({
          title: 'Банк',
          name: 'bank',
          value: response.data.bank,
        });

        result.inputs.push({
          title: 'Номер счета',
          name: 'accountNumber',
          value: response.data.accountNumber,
        });

        result.checkboxes.push({
          title: 'Отметка компания Амикс',
          name: 'mark',
          value: response.data.mark,
        });

        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  removeLinkedItem() {
    return null;
  },

  addLinkedItem() {
    return null;
  },

  deleteItem(id) {
    return apiDelete(`/counterparty/${id}`)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getFields() {
    return {
      relations: [],
      inputs: [
        {
          title: 'Название',
          name: 'name',
          value: '',
        },
        {
          title: 'Страна регистрации',
          name: 'countryRegistration',
          value: '',
        },
        {
          title: 'ИНН',
          name: 'INN',
          value: '',
        },
        {
          title: 'КПП',
          name: 'KPP',
          value: '',
        },
        {
          title: 'ОГРН',
          name: 'OGRN',
          value: '',
        },
        {
          title: 'Банк',
          name: 'bank',
          value: '',
        },
        {
          title: 'Номер счета',
          name: 'accountNumber',
          value: '',
        },
      ],
      checkboxes: [
        {
          title: 'Отметка компания Амикс',
          name: 'mark',
          value: false,
        },
      ],
    };
  },

  updateItem(id, body) {
    return apiPut(`/counterparty/${id}`, body)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  createItem(body) {
    return apiPost('/counterparty', body)
      .then(response => ({
        status: response.status,
        data: {
          ...response.data,
          id: response.data._id,
        },
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/counterparties?search=${body.search}`)
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        ...item,
      })))
      .catch(error => console.log('Not Search', error));
  },
};
