/**
 * @flow
 */
import React from 'react';

import {
  LoginForm,
  Logo,
  TimeZones,
  Popup,
} from '../../components';

import {
  Container,
  MainPart,
  SecondaryPart,
  LogoWrapper,
  TimeZonesWrapper,
  AdditionalInformation,
  MainPartContainer,
  PopupMessage,
} from './LoginStyled';

type LoginPagePropsType = {
  setLogin: (any) => void,
  setPassword: (any) => void,
  submitForm: (any) => void,
  error?: string,
  setError: (any) => void,
  isLoading: boolean,
  submitEvent: (any) => void,
  errorName: String,
};

export default function LoginPage(props: LoginPagePropsType) {
  return (
    <Container
      background={require('../../assets/images/logo_bg.png')}
    >
      <MainPart>
        <MainPartContainer>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <LoginForm
            setLogin={props.setLogin}
            setPassword={props.setPassword}
            submitForm={props.submitForm}
            submitEvent={props.submitEvent}
            borderLogin={props.borderLogin}
          />
          <AdditionalInformation>
            <p>
              <a href="https://suffix.by/">
                <img alt="Tapston" src="https://suffix.by/img/logos/logo.svg" />
              </a>
            </p>
          </AdditionalInformation>
        </MainPartContainer>
      </MainPart>
    
      {(!props.login || !props.password)
        ? (
          <PopupMessage
            show={props.error}
            onClick={() => props.setError(null)}
          >
            <Popup status="Error" message="Поля 'Логин' и 'Пароль' должны быть заполнены" />
          </PopupMessage>
        )
        : 
        <PopupMessage
          show={props.error}
          onClick={() => props.setError(null)}
        >
          <Popup status="Error" message={props.errorName} />
        </PopupMessage>
      }
      <PopupMessage
        show={props.isLoading}
      >
        <Popup status="WarningLoader" message="Загрузка" />
      </PopupMessage>
    </Container>
  );
}

LoginPage.defaultProps = {
  error: '',
};
