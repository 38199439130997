import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
} from '../ApiService';

import DriverService from '../DriverService';
import CarService from '../CarService';
import TrailerService from '../TrailerService';

export default {
  getApiName() {
    return 'autoagents';
  },

  getItems(p = 1, params) {
    return apiGet(`/autoagents?page=${p}`, { params })
      .then(response => ({
        data: response.data.docs.map(item => ({
          id: item._id,
          ...item,
        })),
        records: response.data.pages.allRecords,
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getItem(id) {
    return apiGet(`/autoagent/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: response.data.name,
          counters: [],
        };
        const lengthDrivers = response.data.drivers.length;
        const lengthCars = response.data.cars.length;
        const lengthTrailers = response.data.trailers.length;
        if (lengthDrivers > 0) {
          switch (lengthDrivers) {
            case 11:
              result.counters.push(`${lengthDrivers} водителей`);
              break;
            case 1:
            case Number(`${Math.floor(lengthDrivers / 10)}1`):
              result.counters.push(`${lengthDrivers} водитель`);
              break;
            case 2:
            case 3:
            case 4:
              result.counters.push(`${lengthDrivers} водителя`);
              break;
            default:
              result.counters.push(`${lengthDrivers} водителей`);
              break;
          }
        }
        if (lengthCars > 0) {
          switch (lengthCars) {
            case 11:
              result.counters.push(`${lengthCars} машин`);
              break;
            case 1:
            case Number(`${Math.floor(lengthCars / 10)}1`):
              result.counters.push(`${lengthCars} машина`);
              break;
            case 2:
            case 3:
            case 4:
              result.counters.push(`${lengthCars} машины`);
              break;
            default:
              result.counters.push(`${lengthCars} машин`);
              break;
          }
        }
        if (lengthTrailers > 0) {
          switch (lengthTrailers) {
            case 11:
              result.counters.push(`${lengthTrailers} прицепов`);
              break;
            case 1:
            case Number(`${Math.floor(lengthTrailers / 10)}1`):
              result.counters.push(`${lengthTrailers} прицеп`);
              break;
            case 2:
            case 3:
            case 4:
              result.counters.push(`${lengthTrailers} прицепа`);
              break;
            default:
              result.counters.push(`${lengthTrailers} прицепов`);
              break;
          }
        }
        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getById(id) {
    return apiGet(`/autoagent/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: response.data.name,
          relations: [],
          inputs: [],
          checkboxes: [],
        };

        if (response.data.drivers.length > 0) {
          result.relations.push({
            title: 'Водители',
            name: 'drivers',
            items: response.data.drivers.map(driver => ({
              ...driver,
              name: `Ф.И.О: ${driver.name},
                    Паспортные данные: ${driver.driversLicense},
                    Водительское удостоверение: ${driver.passportDetails}`,
            })),
            linkedService: DriverService,
          });
        } else {
          result.relations.push({
            title: 'Водители',
            name: 'drivers',
            items: [],
            linkedService: DriverService,
          });
        }

        if (response.data.cars.length > 0) {
          result.relations.push({
            title: 'Машины',
            name: 'cars',
            items: response.data.cars.map(car => ({
              ...car,
              name: `Марка: ${car.name}, Гос. номер: ${car.number}`,
            })),
            linkedService: CarService,
          });
        } else {
          result.relations.push({
            title: 'Машины',
            name: 'cars',
            items: [],
            linkedService: CarService,
          });
        }

        if (response.data.trailers.length > 0) {
          result.relations.push({
            title: 'Прицепы',
            name: 'trailers',
            items: response.data.trailers.map(trailer => ({
              ...trailer,
              name: `Номер: ${trailer.number}`,
            })),
            linkedService: TrailerService,
          });
        } else {
          result.relations.push({
            title: 'Прицепы',
            name: 'trailers',
            items: [],
            linkedService: TrailerService,
          });
        }

        result.inputs.push({
          title: 'Название',
          name: 'name',
          value: response.data.name,
        });

        result.inputs.push({
          title: 'Контакты',
          name: 'contacts',
          value: response.data.contacts,
        });

        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  removeLinkedItem(id, item, itemId) {
    let result = null;
    if (item === 'drivers') {
      result = apiPut(`/autoagent/${id}?deldriver=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    } else if (item === 'cars') {
      result = apiPut(`/autoagent/${id}?delcar=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    } else if (item === 'trailers') {
      result = apiPut(`/autoagent/${id}?deltrailer=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    }

    return result;
  },

  addLinkedItem(id, item, itemId) {
    let result = null;
    if (item === 'drivers') {
      result = apiPut(`/autoagent/${id}?adddriver=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    } else if (item === 'cars') {
      result = apiPut(`/autoagent/${id}?addcar=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    } else if (item === 'trailers') {
      result = apiPut(`/autoagent/${id}?addtrailer=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    }

    return result;
  },

  deleteItem(id) {
    return apiDelete(`/autoagent/${id}`)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getFields() {
    return {
      relations: [
        {
          title: 'Водители',
          name: 'drivers',
          items: null,
          linkedService: DriverService,
        },
        {
          title: 'Машины',
          name: 'cars',
          items: null,
          linkedService: CarService,
        },
        {
          title: 'Прицеп',
          name: 'trailers',
          items: null,
          linkedService: TrailerService,
        },
      ],
      inputs: [
        {
          title: 'Название',
          name: 'name',
        },
        {
          title: 'Контакты',
          name: 'contacts',
        },
      ],
      checkboxes: [],
    };
  },

  updateItem(id, body) {
    return apiPut(`/autoagent/${id}`, body)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  createItem(body) {
    return apiPost('/autoagent', body)
      .then(response => ({
        status: response.status,
        data: {
          id: response.data._id,
          ...response.data,
        },
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/autoagents?search=${body.search}`)
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        ...item,
      })))
      .catch(error => console.log('Not Search', error));
  },
};
