/**
 * @flow
 */
import React from 'react';

import {
  HeaderColumn,
  TextHeaderColumn,
  SortingHeaderColumn,
} from './TableHeaderStyled';

type TableHeaderPropsType = {
  trName: String,
  columnTitles: Object,
  checkboxView: boolean,
  tableSorting: Object,
  selectAllRequests: (boolean) => void,
  sortTable: (string) => void,
};

export default function TableHeaderView(props: TableHeaderPropsType) {
  const header = [];

  let sortWay = null;
  let sortNumber = 1;
  let sortNumberStatus = false;

  Object.keys(props.columnTitles).forEach((key) => {
    Object.keys(props.tableSorting).forEach((field) => {
      if (field !== 'number' && !sortNumberStatus) {
        sortNumber += 1;
      } else {
        sortNumberStatus = true;
      }
    });
    if (props.tableSorting.number === 'dn') {
      sortWay = '↓';
    } else if (props.tableSorting.number === 'up') {
      sortWay = '↑';
    }

    if (key !== 'number') {
      let sortingWay = null;
      let sortingNumber = 1;
      let sortingNumberStatus = false;
      if (props.tableSorting[key]) {
        Object.keys(props.tableSorting).forEach((field) => {
          if (key !== field && !sortingNumberStatus) {
            sortingNumber += 1;
          } else {
            sortingNumberStatus = true;
          }
        });
        if (props.tableSorting[key] === 'dn') {
          sortingWay = '↓';
        } else if (props.tableSorting[key] === 'up') {
          sortingWay = '↑';
        }
      }
      header.push(
        <td key={key}>
          <HeaderColumn>
            <TextHeaderColumn onClick={() => props.sortTable(key)}>
              {props.columnTitles[key]}
            </TextHeaderColumn>
            {(sortingWay) ? <SortingHeaderColumn>{sortingNumber} {sortingWay}</SortingHeaderColumn> : null}
          </HeaderColumn>
        </td>,
      );
    }
  });

  return (
    <tr className={props.trName}>
      <td key="checkbox">
        <HeaderColumn>
          <input
            type="checkbox"
            checked={props.checkboxView}
            onChange={props.selectAllRequests}
          />
        </HeaderColumn>
      </td>
      <td key="number">
        <HeaderColumn>
          <TextHeaderColumn onClick={() => props.sortTable('number')}>
            {'Номер задачи:'}
          </TextHeaderColumn>
          {(sortWay) ? <SortingHeaderColumn>{sortNumber} {sortWay}</SortingHeaderColumn> : null}
        </HeaderColumn>
      </td>
      {header}
    </tr>
  );
}
