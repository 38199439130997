import { compose, lifecycle } from 'recompose';

import TableColumnView from './TableColumnView';

export default compose(
  lifecycle({
    shouldComponentUpdate(nextProps) {
      if (nextProps.update || this.props.update) {
        return true;
      }
      return false;
    },
  }),
)(TableColumnView);
