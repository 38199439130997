import styled, { css } from 'styled-components';

import { base } from '../../styles';

export const Popup = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 24px 48px;
    border-radius: 6px;
    box-shadow: 0 12px 20px #efefef;
    z-index: 5000;
    ${props => props.status === 'Warning' && css`
        display: block;
        text-align: center;
        color: #black;
        background-color: #fff;
    `}

    ${props => props.status === 'WarningLoader' && css`
        color: #black;
        background-color: #fff;
    `}

    ${props => props.status === 'Info' && css`
        color: #154080;
        background-color: #d0e4fc;
    `}
    ${props => props.status === 'Success' && css`
        color: #2a552a;
        background-color: #d9ecdb;
    `}
    ${props => props.status === 'Error' && css`
        color: #692326;
        background-color: #f3d8da;
    `}
`;
