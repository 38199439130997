import styled, { css } from 'styled-components';
import { colors, base, screens } from '../../styles';

export const Form = styled.div`
  background: ${colors.primary_gray};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: sticky;
  top: 84px;
  background: ${colors.primary_gray};
  z-index: 9;
  margin-bottom: 16px;
  @media (min-width: ${screens.screen_xs_max}) {
    margin-top: -12px;
  }
  h1{
    margin-right: 60px;
  }
`;

export const TitleText = styled.h1`
  margin: 0;
  margin-right: 60px;
  font-size: 42px;
  font-weight: 600;

  @media (max-width: ${screens.screen_3xs_max}) {
    margin-right: 0;
  }

  @media (max-width: ${screens.screen_xs_max}) {
    font-size: 24px;
    width: 100%;
    margin-bottom: 18px;
  }

`;

export const TitleButton = styled.div`
  margin-right: 12px;
`;
export const InputData = styled.input`
    border-radius: 6px;
    border: 1px solid #efefef;
    padding: 10px 12px;
    font-size: 16px;
    color: #000;
    outline: none;
    width: 100%;
    background: #fff;
    cursor: pointer;

`;
export const InputTime = styled.input`
    border-radius: 6px;
    border: 1px solid #efefef;
    padding: 10px 12px;
    font-size: 16px;
    color: #000;
    outline: none;
    width: 100%;
    background: #fff;
    cursor: pointer;
`;

export const CalcValue = styled.div`
  font-size: 20px;
  font-weight: 800;
  ${(props) => {
    if (props.value < 0) {
      return css`
        color: ${colors.primary};
      `;
    }
    return false;
  }}
`;

export const StateContainer = styled.div`
  background: ${colors.white};
  padding: 14px 0 16px 16px;
`;

export const StateButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 3px;
`;

export const OuterFormCheckboxWrapper = styled.div`
  margin-top: ${base * 1.6}px;
`;

export const StateButton = styled.div`
  font-size: 13px;
  color: ${colors.history_gray};
  white-space: nowrap;
  background-color: ${colors.white};
  outline: none;
  cursor: pointer;
  padding: 6px 12px;
  margin: 0 12px 0 0;
  border: 1px solid #b8b8b8;
  border-radius: 3px;
  text-align: center;
  margin-top: 8px;
  ${(props) => {
    if (props.active) {
      switch (props.status) {
        case 'Предварительное':
          return css`
            background-color: #A9D08E;
            border: 1px solid #A9D08E;
            color: white;
          `;
        case 'В пути':
          return css`
            background-color: #FFFF00;
            border: 1px solid #FFFF00;
            color: black;
          `;
        case 'Прибыл':
          return css`
            background-color: #da9d0a;
            border: 1px solid #da9d0a;
            color: white;
          `;
        case 'Выдан клиенту':
          return css`
            background-color: #0ada35;
            border: 1px solid #0ada35;
            color: white;
          `;
        case 'Прогон':
          return css`
            background-color: #F4B084;
            border: 1px solid #F4B084;
            color: white;
          `;
        case 'Закрыта':
          return css`
            background-color: #808080;
            border: 1px solid #808080;
            color: white;
          `;
        case 'Вывоз':
          return css`
            background-color: #F58223;
            border: 1px solid #F58223;
            color: white;
          `;
        default:
          return css`
            background-color: #aeda9b;
            border: 1px solid #b8b8b8;
          `;
      }
    }
    return true;
  }}
`;

export const BasicInformationContainer = styled.div`
  padding: 20px 20px 0 0;
  border-top: 66px solid transparent;
  margin-top: -66px;
  max-width: 1235px;
  @media (max-width: ${screens.screen_xs_max}) {
    padding: 20px 0 0 0;
  }
`;

export const CargoInformationContainer = styled.div`
  padding: 20px 20px 0 0;
  border-top: 66px solid transparent;
  margin-top: -66px;
  @media (max-width: ${screens.screen_xs_max}) {
    padding: 20px 0 0 0;
  }
`;

export const AdditionalServicesContainer = styled.div`
  padding: 20px 20px 0 0;
  border-top: 66px solid transparent;
  margin-top: -66px;
  @media (max-width: ${screens.screen_xs_max}) {
    padding: 20px 0 0 0;
  }
`;

export const CountingContainer = styled.div`
  padding: 20px 20px 0 0;
  border-top: 66px solid transparent;
  margin-top: -66px;
  @media (max-width: ${screens.screen_xs_max}) {
    padding: 20px 0 0 0;
  }
`;

export const ContainerTitle = styled.div`
  padding-bottom: 20px;
  font-weight: 400;
`;

export const ContainerValues = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div > div {
    margin-bottom: 8px!important;
  }
`;

export const ContainerValue = styled.div`
  width: 250px;
  margin: 0 60px 20px 0;
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`;

export const ValueTitle = styled.div`
  margin-bottom: 0;
  font-size: 13px;
`;

export const Select = styled.select`
  width: 100%;
  height: ${base * 3.6}px;
  outline: none;
  color: ${colors.black};
  padding: 0 12px;
  border: none;
  border-radius: 6px;
  background-color: ${colors.white_smoke};
`;

export const AutocompleteItem = styled.div`
  padding: 6px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: ${colors.primary};
  }
`;

export const ValueOptions = styled.div`
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  width: 242px;
  max-height: 166px;
  margin-top: 6px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 12px 20px #efefef;
  padding: 6px 4px;
`;

export const OptionValues = styled.div`
  padding: 3px 6px 3px 6px;

  &:active {
    color: ${colors.primary};
  }
`;

export const CalendarContainer = styled.div`
  position: absolute;
  z-index: 9999;
`;
