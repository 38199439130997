import {
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'recompose';
import moment from 'moment-timezone';

import TimeZonesView from './TimeZonesView';

const zones = [
  {
    city: 'Москва',
    zone: 'Europe/Moscow',
    time: '',
  },
  {
    city: 'Екатеринбург',
    zone: 'Asia/Yekaterinburg',
    time: '',
  },
  {
    city: 'Новосибирск',
    zone: 'Asia/Novosibirsk',
    time: '',
  },
  {
    city: 'Красноярск',
    zone: 'Asia/Krasnoyarsk',
    time: '',
  },
  {
    city: 'Иркутск',
    zone: 'Asia/Irkutsk',
    time: '',
  },
  {
    city: 'Якутск',
    zone: 'Asia/Yakutsk',
    time: '',
  },
  {
    city: 'Хабаровск',
    zone: 'Asia/Vladivostok',
    time: '',
  },
  {
    city: 'Владивосток',
    zone: 'Asia/Vladivostok',
    time: '',
  },
  {
    city: 'Ю.-Сахалинск',
    zone: 'Asia/Srednekolymsk',
    time: '',
  },
  {
    city: 'Магадан',
    zone: 'Asia/Srednekolymsk',
    time: '',
  },
  {
    city: 'Петропаловск-Кам.',
    zone: 'Asia/Kamchatka',
    time: '',
  },
];

export default compose(
  withState('interval', 'setInterval', null),
  withState('zones', 'setZones', zones),
  withHandlers({
    updateTime: props => () => {
      const times = zones.map(zone => ({
        ...zone,
        time: moment.tz(zone.zone).format('HH:mm'),
      }));

      props.setZones(times);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateTime();

      const interval = setInterval(() => {
        this.props.updateTime();
      }, 60000);

      this.props.setInterval(interval);
    },
    componentWillUnmount() {
      clearInterval(this.props.interval);
    },
  }),
)(TimeZonesView);
