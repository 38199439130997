import { compose, lifecycle } from 'recompose';

import TableHeaderView from './TableHeaderView';

// хранит предыдущее состояние
// нужно для того, что бы убрать стрелку,
// когда сбросили сортировку
let currentSorting = false;

export default compose(
  lifecycle({
    shouldComponentUpdate(nextProps) {
      if (this.props.trName === 'table-header-original') {
        return false;
      }
      let update = false;
      if (this.props.checkboxView !== nextProps.checkboxView) {
        update = true;
      }

      const tableSortingLength = Object.keys(this.props.tableSorting).length;
      const nextTableSortingLength = Object.keys(nextProps.tableSorting).length;

      if (tableSortingLength > 0 || nextTableSortingLength > 0) {
        currentSorting = true;
        update = true;
      } else if (currentSorting) {
        currentSorting = false;
        update = true;
      }

      return update;
    },
  }),
)(TableHeaderView);
