import AddressService from './AddressService';
import AuthService from './AuthService';
import AutoagentService from './AutoagentService';
import CarService from './CarService';
import ConsigneeService from './ConsigneeService';
import CounterpartyService from './CounterpartyService';
import DirectoryService from './DirectoryService';
import DriverService from './DriverService';
import LoadService from './LoadService';
import PayerService from './PayerService';
import RequestsHistoryService from './RequestsHistoryService';
import RequestsService from './RequestsService';
import RoleService from './RoleService';
import ShipperService from './ShipperService';
import StationService from './StationService';
import TerminalService from './TerminalService';
import TrailerService from './TrailerService';
import UserService from './UserService';
import UserPayersService from './UserPayersService';
import PaymentsService from './PaymentsService';
import PlacesService from './PlacesService';
import RefuelingService from './RefuelingService';
import MessagesService from './MessagesService';
import ShiftsService from './ShiftsService';
import ProductService from './ProductService';

export {
  AddressService,
  AuthService,
  AutoagentService,
  CarService,
  ProductService,
  ConsigneeService,
  CounterpartyService,
  DirectoryService,
  DriverService,
  LoadService,
  PayerService,
  RequestsHistoryService,
  RequestsService,
  RoleService,
  ShipperService,
  StationService,
  TerminalService,
  TrailerService,
  UserService,
  UserPayersService,
  PaymentsService,
  PlacesService,
  RefuelingService,
  ShiftsService,
  MessagesService,
};

export const directoryServiceMap = {
  refueling: RefuelingService,
  messages: MessagesService,
  places: PlacesService,
  payers: PayerService,
  counterparties: CounterpartyService,
  shippers: ShipperService,
  consignees: ConsigneeService,
  autoagents: AutoagentService,
  drivers: DriverService,
  cars: CarService,
  products: ProductService,
  trailers: TrailerService,
  stations: StationService,
  loads: LoadService,
  terminals: TerminalService,
  payments: PaymentsService,
  shifts: ShiftsService,
};
