/**
 * @flow
 */
import React from 'react';

import {
  Form,
  Container,
  DateTime,
  Description,
  Line,
  UserName,
  Field,
  ArrowHorizontal,
  ArrowVertical,
  TextGray,
  MobileUserTime,
  MobileDateTime,
} from './HistoryItemStyled';

type HistoryItemPropsType = {
  date?: string,
  time?: string,
  userName?: string,
  inputName?: string,
  nextValue?: string,
  previousValue?: string,
};

export default function HistoryItemView(props: HistoryItemPropsType) {
  const maxLength = 40;
  const arrowHorizontal = require('../../assets/icons/arrow-horizontal.svg');
  const arrowVertical = require('../../assets/icons/arrow-vertical.svg');
  const previousValue = (props.previousValue) ? props.previousValue : 'пустое поле';
  const nextValue = (props.nextValue) ? props.nextValue : 'пустое поле';
  const length = previousValue.length + nextValue.length;

  return (
    <Form>
      <Line />
      <Container>
        <DateTime>
          <div>{props.date}</div>
          <div>{props.time}</div>
        </DateTime>
        <Description>
          <UserName>{props.userName}</UserName>
          <MobileUserTime>
            <div>{props.userName}</div>
            <MobileDateTime>{props.time} {props.date}</MobileDateTime>
          </MobileUserTime>
          <TextGray>
            <Field>{props.inputName}</Field>
            {(length < maxLength)
              ? <div style={{ float: 'left', fontSize: '14px' }}>{previousValue}</div>
              : <div style={{ float: 'left', fontSize: '14px' }}>{previousValue}</div>}
            {(length < maxLength)
              ? (
                <div style={{ float: 'left', margin: '-3px 12px 0 12px' }}>
                  <ArrowHorizontal src={arrowHorizontal} alt="arrow" />
                </div>
              )
              : (
                <div style={{ float: 'left', width: '100%' }}>
                  <ArrowVertical src={arrowVertical} alt="arrow" />
                </div>
              )}
          </TextGray>
          {(length < maxLength)
            ? <div style={{ float: 'left', fontSize: '14px' }}>{nextValue}</div>
            : <div style={{ float: 'left', fontSize: '14px' }}>{nextValue}</div>}
        </Description>
      </Container>
    </Form>
  );
}

HistoryItemView.defaultProps = {
  date: '',
  time: '',
  userName: '',
  inputName: '',
  nextValue: '',
  previousValue: '',
};
