import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const ContainerRadioButton = styled.div`
    display: flex;
    font-size: 20px;
    cursor: pointer;
`;

export const RadioButton = styled.div`
    width: 20px;
    height: 20px;
    border: 1px solid #777777;
    border-radius: 50%;
    margin-right: 24px;
    &:hover{
        border: 1px solid ${colors.primary};
    }
    ${props => props.status && css`
        border: 1px solid ${colors.primary};
    `}
`;

export const LabelRadioButton = styled.div``;

export const Circle = styled.div`
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: none;
    margin: 10% 0 0 10%;
    ${props => props.status && css`
        background-color: ${colors.primary};
    `}
`;
