/**
 * @flow
 */

import React from 'react';

import {
  Button,
} from '../index';

import {
  Cross,
  Payer,
  PayerForm,
  NameCompany,
  Counter,
  Name,
  CountersCompany,
} from './PayerStyled';

type PayerPropsType = {
  name: string,
  counters?: Array<any>,
  isActive: boolean,
  clickItem: (any) => void,
  crossClick: (any) => void,
};

export default function PayerView(props: PayerPropsType) {
  const countersCompany = Array.prototype.map.call(props.counters, (el, i) => (
    <Counter key={i}>
      {el}
    </Counter>
  ));

  return (
    <Payer isActive={props.isActive}>
      <Cross>
        <Button
          cross
          clicked={() => {
            props.crossClick();
          }}
        />
      </Cross>
      <PayerForm onClick={props.clickItem}>
        <NameCompany>
          <Name>{props.name}</Name>
        </NameCompany>
        {
          countersCompany.length > 0 && (
            <CountersCompany>
              {countersCompany}
            </CountersCompany>
          )
        }
      </PayerForm>
    </Payer>
  );
}

PayerView.defaultProps = {
  counters: [],
};
