import {
  compose,
  withState,
  withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';

import RequestTableView from './RequestTableView';

let timerId = null;
let prevent = false;

export default compose(
  withRouter,
  withState('tableSorting', 'setTableSorting', {}),
  withState('editField', 'setEditField', {
    requestId: null,
    name: '',
    value: '',
    edit: false,
  }),

  withHandlers({
    handleClick: props => (key, value) => {
      if (props.editField.edit) return;
      timerId = setTimeout(() => {
        if (!prevent) {
          props.addFilter(key, value);
        }
        prevent = false;
      }, 300);
    },
    handleDoubleClick: props => (id, key, value) => {
      clearTimeout(timerId);
      prevent = true;
      props.setEditField({
        requestId: id,
        name: key,
        value,
        edit: true,
      });
    },
  }),

  withHandlers({
    sortTable: props => (field) => {
      const updateTableSorting = props.tableSorting;
      if (updateTableSorting[field]) {
        if (updateTableSorting[field] === 'dn') {
          updateTableSorting[field] = 'up';
        } else if (updateTableSorting[field] === 'up') {
          delete updateTableSorting[field];
        }
      } else {
        updateTableSorting[field] = 'dn';
      }
      props.setTableSorting(updateTableSorting);
      props.setSorting(props.tableSorting);
    },
    openRequest: props => (id) => {
      props.openRequest(id);
    },
    openEditPage: props => (id) => {
      props.history.push(`/dashboard/task/edit/${id}`);
    },
    editRequest: props => async (cb) => {
      const { requestId, name, value } = props.editField;
      await props.updateRequest(requestId, name, value);
      cb();
    },
  }),
)(RequestTableView);
