import styled from 'styled-components';

import { colors } from '../../../styles';

export const TableColumn = styled.td`
  outline: none;
  cursor: pointer;
  position: relative;
  border-left: 1px solid ${colors.primary_gray};
  &:hover {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: ${colors.light_gray};
      width: 100%;
    }
  }
`;
