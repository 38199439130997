/**
 * @flow
 */
import React from 'react';

import { TableColumn } from './TableColumnStyled';

type TableColumnPropsType = {
  children: Array<any>,
  onClick: (any) => void,
  onDoubleClick: (any) => void,
};

function Column(props: TableColumnPropsType) {
  return (
    <TableColumn
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
    >
      {props.children}
    </TableColumn>
  );
}

export default Column;
