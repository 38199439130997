import * as actionTypes from './actionTypes';

export function setActiveSidebarItem(data) {
  return {
    type: actionTypes.SET_ACTIVE_SIDEBAR_ITEM,
    payload: data,
  };
}

export function setActiveSecondarySidebarItem(data) {
  return {
    type: actionTypes.SET_ACTIVE_SECONDARY_SIDEBAR_ITEM,
    payload: data,
  };
}

export function setSecondaryBarItems(data) {
  return {
    type: actionTypes.SET_SECONDARY_BAR_ITEMS,
    payload: data,
  };
}

export function checkStatusPopup(data) {
  return {
    type: actionTypes.CHECK_STATUS_POPUP,
    payload: data,
  };
}

export function setNeedPadding(state) {
  return {
    type: actionTypes.NEED_PADDING,
    payload: state,
  };
}

export function setExpand(state) {
  return {
    type: actionTypes.SET_EXPAND,
    payload: state,
  };
}
