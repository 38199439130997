import {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
  } from '../ApiService';
  
  export default {
    getApiName() {
      return 'places';
    },
  
    getItems(p = 1, params) {
      return apiGet(`/places?page=${p}`, { params })
        .then(response => ({
          data: response.data.docs.map(places => ({
            ...places,
            id: places._id,
            name: `Место: ${places.name}, Адрес: ${places.address}`,
          })),
          records: response.data.records.all,
        }))
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },
  
    getById(id) {
      return apiGet(`/places/${id}`)
        .then((response) => {
          const result = {
            id: response.data._id,
            name: `${response.data.name}; ${response.data.address}`,
            relations: [],
            inputs: [],
            checkboxes: [],
            dropdowns: [],
          };
  
          result.inputs.push({
            title: 'Место',
            name: 'name',
            value: response.data.name,
          });
  
          result.inputs.push({
            title: 'Адрес',
            name: 'address',
            value: response.data.address,
          });
  
          return result;
        })
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },
  
    removeLinkedItem() {
      return null;
    },
  
    addLinkedItem() {
      return null;
    },
  
    deleteItem(id) {
      return apiDelete(`/places/${id}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },
  
    getFields() {
      return {
        relations: [],
        inputs: [
          {
            title: 'Место',
            name: 'name',
          },
          {
            title: 'Адрес',
            name: 'address',
          },
        ],
        checkboxes: [],
        dropdowns: [],
      };
    },
  
    updateItem(id, body) {
      return apiPut(`/places/${id}`, body)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },
  
    createItem(body) {
      return apiPost('/places', body)
        .then(response => ({
          status: response.status,
          data: {
            ...response.data,
            id: response.data._id,
            name: `Место: ${response.data.name}, Адрес: ${response.data.address}`,
          },
        }))
        .catch((error) => {
          console.log('error', error);
          throw new Error(error.response.data.error.message);
        });
    },
  
    searchItem(body) {
      return apiGet(`/places?search=${body.search}`)
        .then(response => response.data.docs.map(places => ({
          ...places,
          id: places._id,
          name: `Место: ${places.name}, Адрес: ${places.address}`,
        })))
        .catch(error => console.log('Not Search', error));
    },
  };
  