/**
 * @flow
 */
import React from 'react';

import {
  PageTitle,
  TimeZones,
} from '../../../components';
import {
  Content,
  TimeZonesWrapper,
  UserName,
  TimeZonesTitle,
  Greeting,
} from './MainStyled';

type MainPropsType = {
  user: Object,
};

export default function Main(props: MainPropsType) {
  return (
    <Content>
      <Greeting>
        <PageTitle>
          Здравствуйте, <UserName> {props.user.firstName}!</UserName>
        </PageTitle>
      </Greeting>
    </Content>
  );
}
