import {
  compose,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose';

import { connect } from 'react-redux';

// services
import UserService from '../../../../services/UserService';

// actions
import { saveUserData } from '../../../../store/actions/auth';
import {
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setSecondaryBarItems,
  checkStatusPopup,
} from '../../../../store/actions/dashboard';

// view
import PersonalInfoView from './PersonalInfoView';

export default compose(
  withState('saveIMG', 'setSaveIMG'),
  withState('toggleState', 'setToggleState', false),
  withState('saveResualt', 'setSaveResualt', false),
  withState('firstName', 'setFirstName', ''),
  withState('lastName', 'setLastName', ''),
  withState('position', 'setPosition', ''),
  withState('login', 'setLogin', ''),
  withState('phoneNumber', 'setPhoneNumber', ''),
  withState('email', 'setEmail', ''),
  withState('avatar', 'setAvatar', ''),

  connect(
    state => ({
      user: state.auth.currentUser,
      checkStatus: state.dashboard.checkStatusPopup,
      activeSecondaryItem: state.dashboard.activeSecondaryItem,
    }),
    dispatch => ({
      saveUserData: data => dispatch(saveUserData(data)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: item => dispatch(checkStatusPopup(item)),
      setActiveSecondaryItem: item => dispatch(setActiveSecondarySidebarItem(item)),
    }),
  ),
  withHandlers({
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    myAvatar: ({ setSaveIMG, setAvatar }) => (e) => {
      if (e.target.files[0]) {
        setAvatar(e.target.files[0]);
        setSaveIMG(e.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
          setSaveIMG(reader.result);
        };
      }
    },
    statusCheckInfo: ({
      firstName, lastName, phoneNumber, email, setToggleState,
    }) => () => {
      if (firstName && lastName && phoneNumber && email !== '') {
        setToggleState(true);
      } else {
        setToggleState(false);
      }
    },
    saveProfileData: props => async () => {
      const formData = new FormData();
      formData.append('firstName', props.firstName);
      formData.append('lastName', props.lastName);
      formData.append('phone', props.phoneNumber);
      formData.append('email', props.email);
      formData.append('avatar', props.avatar);
      try {
        const result = await UserService.updateUser(props.user.id, formData, { contentType: 'multipart/form-data' });
        const outcome = await UserService.getUserById(props.user.id);
        const userData = { ...props.user, ...outcome };
        if (result.status === 200) {
          props.saveUserData(userData);
          props.functionCheckTime('Success', 'Данные сохранены');
        }
      } catch (error) {
        console.log('error', error.message);
        props.functionCheckTime('Error', error.message);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      UserService.getUserById(this.props.user.id)
        .then((response) => {
          this.props.saveUserData({ ...this.props.user, ...response });
        })
        .catch(error => console.log('error', error));
      this.props.setFirstName(this.props.user.firstName);
      this.props.setLastName(this.props.user.lastName);
      this.props.setPosition(this.props.user.roleName);
      this.props.setLogin(this.props.user.login);
      this.props.setPhoneNumber(this.props.user.phone);
      this.props.setEmail(this.props.user.email);
      if (this.props.user.avatar == null) {
        this.props.setSaveIMG(require('../../../../assets/images/default_avatar.png'));
      } else {
        this.props.setSaveIMG(this.props.user.avatar);
      }

      this.props.setSecondaryBarItems(
        [
          {
            id: '123',
            name: 'Личная информация',
            url: '/dashboard/profile/personalInfo',
          },
          {
            id: '1234',
            name: 'Смена пароля',
            url: '/dashboard/profile/changePassword',
          },
          {
            id: '12345',
            name: 'Настройка колонок',
            url: '/dashboard/profile/custColumns',
          },
        ]);
      this.props.setActiveSecondaryItem(
        {
          id: '123',
          name: 'Личная информация',
          url: '/dashboard/profile/personalInfo',
        },
      );
    },
  }),
)(PersonalInfoView);
