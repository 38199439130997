import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';
import moment from 'moment';
import { connect } from 'react-redux';

// view
import RequestFromView from './RequestFormView';

// actions
import { saveUserAccesses } from '../../../store/actions/auth';
import {
  setSecondaryBarItems,
  setActiveSecondarySidebarItem,
  checkStatusPopup,
} from '../../../store/actions/dashboard';

// services
import PayerService from '../../../services/PayerService';
import CounterpartyService from '../../../services/CounterpartyService';
import ShipperService from '../../../services/ShipperService';
import ConsigneeService from '../../../services/ConsigneeService';
import AutoagentService from '../../../services/AutoagentService';
import StationService from '../../../services/StationService';
import LoadService from '../../../services/LoadService';
import TerminalService from '../../../services/TerminalService';
import { RequestsService, CarService, UserService, PlacesService, ProductService } from '../../../services';

// socket service
import {
  socket,
} from '../../../services/socket';
const FIELDS_HEADER = {
  number: "Номер задачи:",
  type: "Тип задачи",
  status: "Статус",
  created: "Дата, время создания:",
  execution: "Дата, время исполнения",
  description: "Описание задачи",
  'creator.firstName': "Имя создателя",
  'creator.lastName': "Фамилия создателя",
  'creator.phone': "Телефон создателя",
  'creator.email': "Электронная почта создателя",
  'executor.firstName': "Имя исполнителя",
  'executor.lastName': "Фамилия исполнителя",
  'executor.phone': "Телефон исполнителя",
  'executor.email': "Электронная почта исполнителя",
  'car.name': "Название машины",
  'car.number': "Номер машины",
  'place.name': "Название места",
  'place.address': "Адрес места",
  'powerOfAttorneyNumber': "Номер доверенности",
  'odometer.start': "Начальные показания одометра",
  'odometer.end': "Конечные показания одометра",
  'requestCountQuantity': "Задача кол-во",
  'requestCountWeight': "Задача вес",
  'requestPriceAll': "Задача общая сумма",
  'requestPriceNds': "Задача сумма НДС",
  ttn: "Номер ТТН",
  'requestProduct.description': "Задача описание продукта",
  'requestProduct.name': "Задача название продукта",
  problem: "Проблемы",
  __v: "Кол-во изменений документа"
}
export default compose(
  connect(
    state => ({
      accesses: state.auth.userAccesses,
      checkStatus: state.dashboard.checkStatusPopup,
    }),
    dispatch => ({
      saveUserAccesses: data => dispatch(saveUserAccesses(data)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: item => dispatch(checkStatusPopup(item)),
    }),
  ),
  withState('autocompleteValues', 'setAutocompleteValues', {}),
  withState('edit', 'setEdit', false),
  withState('total', 'setTotal', null),
  withState('fieldsValue', 'setFieldsValue', {}),
  withState('changedFields', 'setChangedFields', []),
  withState('changedPaymentFields', 'setChangedPaymentFields', []),
  // withState('payments', 'setPayments', [{ ...PAYMENT_FIELDS }]),
  withState('addedPaymentsField', 'setAddedPaymentField', []),
  withState('accessesHeader', 'setAccessesHeader',{}),
  withState('executionDate', 'setExecutionDate', ''),
  withState('executionTime', 'setExecutionTime', ''),
  withHandlers({
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    testing: props => () => console.log('testing', props.test),
    calculation: props => () => {
      const currentFieldsValue = { ...props.fieldsValue };
      if (currentFieldsValue.buh_total) {
        let total = (currentFieldsValue.buh_amount.name) ? Number(currentFieldsValue.buh_amount.name) : 0;
        total -= currentFieldsValue.buh_carrier.name ? Number(currentFieldsValue.buh_carrier.name) : 0;
        total -= currentFieldsValue.buh_gasketMaterial.name ? Number(currentFieldsValue.buh_gasketMaterial.name) : 0;
        total -= currentFieldsValue.buh_autoBeforeLoading.name
          ? Number(currentFieldsValue.buh_autoBeforeLoading.name) : 0;
        total -= currentFieldsValue.buh_forMKAD.name ? Number(currentFieldsValue.buh_forMKAD.name) : 0;
        total -= currentFieldsValue.buh_plain.name ? Number(currentFieldsValue.buh_plain.name) : 0;
        total -= currentFieldsValue.buh_overload.name ? Number(currentFieldsValue.buh_overload.name) : 0;
        total -= currentFieldsValue.buh_containerAgent.name ? Number(currentFieldsValue.buh_containerAgent.name) : 0;
        total -= currentFieldsValue.buh_forwarderOnLoading.name
          ? Number(currentFieldsValue.buh_forwarderOnLoading.name) : 0;
        total -= currentFieldsValue.buh_moversOnLoading.name ? Number(currentFieldsValue.buh_moversOnLoading.name) : 0;
        total -= currentFieldsValue.buh_moversAtUnloading.name
          ? Number(currentFieldsValue.buh_moversAtUnloading.name) : 0;
        total -= currentFieldsValue.buh_veterinaryCertificate.name
          ? Number(currentFieldsValue.buh_veterinaryCertificate.name) : 0;
        total -= currentFieldsValue.buh_warming.name ? Number(currentFieldsValue.buh_warming.name) : 0;
        total -= currentFieldsValue.buh_loadFastening.name ? Number(currentFieldsValue.buh_loadFastening.name) : 0;
        total -= currentFieldsValue.buh_additionalServices.name
          ? Number(currentFieldsValue.buh_additionalServices.name) : 0;
        total -= currentFieldsValue.buh_exportAgent.name ? Number(currentFieldsValue.buh_exportAgent.name) : 0;
        total -= currentFieldsValue.buh_simpleToUnload.name ? Number(currentFieldsValue.buh_simpleToUnload.name) : 0;
        total -= currentFieldsValue.buh_storageAtTheDestinationStation.name
          ? Number(currentFieldsValue.buh_storageAtTheDestinationStation.name) : 0;
        total -= currentFieldsValue.buh_seal.name ? Number(currentFieldsValue.buh_seal.name) : 0;
        total -= currentFieldsValue.buh_bills.name ? Number(currentFieldsValue.buh_bills.name) : 0;
        total -= currentFieldsValue.buh_agentToClient.name ? Number(currentFieldsValue.buh_agentToClient.name) : 0;
        total -= currentFieldsValue.buh_managerial.name ? Number(currentFieldsValue.buh_managerial.name) : 0;
        total -= currentFieldsValue.buh_agentSuppliers.name ? Number(currentFieldsValue.buh_agentSuppliers.name) : 0;
        total -= currentFieldsValue.buh_OBN.name ? Number(currentFieldsValue.buh_OBN.name) : 0;
        total.toFixed(2);
        props.setTotal(total);
      }
    },
  }),
  withHandlers({
    saveHandler: props => async (body) => {
      try {
        console.log('body', body);
        const result = {};
        const resultResp = {};
        Object.keys(body).forEach((field) => {
          if (typeof body[field] === 'object') {
            result[field] = body[field]._id;
          } else {
            result[field] = body[field] !== undefined ? body[field] : '';
          }
        })
        Object.keys(result).forEach((element) => {
          if(result[element] !== ''){
            resultResp[element] = result[element];
          }
        })
        const response = await RequestsService.addRequest(resultResp);
        socket.emit('table', 'create');
        // props.history.push('/dashboard/task');
        props.history.goBack();
        props.functionCheckTime('Success', 'Успешно');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    updateHandler: props => async (body) => {
      const updatedFields = {};
      props.changedFields.forEach((field) => {
        if (typeof body[field] === 'object') {
          updatedFields[field] = body[field]._id;
        } else {
          updatedFields[field] = body[field] !== undefined ? body[field] : '';
        }
      });
      // console.log('updatedFields', updatedFields);
      try {
        const requestId = props.location.pathname.split('/').pop();
        await RequestsService.updateRequest(requestId, updatedFields);
        props.setChangedFields([]);
        socket.emit('table', 'update');
        props.functionCheckTime('Success', 'Сохранено');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    selectedFieldValue: props => (field, value) => {
      const currentFieldsValue = props.fieldsValue;
      currentFieldsValue[field] = value;
      props.setFieldsValue(currentFieldsValue);
      props.setAutocompleteValues({});
    },
    changeField: props => async (field, value, type, serviceType) => {
      const currentFieldsValue = { ...props.fieldsValue };
      if(field === 'requestPriceAll'){
        currentFieldsValue[`requestPriceNds`] = (value * 0.1).toFixed(2);
      }
      if (typeof currentFieldsValue[field] === 'object' && currentFieldsValue[field] !== null ) {
        currentFieldsValue[field].name = value;
      } else {
        currentFieldsValue[field] = value;
      }
      const changedFields = [...props.changedFields];
      changedFields.push(field);
      props.setChangedFields(changedFields);
      props.setFieldsValue(currentFieldsValue, () => {
        props.calculation();
      });
      if (type !== 'autocomplete') {
        return;
      }
      let currentService = null;
      let dependencies = [];
      switch (serviceType) {
        case 'payer':
          currentService = PayerService;
          break;
        case 'consignee':
          if (props.fieldsValue.payer.id) {
            const obj = await PayerService.getById(props.fieldValue.payer.id);
            if (obj.relations[0].items) {
              if (value !== '') {
                value = value.toLowerCase();
                obj.relations[0].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[0].items;
              }
            }
          }
          // currentService = ConsigneeService;
          break;
        case 'shipper':
          if (props.fieldsValue.payer.id) {
            const obj = await PayerService.getById(props.fieldsValue.payer.id);
            if (obj.relations[1].items) {
              if (value !== '') {
                value = value.toLowerCase();
                obj.relations[1].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[1].items;
              }
            }
          }
          // currentService = ShipperService;
          break;
        case 'agentAutoLoad':
          currentService = AutoagentService;
          break;
        case 'agentAutoCall':
        case 'ownerContainer':
        case 'company':
        case 'companyMovers':
        case 'companyMoversUnloading':
        case 'companyMoversLoading':
        case 'companyNameForwarder':
        case 'warmingCompany':
        case 'railCarrier':
        case 'forwarderLoading':
        case 'forwarderUnloading':
          currentService = CounterpartyService;
          break;
        case 'addressLoading':
          if (props.fieldsValue.shipper.id) {
            const obj = await ShipperService.getById(props.fieldsValue.shipper.id);
            if (obj.relations[0].items) {
              if (value !== '') {
                value = value.toLowerCase();
                obj.relations[0].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[0].items;
              }
            }
          }
          break;
        case 'addressUnloading':
          if (props.fieldsValue.consignee.id) {
            const obj = await ConsigneeService.getById(props.fieldsValue.consignee.id);
            if (obj.relations[0].items) {
              if (value !== '') {
                value = value.toLowerCase();
                obj.relations[0].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[0].items;
              }
            }
          }
          // currentService = AddressService;
          break;
        case 'stationDeparture':
        case 'stationDestination':
        case 'destinationCity':
          currentService = StationService;
          break;
        case 'driver':
          if (props.fieldsValue.agentAutoLoad.id) {
            const obj = await AutoagentService.getById(props.fieldsValue.agentAutoLoad.id);
            const items = obj.relations[0].items.map(item => ({
              id: item._id,
              ...item,
            }));
            if (items.length !== 0) {
              if (value !== '') {
                value = value.toLowerCase();
                items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = items;
              }
            }
          }
          // currentService = DriverService;
          break;
        case 'car':
          currentService = CarService;
          break;
        case 'trailer':
          if (props.fieldsValue.agentAutoLoad.id) {
            const obj = await AutoagentService.getById(props.fieldsValue.agentAutoLoad.id);
            const items = obj.relations[2].items.map(item => ({
              id: item._id,
              ...item,
            }));
            if (items.length !== 0) {
              if (value !== '') {
                value = value.toLowerCase();
                items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = items;
              }
            }
          }
          // currentService = TrailerService;
          break;
        case 'terminalStaging':
          currentService = TerminalService;
          break;
        case 'loadActual':
        case 'loadDocumented':
          currentService = LoadService;
          break;
        case 'executor':
          currentService = UserService;
          break;
        case 'place':
          currentService = PlacesService;
          break;
        case 'requestProduct':
          currentService = ProductService;
          break;
        default:
          console.log('default service');
          break;
      }
      const result = (currentService)
        ? await currentService.searchItem({ search: value, limit: 3 })
        : dependencies;
      const autocompleteValues = {};
      const executorValues = [];
      if( field === 'executor'){
        Object.keys(result).forEach((element) => {
          if ( result[element].role.name !== 'Администратор') {
            executorValues.push(result[element]);
          }
        })
        autocompleteValues[field] = executorValues;
      } else {
        autocompleteValues[field] = result;
      }
      props.setAutocompleteValues(autocompleteValues);
    },
    clearAutocompleteValues: props => () => {
      if (Object.keys(props.autocompleteValues).length) {
        props.setAutocompleteValues({});
      }
    },
  }),

  lifecycle({
    async componentDidMount() {
      const FIELDS = {
        car: {
          _id: '',
          country: '',
          name: '',
          number: '',
        },
        created: '',
        execution: '',
        creator: {
          _id: '',
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
        },
        description: '',
        executor: {
          _id: '',
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
        },
        id: '',
        number: '',
        odometer: {
          start: '',
          end: '',
        },
        place: {
          _id: '',
          name: '',
          address: '',
        },
        powerOfAttorneyNumber: '',
        requestCountQuantity: '',
        requestCountWeight: '',
        requestPriceAll: '',
        requestPriceNds: '',
        requestProduct: {
          _id: '',
          description: '',
          name: '',
        },
        problem: '',
        __v: '',
        type: '',
        status: '',
        };
      console.log('{ ...FIELDS }', { ...FIELDS });
      const pathname = this.props.location.pathname.split('/');
      if (pathname[3] === 'edit') {
        try {
          const requests = await RequestsService.getRequestById(pathname[4]);
          console.log('requests', requests);
          Object.keys(requests.document).forEach((field) => {
            if(typeof requests.document[field] === 'object' && requests.document[field]) {
              requests.document[field] = { ...requests.document[field], name: requests.document[field].data };
                if(typeof requests.document[field].data === 'object'){
                }
            }
          });
        this.props.setEdit(true);
        this.props.setAccessesHeader(requests.headers);
        this.props.setFieldsValue({
          ...FIELDS,
          ...requests.document
        }, () => {
          this.props.calculation();
        });
        this.props.setExecutionDate(moment(this.props.fieldsValue.execution).format('YYYY-MM-DD'));
        this.props.setExecutionTime(moment(this.props.fieldsValue.execution).format('HH:mm'));
        } catch (error) {
          console.log('Get request error', error);
        }
      } else {
        // Object.keys(FIELDS).forEach((key) => {
        //   if (key === 'state') {
        //     FIELDS[key] = { name: 'Предварительное' };
        //   } else {
        //     FIELDS[key] = {};
        //   }
        // });
        console.log('{...FIELDS_HEADER}', {...FIELDS_HEADER});
        console.log('{ ...FIELDS }', { ...FIELDS });
        this.props.setAccessesHeader({...FIELDS_HEADER});
        this.props.setFieldsValue({ ...FIELDS }, () => {
          this.props.calculation();
        });
      }
      const { requests = null } = this.props.accesses;
      if (requests) {
        this.props.saveUserAccesses(requests);
      }
      // формирование меню

    },
  }),
)(RequestFromView);
