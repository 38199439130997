import styled from 'styled-components';

import { base, screens, colors } from '../../../styles';

export const ContentPage = styled.div`
  width: 100%;
`;

export const FormCardInfo = styled.div`
  position: fixed;
  right: 0;
  // top: 0;
  // bottom: 0;
  // overflow-y: scroll;
  // padding-top: ${base * 11.25}px;
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100%;
  }
`;

export const ContentGlobal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
  @media (min-width: ${screens.screen_xs_max}) {
    margin-top: -12px;
  }
`;

export const MobileTitleBlock = styled.div`
  display: none;
  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const Button = styled.div`
  background-color: ${colors.primary};
  border-radius: 6px;
  cursor: pointer;
  & > p {
    font-size: 12px;
    color: ${colors.white};
    padding: 12px 16px;
  }
`;

export const InputSearchBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const AddSearch = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const Loader = styled.div`
  display: none;
  transform: translateZ(1px);
  margin-left: 20px;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #2E83EC;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  opacity: 0.65;
  @keyframes lds-circle {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }
`;
