/**
 * @flow
 */
import React from 'react';
import moment from 'moment';

import {
  Form,
  TitleContainer,
  Title,
  TitleButton,
  MobileTitleButton,
  HistoryContainer,
  RequestWrapper,
  ContainerRequest,
  Loader,
  PaymentsWrapper,
  ButtonS,
} from './RequestStyles';

import {
  Request,
  History,
  HistoryItem,
  Button,
  Payment,
  PageTitle,
} from '../../../components';

type RequestPropsType = {
  availableFields: Object,
  isLoading: Boolean,
  request: Object,
  historyRequest: Array<any>,
  payments: Array<Object>,
  addRequest: () => void,
  back: () => void,
  getExcel: () => void,
  changeDateHistory: () => void,
  editRequest: () => void,
  deleteRequest: () => void,
};

export default function RequestFormView(props: RequestPropsType) {
  // формирование истории заявки
  const history = [];
  props.historyRequest.forEach((element, index) => {
    console.log('element', element);
    const date = moment(element.created).format('DD-MM-YYYY');
    const time = moment(element.created).format('HH:mm:ss');
    const lastItem = index === props.historyRequest.length - 1;
    let previousValue = '';
    let nextValue = '';
    if (!element.isObject) {
      previousValue = element.oldValue;
      nextValue = element.newValue;
    } else {
      if (!element.oldValue || !element.newValue) return;
      const preObj = JSON.parse(element.oldValue);
      const nextObj = JSON.parse(element.newValue);
      Object.keys(preObj).forEach((field) => {
        if (field !== '_id') {
          if (preObj[field]) {
            if (!Array.isArray(preObj[field])) {
              previousValue += preObj[field];
            }
          }
        }
      });
      Object.keys(nextObj).forEach((field) => {
        if (field !== '_id') {
          if (nextObj[field]) {
            if (!Array.isArray(nextObj[field])) {
              nextValue += nextObj[field];
            }
          }
        }
      });
      if (previousValue === '') {
        previousValue = null;
      }
    }
    console.log('date', date);
    console.log('time', time);
    history.push(
      <HistoryItem
        key={element._id}
        date={date}
        time={time}
        // userName={`${element.userID.firstName} ${element.userID.lastName}`}
        inputName={element.field}
        previousValue={previousValue}
        nextValue={nextValue}
        lastItem={lastItem}
      />,
    );
  });

  if (props.isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: '50%',
          left: '50%',
        }}
      >
        <Loader className='lds-circle' />
      </div>
    );
  }

  return (
    <Form>
      <TitleContainer>
        <Title>Задачи</Title>
        <TitleButton>
          <ButtonS
            onClick={() => props.addRequest()}
          >
            <p>Добавить</p>
          </ButtonS>
        </TitleButton>
        <TitleButton white>
          {/* <Button mode="white" clicked={() => { props.back(); }}>Вернуться</Button> */}
          <ButtonS
            onClick={() => { props.back()}}
            white
          >
            <p>Вернуться</p>
          </ButtonS>
        </TitleButton>
      </TitleContainer>
      <MobileTitleButton>
        <ButtonS
            onClick={() => props.back()}
            white
          >
            <p>Вернуться</p>
          </ButtonS>
      </MobileTitleButton>
      <ContainerRequest>
        <RequestWrapper>
          {
            props.payments.payment
            && props.payments.payment.length > 0
            && (
              <>
                <h3>Платежи</h3>
                <PaymentsWrapper>
                  {props.payments.payment.map(payment => (
                    <Payment
                      key={payment.id}
                      number={payment.paymentNumber}
                      date={payment.date}
                      amount={+payment.amount}
                    />
                  ))}
                </PaymentsWrapper>
              </>
            )
          }
          {
            props.payments.containerAgentPayment
            && props.payments.containerAgentPayment.length > 0
            && (
              <>
                <h3>Контейнерный агент</h3>
                <PaymentsWrapper>
                  {props.payments.containerAgentPayment.map(payment => (
                    <Payment
                      number={payment.paymentNumber}
                      date={payment.date}
                      amount={+payment.amount}
                    />
                  ))}
                </PaymentsWrapper>
              </>
            )
          }
          <Request
            columnTitles={props.availableFields}
            data={props.request}
            export={props.getExcel}
            edit={props.editRequest}
            delete={props.deleteRequest}
          />
        </RequestWrapper>
        <HistoryContainer>
          <History
            requestNumber={props.request.number}
            changeDateHistory={props.changeDateHistory}
          >
            {history}
          </History>
        </HistoryContainer>
      </ContainerRequest>
    </Form>
  );
}
