import styled from 'styled-components';

import { base, colors } from '../../../styles';

export const HeaderColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: 12px;
`;

export const TextHeaderColumn = styled.div`
  outline: none;
  cursor: pointer;
  user-select: none;
`;

export const SortingHeaderColumn = styled.div`
  position: absolute;
  right: -21px;
  color: ${colors.primary};
  margin-left: ${base * 0.4}px;
`;
