import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const InputCheckBox = styled.div`
  min-width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: ${base - 8}px;
  margin-right: 12px;
  &:hover{
    border: 1px solid ${colors.primary};
  }
  ${props => props.status && css`
    background-image: url(${require('../../assets/icons/check-box.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    border: 1px solid ${colors.primary};
  `}
`;

export const LabelCheckBox = styled.div``;

export const CheckBox = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
  ${props => props.disabled && css`
    pointer-events: none;
  `}
`;
