/**
 * @flow
 */
import React from 'react';

import {
  SideBarItemWrapper,
  Image,
  Name,
  IconSection,
  TextSection,
} from './SideBarItemStyled';

type SideBarItemViewPropsType = {
  active?: boolean,
  clicked?: (any) => void,
  icon: string,
  name?: string,
};

export default function SideBarItemView(props: SideBarItemViewPropsType) {
  return (
    <SideBarItemWrapper
      onClick={props.clicked}
      active={props.active}
      name={props.name}
    >
      {
        props.icon && (
          <IconSection>
            <Image src={props.icon} alt="icon" />
          </IconSection>
        )
      }
      <TextSection>
        <Name>{props.name}</Name>
      </TextSection>
    </SideBarItemWrapper>
  );
}

SideBarItemView.defaultProps = {
  active: false,
  clicked: null,
  name: '',
};
