/**
 * @flow
 */
import React from 'react';

import {
  Button,
} from './ButtonStyled';

type ButtonViewPropsType = {
  clicked: (any) => void,
  children: any,
  circle?: boolean,
  rectangle?: boolean,
  cross?: boolean,
  secondary?: boolean,
  crossHover?: boolean,
  plus?: boolean,
  mode?: string,
  maxWidth?: boolean,
  expand?: Boolean,
};

export default function ButtonView(props: ButtonViewPropsType) {
  return (
    <Button
      type="button"
      onClick={props.clicked}
      circle={props.circle}
      rectangle={props.rectangle}
      cross={props.cross}
      secondary={props.secondary}
      crossHover={props.crossHover}
      plus={props.plus}
      mode={props.mode}
      maxWidth={props.maxWidth}
      iconExpand={props.iconExpand}
    >
      {
        props.plus && (
          <img
            style={{ width: '12px', transform: 'rotate(45deg)' }}
            src={require('../../assets/icons/close-white.svg')}
            alt="plus"
            transform="rotate(45deg)"
          />
        )
      }
      {props.children}
    </Button>
  );
}

ButtonView.defaultProps = {
  circle: false,
  rectangle: false,
  cross: false,
  secondary: false,
  crossHover: false,
  plus: false,
  mode: '',
  maxWidth: false,
  iconExpand: false,
};
