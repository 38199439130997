import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const SelectDropDownWraper = styled.div`
    font-size: 12px;
    cursor: pointer;
    height: ${base * 3.6}px;
    box-sizing: border-box;
    position: relative;
`;

export const SelectDropDownHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border: 1px solid ${colors.primary_white_gray};
    padding: 0 12px;
    margin-bottom: ${base - 4}px;
    border-radius: ${base - 4}px;
`;

export const SelectName = styled.div`
    color: ${colors.black};
    ${props => props.isPlaceholder && css`
        color: #8e8e8e;
    `}
`;

export const SelectArrowUp = styled.div`
    background-image: url(${require('../../assets/icons/drop-down.svg')});
    width: ${base * 0.8}px;
    height: ${base * 2.25}px;
    background-repeat: no-repeat;
    background-position: center;
`;

export const SelectArrowDown = styled.div`
    background-image: url(${require('../../assets/icons/drop-down-up.svg')});
    width: ${base * 0.8}px;
    height: ${base * 2.25}px;
    background-repeat: no-repeat;
    background-position: center;
`;

export const SelectDropDownOptions = styled.div`
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    margin-top: 0;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 12px 20px #efefef;
    padding: 6px 12px;
    z-index: 100;
    @media (max-width: ${screens.screen_xs_max}) {
      width: 100%;
    }
`;

export const SelectDropDownMask = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
`;
