import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';

import { connect } from 'react-redux';

// view
import RequestView from './RequestView';

// services
import {
  RequestsService,
  RequestsHistoryService,
  PaymentsService,
} from '../../../services';

// socket service
import {
  socket,
} from '../../../services/socket';

// actions
import {
  setSecondaryBarItems,
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
} from '../../../store/actions/dashboard';

export default compose(
  connect(
    state => ({
      activeSidebarItem: state.dashboard.activeItem,
    }),
    dispatch => ({
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
    }),
  ),
  withState('request', 'setRequest', {}),
  withState('availableFields', 'setAvailableFields', {}),
  withState('historyRequest', 'setHistoryRequest', []),
  withState('isLoading', 'setIsLoading', false),
  withState('payments', 'setPayments', {}),
  withHandlers({
    addRequest: props => () => {
      console.log('click');
      props.history.push('/dashboard/task/create');
    },
    back: props => () => {
      // props.history.push('/dashboard/task/archive');
      props.history.goBack();
    },
    getExcel: props => async () => {
      try {
        const response = await RequestsService.getRequestsExcel({ id: `["${props.request.id}"]` });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log(err);
      }
    },
    changeDateHistory: props => async (dateRange) => {
      // get request history from API
      try {
        const history = await RequestsHistoryService.getAllRequestsHistory({
          task: props.request.id,
          daterange: dateRange,
        });
        props.setHistoryRequest(history);
      } catch (err) {
        console.log(err);
      }
    },
    editRequest: props => () => {
      props.history.push(`/dashboard/task/edit/${props.request.id}`);
    },
    deleteRequest: props => async () => {
      try {
        // console.log('props.request._id', props.request);
        await RequestsService.deleteRequest(props.request.id);
        socket.emit('table', 'delete');
        // props.history.push('/dashboard/task/archive');
        props.history.goBack();
      } catch (err) {
        console.log(err);
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const pathname = this.props.location.pathname.split('/');
      this.props.setIsLoading(true);
      // get request from API
      try {
        const requests = await RequestsService.getRequestById(pathname[4]);
        // console.log('requests', requests);
        const result = [];
        Object.keys(requests.doc).forEach((key) => {
          let counter = {};
          Object.keys(requests.doc[key]).forEach((item) => {
            if( typeof requests.doc[key][item] === 'object'){
              if (requests.doc[key][item]){
                Object.keys(requests.doc[key][item]).forEach((keyItem) => {
                  if( typeof requests.doc[key][item][keyItem] === 'object'){
                    if(requests.doc[key][item][keyItem]){
                      Object.keys(requests.doc[key][item][keyItem]).forEach((element) => {
                        if (typeof requests.doc[key][item][keyItem][element] === 'object'){
                        } else {
                          counter[element] = requests.doc[key][item][keyItem][element];
                        }
                      });
                    }
                  } else {
                    counter[keyItem] = requests.doc[key][item][keyItem];
                  }
                });
              }
            } else {
              counter[item] = requests.doc[key][item];
            }
          })
          result.push(counter);
        });
        // console.log('result', result[0]);
        // console.log('requests.headers', requests.headers);
        this.props.setRequest(result[0]);
        this.props.setAvailableFields(requests.headers);
      } catch (err) {
        console.log(err);
      }
      // get request history from API
      try {
        const history = await RequestsHistoryService.getAllRequestsHistory({ task: pathname[4] });
        this.props.setHistoryRequest(history);
      } catch (err) {
        console.log(err);
      }

      // geе request payments
      // try {
      //   const paymentsResponse = await PaymentsService.getById(pathname[4]);

      //   const payments = {};

      //   paymentsResponse.forEach((payment) => {
      //     if (!(payment.type in payments)) {
      //       payments[payment.type] = [];
      //     }
      //     payments[payment.type].push(payment);
      //   });

      //   console.log('payments', payments);
      //   this.props.setPayments(payments);
      // } catch (error) {
      //   console.log('Request payments error', error);
      // }

      this.props.setIsLoading(false);
      // формирование меню
      this.props.setSecondaryBarItems([]);
    },
  }),
)(RequestView);
