import styled, { css } from 'styled-components';
import { base, colors, screens } from '../../../styles';

export const Form = styled.div`
`;

export const ControlContainer = styled.div`
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
  @media (min-width: ${screens.screen_xs_max}) {
    margin-top: -12px;
  }
`;

export const Heading = styled.h1`
  align-items: center;
  font-size: 46px;
  color: black;
  font-weight: 300;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const AddSearch = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const Buttons = styled.div`
  background-color: ${colors.primary};
  border-radius: 6px;
  cursor: pointer;
  & > p {
    font-size: 12px;
    color: ${colors.white};
    padding: 12px 16px;
  }
`;

export const ButtonAdd = styled.div`
  background-color: ${colors.primary};
  border-radius: 6px;
  cursor: pointer;
  & > p {
    font-size: 12px;
    color: ${colors.white};
    padding: 12px 16px;
  }
`;

export const InputSearchBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const MobileControlContainer = styled.div`
  display: none;

  @media (max-width: ${screens.screen_md_mid}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  margin-bottom: 16px;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-top: -12px;
    display: none;
  }
`;

export const TitleButton = styled.div`
  margin-right: 16px;
  position: relative;
  ${props => props.filtersActivePoint && css`
    &:before {
      content: '•';
      position: absolute;
      color: #da350a;
      top: -21px;
      left: 9px;
      font-size: 20px;
    }
  `}
`;

export const ExpandButtonWrapper = styled.div`
  padding-bottom: 12px;
`;

export const DateFilterActive = styled.div`
  display: block;
  position: absolute;
  top: -38px;
  right: 483px;
  color: ${colors.primary};

  @media (max-width: ${screens.screen_md_mid}) {
    display: none;
  }
`;

export const CalendarContainer = styled.div`
  display: block;
  position: absolute;
  z-index: 15;
  top: ${base * 14}px;
  right: 12px;

  @media (max-width: ${screens.screen_md_mid}) {
    top: 132px;
    left: 12px;
  }
`;

export const CalendarButton = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  position: relative;

  ${props => props.filterActive && css`
    &:before {
      content: '•';
      position: absolute;
      color: ${colors.primary};
      top: -21px;
      left: 9px;
      font-size: 20px;
    }
  `}

`;

export const InputDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-right: 16px;

  input, div {
    width: ${base * 14}px;
  }
`;

// export const MobileInputDate = styled.div`
//   width: 100%;
//   position: relative;

//   input, div {
//     height: 36px;
//   }
// `;

export const InputSearch = styled.div`
  width: 100%

  @media (max-width: ${screens.screen_md_mid}) {
    display: none;
  }
`;

export const MobileInputSearch = styled.div`
  width: 100%;
`;

export const FiltersContainer = styled.div`
  background: ${colors.white};
  margin: 0 -46px 0 -51px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 0 0 82px;

  @media (max-width: ${screens.screen_xs_max}) {
    padding: 12px 0 0 12px;
    margin: 0 -100px 0 0;
  }
`;

export const TableContainer = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
  padding-bottom: 30px;
  font-size: 16px;

  @media (max-width: ${screens.screen_xs_max}) {
    width: 100%;
  }
`;

export const FiltersValueContainer = styled.div`
  width: 150px;
  margin: 0 12px 12px 0;
`;

export const FiltersValueTitle = styled.div`
  margin: 0 0 2px 0;
  color: ${colors.light_gray};
  font-size: 12px;
`;

export const FiltersValue = styled.div`
  position: relative;

  input {
    height: 22px;
    font-size: 11px;
  }
`;

export const FiltersValueDelete = styled.img`
  width: 12px;
  position: absolute;
  top: 6px;
  right: 6px;
  outline: none;
  cursor: pointer;
`;

export const DateFiltersValueDelete = styled.img`
  width: 12px;
  height: 12px;
  position: relative;
  outline: none;
  cursor: pointer;

  @media (max-width: ${screens.screen_md_mid}) {
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 22px;
  font-size: 11px;
  outline: none;
  padding: 0 12px;
  border: none;
  border-radius: 6px;
  background-color: ${colors.white_smoke};

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const ValueOptions = styled.div`
  position: absolute;
  z-index: 100;
  width: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 200px;
  margin-top: 6px;
  background-color: ${colors.white};
  border: 1px solid ${colors.history_gray};
  border-radius: 6px;
`;

export const OptionValues = styled.div`
  padding: 3px 6px 3px 6px;
  cursor: pointer;

  &:hover {
    color: ${colors.primary};
  }

  &:active {
    color: ${colors.primary};
  }
`;

export const Loaders = styled.div`
    display: none;
    transform: translateZ(1px);
    margin-left: 20px;
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #2E83EC;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    opacity: 0.65;
    @keyframes lds-circle {
    0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
    transform: rotateY(0deg);
    }
    50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
    transform: rotateY(3600deg);
    }
    }
`;

export const IconButtonWrapper = styled.div`
  font-size: 18px;
  display: flex;
  // justify-content: space-between;
`;
