import moment from 'moment';
import {
  apiPost,
  apiGet,
  apiPut,
  apiDelete,
} from '../ApiService';

export default {
  getApiName() {
    return 'tasks';
  },

  getAllRequests(page = 1, records = 50, params, status='') {
    return apiGet(`/tasks/?status=${status}&page=${page}&records=${records}`, { params })
      .then((response) => {
        const requests = [];
        response.data.docs.forEach((element) => {
          const request = {};
            Object.keys(element).forEach((field) => {
              if (field === '_id') {
                request.id = element[field];
              } else if (field === 'created') {
                request[field] = moment(element[field]).format('DD-MM-YYYY HH:mm:ss');
              } else if (field === 'execution') {
                request[field] = moment(element[field]).format('DD-MM-YYYY HH:mm:ss');
              } else if (element[field] === null) {
                request[field] = null;
              } else if (typeof element[field] === 'object') {
                let data = {};
                Object.keys(element[field]).forEach((key) => {
                  if(element[field][key]){
                    if( typeof element[field][key] === 'object'){
                      Object.keys(element[field][key]).forEach((elementKey) => {
                        if(element[field][key][elementKey]){
                          data[`${field}.${key}.${elementKey}`] = element[field][key][elementKey];
                        }
                      });
                    } else {
                      data[`${field}.${key}`] = element[field][key];
                    }
                  }
                });
                request[field] = data;
              } else {
                request[field] = element[field];
              }
            });
            requests.push(request);
        });
        return requests;
      })
      .catch(error => {throw new Error(error.response.data.error.message);});
  },
  getRequestById(id) {
    return apiGet(`/tasks/${id}`)
      .then((response) => {
        const res = {
          doc: [],
          headers: response.data.headers,
          response: response.data.doc,
          document: {},
        };
        const request = {};
        Object.keys(response.data.doc).forEach((field) => {
          if(field === '_id'){
            request.id = response.data.doc[field];
          } else if(field === 'created') {
            request[field] = moment(response.data.doc[field]).format('DD-MM-YYYY HH:mm:ss');
          } else if (field === 'execution') {
            request[field] = moment(response.data.doc[field]).format('DD-MM-YYYY HH:mm:ss');
          } else if (response.data.doc[field] === null) {
            request[field] = null;
          } else if (typeof response.data.doc[field] === 'object') {
            let data = {};
            Object.keys(response.data.doc[field]).forEach((key) => {
              if(response.data.doc[field][key]){
                if(typeof response.data.doc[field][key] === 'object'){
                  Object.keys(response.data.doc[field][key]).forEach((elementKey) => {
                    if(response.data.doc[field][key][elementKey]){
                      data[`${field}.${key}.${elementKey}`] = response.data.doc[field][key][elementKey];
                    }
                  });
                } else {
                  data[`${field}.${key}`] = response.data.doc[field][key];
                }
              }
            });
            request[field] = data;
          } else {
            request[field] = response.data.doc[field];
          }
        });
        Object.keys(response.data.doc).forEach((field) => {
          if (field === 'dateCreate') {
            const date = moment(response.data.doc[field]).format('DD-MM-YYYY HH:mm:ss');
            res.document[field] = date;
          } else if (response.data.doc[field] === null) {
            res.document[field] = null;
          } else if (typeof response.data.doc[field] === 'object') {
            let data = '';
            Object.keys(response.data.doc[field]).forEach((key) => {
              if (key !== '_id') {
                if (response.data.doc[field][key]) {
                  if (!Array.isArray(response.data.doc[field][key])) {
                    data += response.data.doc[field][key];
                    data += ' ';
                  }
                }
              }
            });
            res.document[field] = { id: response.data.doc[field]._id, data };
          } else {
            res.document[field] = response.data.doc[field];
          }
        });
        res.doc.push(request);
        return res;
      })
      .catch(error => {throw new Error(error.response.data.error.message);});
  },
  getRequestByIdForActiveTasks(id) {
    return apiGet(`/tasks/${id}`)
      .then(response => {
        const data = response.data.doc;
        const task = {};
            Object.keys(data).forEach((field) => {
              if (field === '_id') {
                task.id = data[field];
              } else if (field === 'created') {
                task[field] = moment(data[field]).format('DD-MM-YYYY HH:mm:ss');
              } else if (data[field] === null) {
                task[field] = null;
              } else if (typeof data[field] === 'object') {
                let obj = {};
                Object.keys(data[field]).forEach((key) => {
                  if (data[field][key]) {
                    if (typeof data[field][key] === 'object') {
                      Object.keys(data[field][key]).forEach((elementKey) => {
                        if (data[field][key][elementKey]) {
                          obj[`${field}.${key}.${elementKey}`] = data[field][key][elementKey];
                        }
                      });
                    } else {
                      obj[`${field}.${key}`] = data[field][key];
                    }
                  }
                });
                task[field] = obj;
              } else {
                task[field] = data[field];
              }
            });
        return task;
      })
      .catch(error => {throw new Error(error.response.data.error.message);});
  },
  getRequestsExcel(params) {
    return apiGet('/tasks/excel', { params })
      .then(response => response)
      .catch(error => {throw new Error(error.response.data.error.message);});
  },
  getAvailableFields() {
    return apiGet('/tasks/decoding')
      .then(responce => responce.data)
      .catch(error => {throw new Error(error.response.data.error.message);});
  },
  addRequest(data) {
    return apiPost('/tasks', data)
      .then(response => ({ result: response.data, status: response.status }))
      .catch(error => {throw new Error(error.response.data.error.message);});
  },
  updateRequest(id, fields) {
    return apiPut(`/tasks/${id}`, fields)
      .then(response => ({ result: response.data, status: response.status }))
      .catch(error => {throw new Error(error.response.data.error.message);});
  },
  deleteRequest(id) {
    return apiDelete(`/tasks/${id}`)
      .then(response => ({ result: response.data, status: response.status }))
      .catch(error => {throw new Error(error.response.data.error.message);});
  },
};
