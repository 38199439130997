export const base = 12;

export const colors = {
  black: '#000',
  white: '#fff',
  white_d: '#fafafa',
  light_gray: '#b3b3b3',
  primary: '#2e83ec',
  primary_hover: '#1667cc',
  primary_gray: '#fbfbfb',
  history_gray: '#aeaeae',
  white_smoke: '#f7f7f7',
  primary_white_gray: '#dedede',
};

export const screens = {
  screen_lg_min: '1300px',
  screen_md_max: '1299px',
  screen_md_mid: '1200px',
  screen_md_min: '1000px',
  screen_sm_max: '999px',
  screen_sm_mid: '860px',
  screen_sm_min: '760px',
  screen_xs_max: '759px',
  screen_xs_min: '640px',
  screen_2xs_max: '639px',
  screen_2xs_min: '480px',
  screen_3xs_max: '479px',
  screen_3xs_min: '360px',
  screen_4xs_max: '359px',
  screen_4xs_min: '0px',
};
