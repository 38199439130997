// Backend url
//* /  deploy server
export const BASE_URL = 'https://agroslide-api.tapston.com/';
// */

/* /  dev server
export const BASE_URL = 'https://agroslide-backend-dev.herokuapp.com/';
// */

/* /  local server
export const BASE_URL = 'http://localhost:8081';
// */

// default image
export const DEFAUT_AVATAR = require('../assets/images/default_avatar.png');

// Request input - BUH_TOTAL
export const BUH_TOTAL_INPUT = 'buh_total';

// Requests fields
export const REQUEST_FIELDS = [
  {
    title: 'Отдел обслуживания клиентов',
    name: 'customerservice',
    items: [
      {
        title: 'Компания',
        name: 'company',
        value: false,
      },
      {
        title: 'Стоп-лист',
        name: 'stopSheet',
        value: false,
      },
      {
        title: 'Компания принадлежит "Амикс"',
        name: 'companyAmixgroup',
        value: false,
      },
      {
        title: 'Дата загрузки',
        name: 'dateLoad',
        value: false,
      },
      {
        title: 'Время загрузки',
        name: 'timeLoad',
        value: false,
      },
      {
        title: 'Тип отправки',
        name: 'typeSend',
        value: false,
      },
      {
        title: 'Вид контейнера',
        name: 'typeContainer',
        value: false,
      },
      {
        title: 'Вид доставки',
        name: 'typeElivery',
        value: false,
      },
      {
        title: 'Плательщик',
        name: 'payer',
        value: false,
      },
      {
        title: 'Грузоотправитель',
        name: 'shipper',
        value: false,
      },
      {
        title: 'Грузополучатель',
        name: 'consignee',
        value: false,
      },
      {
        title: 'Адрес погрузки',
        name: 'addressLoading',
        value: false,
      },
      {
        title: 'Адрес разгрузки',
        name: 'addressUnloading',
        value: false,
      },
      {
        title: 'Город назначения',
        name: 'destinationCity',
        value: false,
      },
      {
        title: 'Номер заявки клиента',
        name: 'customerApplicationNumber',
        value: false,
      },
      {
        title: 'Груз фактический',
        name: 'loadActual',
        value: false,
      },
      {
        title: 'Вес',
        name: 'weight',
        value: false,
      },
      {
        title: 'Кол-во мест',
        name: 'numberSeats',
        value: false,
      },
      {
        title: 'Плановая дата выдачи',
        name: 'plannedIssueDate',
        value: false,
      },
      {
        title: 'Плановое время выдачи',
        name: 'scheduledIssueTime',
        value: false,
      },
      {
        title: 'OD',
        name: 'od',
        value: false,
      },
      {
        title: 'Shipment',
        name: 'shipment',
        value: false,
      },
      {
        title: 'Дата прибытия',
        name: 'dateArrival',
        value: false,
      },
      {
        title: 'Утепление',
        name: 'warming',
        value: false,
      },
      {
        title: 'Ветеринарное свидетельство',
        name: 'veterinaryCertificate',
        value: false,
      },
      {
        title: 'Крепление груза',
        name: 'loadFastening',
        value: false,
      },
      {
        title: 'Документы от агента',
        name: 'agentDocuments',
        value: false,
      },
      {
        title: 'Экспедитор погрузка',
        name: 'forwarderLoading',
        value: false,
      },
      {
        title: 'Экспедитор выгрузка',
        name: 'forwarderUnloading',
        value: false,
      },
      {
        title: 'Грузчики на погрузке',
        name: 'moversOnLoading',
        value: false,
      },
      {
        title: 'Грузчики на выгрузке',
        name: 'moversAtUnloading',
        value: false,
      },
      {
        title: 'Отслеживание',
        name: 'tracking',
        value: false,
      },
      {
        title: 'Дополнительные услуги',
        name: 'additionalServices',
        value: false,
      },
      {
        title: 'Сумма',
        name: 'buh_amount',
        value: false,
      },
      {
        title: 'Менеджер',
        name: 'manager',
        value: false,
      },
    ],
  },
  {
    title: 'Отдел отправки',
    name: 'shipmentdepartment',
    items: [
      {
        title: 'Перевозчик по ЖД',
        name: 'railCarrier',
        value: false,
      },
      {
        title: 'Собственник контейнера',
        name: 'ownerContainer',
        value: false,
      },
      {
        title: 'Терминал постановки',
        name: 'terminalStaging',
        value: false,
      },
      {
        title: 'Станция отправления',
        name: 'stationDeparture',
        value: false,
      },
      {
        title: 'Станция назначения',
        name: 'stationDestination',
        value: false,
      },
      {
        title: 'Груз документально',
        name: 'loadDocumented',
        value: false,
      },
      {
        title: 'Инструкция по сдаче контейнера',
        name: 'instructionsDelivery',
        value: false,
      },
      {
        title: 'Релизы',
        name: 'releases',
        value: false,
      },
      {
        title: 'Охрана',
        name: 'security',
        value: false,
      },
      {
        title: 'Компания утепления',
        name: 'warmingCompany',
        value: false,
      },
      {
        title: 'Компания/имя экспедитор',
        name: 'companyNameForwarder',
        value: false,
      },
      {
        title: 'Компания грузчики на погрузке',
        name: 'companyMoversLoading',
        value: false,
      },
    ],
  },
  {
    title: 'Автоотдел',
    name: 'autodepartment',
    items: [
      {
        title: 'Номер контейнера',
        name: 'numberContainer',
        value: false,
      },
      {
        title: 'Номер пломбы',
        name: 'numberSeal',
        value: false,
      },
      {
        title: 'Агент авто на загрузке',
        name: 'agentAutoLoad',
        value: false,
      },
      {
        title: 'Водитель',
        name: 'driver',
        value: false,
      },
      {
        title: 'Машина',
        name: 'car',
        value: false,
      },
      {
        title: 'Прицеп',
        name: 'trailer',
        value: false,
      },
    ],
  },
  {
    title: 'Отдел вывоз в регионы/документы',
    name: 'documentdepartment',
    items: [
      {
        title: 'Агент по вывозу',
        name: 'agentAutoCall',
        value: false,
      },
      {
        title: 'Компания грузчики на выгрузке',
        name: 'companyMoversUnloading',
        value: false,
      },
      {
        title: 'Дата выдачи',
        name: 'dateIssue',
        value: false,
      },
      {
        title: 'Время выдачи(фактическое)',
        name: 'timeIssue',
        value: false,
      },
      {
        title: 'Возврат документов с выгрузки',
        name: 'returnOfDocumentsFromUnloading',
        value: false,
      },
      {
        title: 'Акт',
        name: 'act',
        value: false,
      },
      {
        title: 'ЖД накладные',
        name: 'railwayInvoices',
        value: false,
      },
      {
        title: 'Погрузка',
        name: 'loading',
        value: false,
      },
      {
        title: 'ТТН погрузка',
        name: 'loadingTN',
        value: false,
      },
      {
        title: 'ТТН выгрузка',
        name: 'unloadingTN',
        value: false,
      },
      {
        title: 'Статус документов',
        name: 'documentStatus',
        value: false,
      },
    ],
  },
  {
    title: 'Финансовый отдел',
    name: 'financialdepartment',
    items: [
      {
        title: 'Перевозчик',
        name: 'buh_carrier',
        value: false,
      },
      {
        title: 'Авто до погрузки',
        name: 'buh_autoBeforeLoading',
        value: false,
      },
      {
        title: 'За МКАД',
        name: 'buh_forMKAD',
        value: false,
      },
      {
        title: 'Простой',
        name: 'buh_plain',
        value: false,
      },
      {
        title: 'Перегруз',
        name: 'buh_overload',
        value: false,
      },
      {
        title: 'Контейнерный агент',
        name: 'buh_containerAgent',
        value: false,
      },
      {
        title: 'СверхНорма',
        name: 'buh_aboveTheNorm',
        value: false,
      },
      {
        title: 'Агент по вызову',
        name: 'buh_exportAgent',
        value: false,
      },
      {
        title: 'Простой на выгрузке',
        name: 'buh_simpleToUnload',
        value: false,
      },
      {
        title: 'Хранение на станции назначения',
        name: 'buh_storageAtTheDestinationStation',
        value: false,
      },
      {
        title: 'Грузчики на выгрузке',
        name: 'buh_moversAtUnloading',
        value: false,
      },
      {
        title: 'Экспедитор на погрузке',
        name: 'buh_forwarderOnLoading',
        value: false,
      },
      {
        title: 'Грузчики на погрузке',
        name: 'buh_moversOnLoading',
        value: false,
      },
      {
        title: 'Ветеринарное свидетельств',
        name: 'buh_veterinaryCertificate',
        value: false,
      },
      {
        title: 'Крепление груза',
        name: 'buh_loadFastening',
        value: false,
      },
      {
        title: 'Утепление',
        name: 'buh_warming',
        value: false,
      },
      {
        title: 'Прокладочный материал',
        name: 'buh_gasketMaterial',
        value: false,
      },
      {
        title: 'Дополнительные услуги',
        name: 'buh_additionalServices',
        value: false,
      },
      {
        title: 'Пломба',
        name: 'buh_seal',
        value: false,
      },
      {
        title: 'Платёжки',
        name: 'buh_bills',
        value: false,
      },
      {
        title: 'Агентские клиенту',
        name: 'buh_agentToClient',
        value: false,
      },
      {
        title: 'Менеджерские',
        name: 'buh_managerial',
        value: false,
      },
      {
        title: 'Агентские поставщиков',
        name: 'buh_agentSuppliers',
        value: false,
      },
      {
        title: 'ОБН',
        name: 'buh_OBN',
        value: false,
      },
    ],
  },
  {
    title: 'Номера счетов',
    name: 'billnumbers',
    items: [
      {
        title: 'Номер счёта клиента',
        name: 'customerAccountNumber',
        value: false,
      },
      {
        title: 'Дата счёта клиента',
        name: 'customerAccountDate',
        value: false,
      },
      {
        title: 'Счёт перевозчика',
        name: 'carrierAccount',
        value: false,
      },
      {
        title: 'Счёт автоуслуги',
        name: 'accountsAutoServices',
        value: false,
      },
      {
        title: 'Счёт контейнерного агента',
        name: 'containerAgentAccount',
        value: false,
      },
      {
        title: 'Счёт агента по вывозу',
        name: 'exportAgentAccount',
        value: false,
      },
      {
        title: 'Счёт грузчиков на погрузке',
        name: 'moversAccountOnLoading',
        value: false,
      },
      {
        title: 'Счёт грузчиков на выгрузке',
        name: 'moversAccountOnUnloading',
        value: false,
      },
      {
        title: 'Счёт крепление груза',
        name: 'accountSecuringCargo',
        value: false,
      },
      {
        title: 'Счёт утепление груза',
        name: 'billCargoWeatherization',
        value: false,
      },
      {
        title: 'Итого',
        name: 'buh_total',
        value: false,
      },
      {
        title: 'Оплачено',
        name: 'buh_pay',
        value: false,
      },
      {
        title: 'Оплата контейнерного агента',
        name: 'buh_containerAgentPayment',
        value: false,
      },
    ],
  },
];
// Roles right names
export const ROLES_NAME = {
  admin: 'admin',
  accountant: 'accountant',
  chiefAccountant: 'chiefAccountant',
  document: 'document',
  logist: 'logist',
  manager: 'manager',
  transaction: 'transaction',
  salesman: 'salesman',
};
