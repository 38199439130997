import {
  apiGet,
} from '../ApiService';

export default {
  getApiName() {
    return 'requestsHistory';
  },

  getAllRequestsHistory(params) {
    return apiGet('/tasks/history', { params })
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        ...item,
      })))
      .catch(error => console.log('Get all requests history error', error));
  },
};
