/**
 * @flow
 */

import React from 'react';

import {
  DropdownWraper,
  UserName,
  UserAvatar,
  DropdownHeader,
  DropdownOptions,
} from './DropdownStyled';

type DropdownPropsType = {
  avatar: string,
  name: string,
  children: any,
  toggledOn: (any) => void,
  toggle: string,
  convertToArray: (any) => Array<any>,
};

export default function Dropdown(props: DropdownPropsType) {
  let { children } = props;
  const isArray = Array.isArray(children);

  if (!isArray) {
    children = props.convertToArray();
  }

  return (
    <DropdownWraper>
      <DropdownHeader onClick={props.toggle}>
        <UserName>{props.name}</UserName>
        {/* <UserAvatar avatar={props.avatar} /> */}
      </DropdownHeader>
      {props.toggledOn && <DropdownOptions onClick={props.toggle}>{ children }</DropdownOptions>}
    </DropdownWraper>
  );
}
