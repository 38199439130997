/**
 * @flow
 */
import React from 'react';

import {
  RequestForm,
} from '../../../components';

type RequestFromPropsType = {
  accesses: Object,
  edit: boolean,
  autocompleteValues: Object,
  fieldsValue: Object,
  total: Number,
  payments: Array<Object>,
  addedPaymentsField: Array<any>,
  changeField: (string, string, string) => void,
  selectedFieldValue: (string, string) => void,
  saveHandler: (Object, string) => void,
  updateHandler: (Object, string) => void,
  clearAutocompleteValues: () => void,
  changePaymentFields: (string, string, string) => void,
  setPayments: (Array<Object>) => void,
  executionDate: string,
  executionTime: string,
  setExecutionDate: (any) => void,
  setExecutionTime: (any) => void,
};

export default function RequestFormView(props: RequestFromPropsType) {
  return (
    <div>
      <RequestForm
        edit={props.edit}
        accesses={props.accesses}
        autocompleteValues={props.autocompleteValues}
        changeField={props.changeField}
        fieldsValue={props.fieldsValue}
        setExecutionDate={props.setExecutionDate}
        setExecutionTime={props.setExecutionTime}
        executionDate={props.executionDate}
        executionTime={props.executionTime}
        accessesHeader={props.accessesHeader}
        total={props.total}
        selectedFieldValue={props.selectedFieldValue}
        saveHandler={(body, bodyPayment) => props.saveHandler(body, bodyPayment)}
        updateHandler={(body, bodyPayment) => props.updateHandler(body, bodyPayment)}
        clearAutocompleteValues={props.clearAutocompleteValues}
        payments={props.payments}
        changePaymentFields={props.changePaymentFields}
        setPayments={props.setPayments}
        addedPaymentsField={props.addedPaymentsField}
        // autoValuest={props.autocompleteValues}
      />
    </div>
  );
}
