import styled from 'styled-components';
import { base, colors } from '../../styles';

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  height: 100%;
  font-size: 12px;
`;

export const HeaderRow = styled.thead`
  color: ${colors.light_gray};
  background-color: #fff;
  z-index: 10;
  height: 32px;
  visibility: hidden;
`;

export const HeaderRowFixed = styled.thead`
  color: ${colors.light_gray};
  background-color: #fff;
  z-index: 10;
  height: 32px;
  position: absolute;
  left: 0;
  top: 0;
`;

export const EmptyTableRow = styled.tr`
  height: 12px;
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: 8px;
`;

export const TextHeaderColumn = styled.div`
  outline: none;
  cursor: pointer;
  user-select: none;
`;

export const SortingHeaderColumn = styled.div`
  color: ${colors.primary};
  margin-left: 6px;
`;

export const EditIcon = styled.div`
  cursor: pointer;
  background-image: url(${require('../../assets/icons/edit-red.svg')});
  background-color: white;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  float: right;
  opacity: -1;
`;

export const TableColumn = styled.td`
  outline: none;
  cursor: pointer;
  position: relative;
  border-left: 1px solid ${colors.primary_gray};
  &:hover {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: ${colors.light_gray};
      width: 100%;
    }
    ${EditIcon}{
      opacity: 1;
    }
  }
`;

export const ContextMenuWrapper = styled.span`
  .react-contextmenu {
    border: 1px solid ${colors.light_gray};
    background-color: ${colors.white};
    z-index: 50;
  }
  .react-contextmenu-item {
    padding: 6px;
    cursor: pointer;
    &:hover {
      background-color: ${colors.light_gray};
    }
  }
`;

export const Tbody = styled.tbody`
  .react-contextmenu-wrapper {
    background: ${colors.white};
    border-bottom: 1px solid ${colors.primary_gray};
    position: relative;
  }
  tr{
    &:hover{
      ${EditIcon}{
        opacity: 1;
      }
    }
  }
`;

export const TextTableColumn = styled.div`
  margin: auto;
  text-align: left;
  // margin: 0 8px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  a {
    color: ${colors.black};
    text-decoration: none;
  }
  a:visited {
    color: ${colors.black};
   }
  a:active {
    color: ${colors.black};
   }
`;

export const TableEdit = styled.input.attrs({
})`
  border: 1px solid ${colors.light_gray};
  outline: none;
  position: absolute;
  padding: 0 ${base * 0.6}px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  top: 0;
`;

export const CalendarContainer = styled.div`
  position: absolute;
  z-index: 9999;
`;
