import styled from 'styled-components';

import { base, colors } from '../../../styles';

export const Item = styled.div`
  text-align: left;
  /* font-family: 'MuseoSansCyrl Small', sans-serif; */
  color: ${colors.black};
  padding: ${base * 1}px 18px;
  transition: all .3s ease-in-out;
  position: relative;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.primary};
    &:last-child {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  &:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: rgba(46, 131, 236, 0.2);
  }

  &:last-child:after{
    content: none;
  }
`;
