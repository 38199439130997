/* eslint-disable no-underscore-dangle */
import {
    compose,
    withState,
    withHandlers,
    lifecycle,
  } from 'recompose';
  
  import { connect } from 'react-redux';
  
  // view
  import ActualTaskView from './ActualTaskView';
  
  // services
  import {
    DirectoryService, RequestsService,
  } from '../../../services';

  import {
    socket,
  } from '../../../services/socket';
  
  // actions
  import {
    setSecondaryBarItems,
    setActiveSidebarItem,
    setActiveSecondarySidebarItem,
    setExpand,
  } from '../../../store/actions/dashboard';

  export default compose(
    connect(
      state => ({
        activeSidebarItem: state.dashboard.activeItem,
        user: state.auth.currentUser,
        expand: state.dashboard.expand,
      }),
      dispatch => ({
        setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
        setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
        setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
        setExpand: state => dispatch(setExpand(state)),
      }),
    ),
    withState('counterTask', 'setCounterTask', []),
    withState('isLoading', 'setIsLoading', false),
    withState('nameTask', 'setNameTask', []),
    withHandlers({
      openTask: props => (id) => {
        props.history.push(`/dashboard/task/view/${id}`);
      },
    }),
    lifecycle({
      async componentDidMount() {
        this.props.setActiveSidebarItem('task');
        this.props.setSecondaryBarItems([{id: '1', name: 'Актуальные задачи', url: '/dashboard/task/actual',}, {id: '2', name: 'Архив задач', url: '/dashboard/task/archive',}, {id: '3', name: 'Добавление задачи', url: '/dashboard/task/create',}]);
        this.props.setActiveSecondarySidebarItem({id: '1', name: 'Актуальные задачи', url: '/dashboard/task/actual',});
        this.props.setIsLoading(true);

        const resultName = await RequestsService.getAvailableFields();
        const requests = await RequestsService.getAllRequests();
        const result = [];
        Object.keys(requests).forEach((key) => {
          const counter = {};
          if (requests[key].status !== 'Закрыта') {
            Object.keys(requests[key]).forEach((item) => {
                if ( typeof requests[key][item] === 'object') {
                  if (requests[key][item]) {
                    Object.keys(requests[key][item]).forEach((keyItem) => {
                      if (typeof requests[key][item][keyItem] === 'object') {
                        if (requests[key][item][keyItem]){
                          Object.keys(requests[key][item][keyItem]).forEach((element) => {
                            if (typeof requests[key][item][keyItem] === 'object') {
                            } else {
                              counter[element] = requests[key][item][keyItem][element];
                            }
                          });
                        }
                      } else {
                        counter[keyItem] = requests[key][item][keyItem];
                      }
                    });
                  }
                } else {
                  counter[item] = requests[key][item];
                }
            })
            result.push(counter);
          }
        });
        this.props.setNameTask(resultName);
        this.props.setCounterTask(result);
        //socket
        socket.on('tasks', (data) => {
          const tasks = this.props.counterTask;
          switch (data.type) {
            case 'delete':
              tasks.map((item, index) => {
                if (item.id === data._id) {
                  tasks.splice(index, 1);
                }
              });
              this.props.setCounterTask(tasks);
            break;
            case 'create':
              RequestsService.getRequestByIdForActiveTasks(data._id)
                .then(newTask => {
                  if (newTask.status !== 'Закрыта') {
                    tasks.push(newTask);
                    this.props.setCounterTask(tasks);
                  }
                })
                .catch(err => console.log(err));
            break;
            case 'update':
                RequestsService.getRequestByIdForActiveTasks(data._id)
                  .then(updTask => {
                    if (updTask.status !== 'Закрыта') {
                      tasks.map((item, index) => {
                        if (item.id === data._id) {
                          tasks[index] = updTask;
                        }
                      });
                      this.props.setCounterTask(tasks);
                    } else {
                      tasks.map((item, index) => {
                        if (item.id === data._id) {
                          tasks.splice(index, 1);
                        }
                      });
                      this.props.setCounterTask(tasks);
                    }
                  })
                .catch(err => console.log(err));
            break;
          }
        });
        this.props.setIsLoading(false);
      },
    }),
  )(ActualTaskView);
  