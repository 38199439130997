/* eslint-disable react/no-unused-prop-types */
/**
 * @flow
 */
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import {
  PageTitle,
  Payer,
  PayerInfo,
  Input,
  Checkbox,
  Popup,
  PayerInfoBlock,
  LinkedItem,
  SelectDropDown,
  SelectDropDownItem,
} from '../../../components';

import {
  ContentPage,
  FormCardInfo,
  ContentGlobal,
  TitleBlock,
  ButtonBlock,
  InputSearchBlock,
  PopupMessage,
  Middle,
  InfoBlock,
  Tabs,
  Tab,
  FromList,
  Paragraph,
  ListBlock,
  ListItem,
  MobileTitleBlock,
  InputWrapper,
  FormListInputWrapper,
  CheckboxWrapper,
  Loaders,
  AdditionalButtonWrapper,
  AdditionalButton,
  Button,
  AddSearch
} from './DirectoriesStyled';

type PayerPagePropsType = {
  payerList: any,
  toggle: (any) => void,
  toggleOn: boolean,
  toggleCurrent: (any) => void,
  activeID?: string,
  delPopup: boolean,
  setPopupVisible: (any) => void,
  blockData: Object,
  edit: boolean,
  setEdit: (boolean) => void,
  linkUser: boolean,
  setLinkUser: (boolean) => void,
  activeTab: number,
  setActiveTab: (number) => void,
  activeSecondaryItem: Object,
  openFormForCreate: () => void,
  setShowCreateForm: (boolean) => void,
  showCreateForm: boolean,
  isLoading: boolean,
  setToRemove: (any) => void,
  createAction: () => void,
  linkedServiceInputState: Object,
  createLinkedItem: () => void,
  itemsForLink: Object,
  forLinkTitle: Object,
  newItemData: Object,
  setNewItemData: (Object) => void,
  linkedServiceForm: Object,
  setLinkedServiceInputState: (Object) => void,
  service: Object,
  showLinkedBar: (Object, string, string) => void,
  addedAction: () => void,
  removeActoin: (string) => void,
  updateAction: () => void,
  addLinkedItem: (string, string, string) => void,
  removeLinkedItem: (string, string, string) => void,
  inputs: Object,
  setInputs: (Object) => void,
  toRemove: string,
  loadMore: () => void,
  isLoadMore: () => void,
  searchItem: (any) => void,
  hasMore: boolean,
  searchLinkedItems: (string) => void,
  setActiveID: (any) => void,
  addAdditionalLinkedInput: () => void,
  addAdditionalInput: () => void,
  serviceForm: Object,
  showUsersForLink: (Object, string, string) => void,
  removeUserFromDirectory: (Object, string, string) => void,
  addUserToDirectory: (string, string) => void,
  skip: (any) => void,
};

const WINDOW_HEIGHT = window.innerHeight;

export default function PayerPage(props: PayerPagePropsType) {
  const renderUserList = () => (
    <InfoBlock>
      <React.Fragment>
        <FromList>
          <Input
            type="search"
            placeholder="Поиск"
            search="true"
            changed={val => console.log(val)}
          />
        </FromList>
        <Paragraph smallParagraph>{props.forLinkTitle.title}</Paragraph>
        <ListBlock isScroll>
          {
            props.itemsForLink.length > 0
            && props.itemsForLink.map(item => (
              <ListItem
                withoutHidden
                key={item.id}
                onClick={() => {
                  const { id } = props.blockData;
                  props.addUserToDirectory(id, item.id);
                }}
              >
                <p>{`${item.firstName} ${item.lastName}`}</p>
              </ListItem>
            ))
          }
        </ListBlock>
      </React.Fragment>
    </InfoBlock>
  );

  const renderEditCard = () => (
    <InfoBlock>
      <Tabs>
        <Tab
          type="button"
          onClick={() => props.setActiveTab(0)}
          active={props.activeTab === 0}
        >
          Из списка
        </Tab>
        <Tab
          type="button"
          onClick={() => props.setActiveTab(1)}
          active={props.activeTab === 1}
        >
          Добавить
        </Tab>
      </Tabs>
      {props.activeTab === 0
        ? (
          <React.Fragment>
            <FromList>
              <Input
                type="search"
                placeholder="Поиск"
                search="true"
                changed={val => props.searchLinkedItems(val)}
              />
            </FromList>
            <Paragraph smallParagraph>{props.forLinkTitle.title}</Paragraph>
            <ListBlock isScroll>
              {
                props.itemsForLink.length > 0
                && props.itemsForLink.map(item => (
                  <ListItem
                    withoutHidden
                    key={item.id}
                    onClick={() => {
                      props.addLinkedItem(props.blockData.id, props.forLinkTitle.name, item.id);
                    }}
                  >
                    <p>{item.name}</p>
                  </ListItem>
                ))
              }
            </ListBlock>
          </React.Fragment>
        )
        : (
          <FromList>
            {
              props.linkedServiceForm
              && Array.isArray(props.linkedServiceForm.inputs)
              && props.linkedServiceForm.inputs.map(input => (
                <FormListInputWrapper>
                  <Paragraph smallParagraph>
                    {input.title}
                  </Paragraph>
                  <div>
                    <Input
                      placeholder={input.title}
                      changed={(value) => {
                        const inputs = { ...props.linkedServiceInputState };
                        inputs[input.name] = value;
                        props.setLinkedServiceInputState(inputs);
                      }}
                    />
                  </div>
                </FormListInputWrapper>
              ))
            }

            {'canAddInput' in props.linkedServiceForm && props.linkedServiceForm.canAddInput && (
              <AdditionalButtonWrapper>
                <AdditionalButton
                  onClick={() => props.addAdditionalLinkedInput()}
                >
                  + Поле для ввода
                </AdditionalButton>
              </AdditionalButtonWrapper>
            )}
          </FromList>
        )
      }
    </InfoBlock>
  );

  const renderNormalCard = () => (
    <React.Fragment>
      {
        props.blockData.relations
        && props.blockData.relations.length > 0
        && props.blockData.relations.map((block, i) => (
          <PayerInfoBlock
            title={block.title}
            key={`${i + 1}-${block.name}`}
            addItem={() => props.showLinkedBar(block.linkedService, block.title, block.name)}
            items={block.items && block.items.map(item => (
              <LinkedItem
                key={item._id || item.id}
                address={item.name}
                action={() => {
                  props.removeLinkedItem(props.blockData.id, block.name, item.id || item._id);
                }}
              />
            ))}
          />
        ))
      }
      {
        props.blockData.users
        && props.blockData.users.length > 0
        && props.blockData.users.map((block, i) => (
          <PayerInfoBlock
            title={block.title}
            key={`${i + 1}-${block.name}`}
            addItem={() => {
              const {
                linkedUserService,
                title,
                name,
              } = block;
              props.showUsersForLink(linkedUserService, title, name);
            }}
            items={block.items && block.items.map(item => (
              <LinkedItem
                key={item.id}
                address={`${item.firstName} ${item.lastName}`}
                action={() => {
                  const { id } = props.blockData;
                  props.removeUserFromDirectory(block.linkedUserService, id, item._id);
                }}
              />
            ))}
          />
        ))
      }
      {
        props.blockData.dropdowns
        && props.blockData.dropdowns.length > 0
        && props.blockData.dropdowns.map((dropdown) => (
          <PayerInfoBlock
            title={dropdown.title}
            items={(
              <InputWrapper>
                <SelectDropDown
                  selectedValue={dropdown.selectedValue}
                  clicked={() => console.log('click')}
                >
                  {
                    dropdown.values.map((item) => (
                      <SelectDropDownItem
                        title={item}
                        selectOption={() => {
                          const dropdowns = { ...props.inputs}
                          dropdowns[dropdown.name] = item;
                          props.setInputs(dropdowns);
                        }}
                      />
                    ))
                  }
                </SelectDropDown>
              </InputWrapper>
            )}
          />
          ))
      }
      {
        props.blockData.inputs
        && props.blockData.inputs.length > 0
        && props.blockData.inputs.map((input, i) => (
          <PayerInfoBlock
            title={input.title}
            key={`${i + 1}-${input.name}`}
            items={(
              <InputWrapper>
                <Input
                  placeholder={input.title}
                  value={props.inputs[input.name]}
                  changed={(value) => {
                    const inputs = { ...props.inputs };
                    inputs[input.name] = value;
                    props.setInputs(inputs);
                    console.log('9 tut');
                  }}
                />
              </InputWrapper>
            )}
          />
        ))
      }
      {
        props.blockData.checkboxes
        && props.blockData.checkboxes.length > 0
        && props.blockData.checkboxes.map(checkbox => (
          <InputWrapper>
            <PayerInfoBlock
              items={(
                <CheckboxWrapper>
                  <Checkbox
                    message={checkbox.title}
                    value={props.inputs[checkbox.name]}
                    onClickItem={(value) => {
                      const inputs = { ...props.inputs };
                      inputs[checkbox.name] = value;
                      props.setInputs(inputs);
                    }}
                  />
                </CheckboxWrapper>
              )}
            />
          </InputWrapper>
        ))
      }
    </React.Fragment>
  );

  return (
    <ContentGlobal>
      <ContentPage>
        <TitleBlock>
          <PageTitle dashboard>
            {props.activeSecondaryItem && props.activeSecondaryItem.name}
          </PageTitle>

          <AddSearch>
            <InputSearchBlock>
              <Input
                type="search"
                placeholder="Поиск"
                search="true"
                changed={(value) => {
                  props.searchItem(value);
                }}
              />
            </InputSearchBlock>
            <ButtonBlock>
              <Button
                onClick={() => {
                  props.openFormForCreate();
                  props.setShowCreateForm(!props.showCreateForm);
                  props.toggle();
                }}
              >
                <p>Добавить</p>
              </Button>
            </ButtonBlock>
          </AddSearch>
        </TitleBlock>
        <MobileTitleBlock>
          <Input
            type="search"
            placeholder="Поиск"
            search="true"
            changed={(value) => {
              props.searchItem(value);
            }}
          />
        </MobileTitleBlock>
        {
          props.isLoading
            ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                }}
              >
                <Loaders className="lds-circle"/>
              </div>
            )
            : (
              <div
                style={{
                  minHeight: 'calc(100vh - 288px)',
                  height: `${WINDOW_HEIGHT - 288}px`,
                  overflow: 'auto',
                  background: '#f8f8f8',
                }}
              >
                <InfiniteScroll
                  pageStart={1}
                  loadMore={(p) => {
                    if (props.isLoadMore) return;
                    if (props.service) {
                      props.loadMore(p);
                    }
                  }}
                  // hasMore={props.payerList.length < props.recordsState && !props.isLoadMore}
                  hasMore={props.hasMore && !props.isLoadMore}
                  useWindow={false}
                >
                  {
                  props.payerList.map(item => (
                    <Payer
                      id={item._id}
                      key={item._id}
                      name={item.name}
                      counters={item.counters}
                      isActive={props.activeID === item._id}
                      clickItem={() => props.toggleCurrent(item)}
                      crossClick={() => {
                        props.setToRemove(item._id);
                        props.setPopupVisible(true);
                      }}
                    />
                  ))
                }
                </InfiniteScroll>
              </div>
            )
        }
      </ContentPage>

      {
        props.showCreateForm && (
          <FormCardInfo>
            <PayerInfo
              create
              edit={props.edit}
              closeButton={() => {
                if (props.edit) {
                  props.setEdit(false);
                } else {
                  props.setShowCreateForm(false);
                }
              }}
              createAction={() => props.createAction()}
              createLinkedItem={() => {
                props.createLinkedItem();
              }}
              showAddButton={props.edit && props.activeTab !== 0}
            >
              <Middle>
                {
                  props.edit
                    ? (
                      <InfoBlock>
                        <Tabs>
                          <Tab
                            type="button"
                            onClick={() => props.setActiveTab(0)}
                            active={props.activeTab === 0}
                          >
                            Из списка
                          </Tab>
                          <Tab
                            type="button"
                            onClick={() => props.setActiveTab(1)}
                            active={props.activeTab === 1}
                          >
                            Добавить
                          </Tab>
                        </Tabs>
                        {props.activeTab === 0
                          ? (
                            <React.Fragment>
                              <FromList>
                                <Input
                                  type="search"
                                  placeholder="Поиск"
                                  search="true"
                                  changed={val => props.searchLinkedItems(val)}
                                />
                              </FromList>
                              <Paragraph smallParagraph>{props.forLinkTitle.title}</Paragraph>
                              <ListBlock isScroll>
                                {
                                  props.itemsForLink.length > 0
                                  && props.itemsForLink.map(item => (
                                    <ListItem
                                      withoutHidden
                                      key={item.id}
                                      onClick={() => {
                                        const result = { ...props.newItemData };
                                        result[props.forLinkTitle.name] = [...result[props.forLinkTitle.name], item];
                                        props.setNewItemData(result);
                                        props.setEdit(false);
                                      }}
                                    >
                                      <p>{item.name}</p>
                                    </ListItem>
                                  ))
                                }
                              </ListBlock>
                            </React.Fragment>
                          )
                          : (
                            <FromList>
                              {
                                props.linkedServiceForm
                                && Array.isArray(props.linkedServiceForm.inputs)
                                && props.linkedServiceForm.inputs.map((input, i) => (
                                  <FormListInputWrapper
                                    key={`${i + 1}-${input.name}`}
                                  >
                                    <Paragraph smallParagraph>
                                      {input.title}
                                    </Paragraph>
                                    <Input
                                      placeholder={input.title}
                                      changed={(value) => {
                                        const inputs = { ...props.linkedServiceInputState };
                                        inputs[input.name] = value;
                                        props.setLinkedServiceInputState(inputs);
                                      }}
                                    />
                                  </FormListInputWrapper>
                                ))
                              }
                              {
                                props.linkedServiceForm
                                && 'canAddInput' in props.linkedServiceForm
                                && props.linkedServiceForm.canAddInput
                                && (
                                <AdditionalButtonWrapper>
                                  <AdditionalButton
                                    onClick={() => props.addAdditionalLinkedInput()}
                                  >
                                    + Поле для ввода
                                  </AdditionalButton>
                                </AdditionalButtonWrapper>
                                )
                              }
                              {
                                props.linkedServiceForm
                                && Array.isArray(props.linkedServiceForm.checkboxes)
                                && props.linkedServiceForm.checkboxes.map((checkbox, i) => (
                                  <CheckboxWrapper
                                    key={`${i + 1}-checkbox`}
                                  >
                                    <Checkbox
                                      message={checkbox.title}
                                      value={props.newItemData[checkbox.name]}
                                      onClickItem={(value) => {
                                        const inputs = { ...props.linkedServiceInputState };
                                        inputs[checkbox.name] = value;
                                        props.setLinkedServiceInputState(inputs);
                                      }}
                                    />
                                  </CheckboxWrapper>
                                ))}
                            </FromList>
                          )
                        }
                      </InfoBlock>
                    ) : (
                      <React.Fragment>
                        {console.log('props', props.serviceForm)}
                        {/* {props.serviceForm.relations.map((item, i) => (
                          <PayerInfoBlock
                            title={item.title}
                            key={`${i + 1}-${item.name}`}
                            addItem={() => {
                              props.showLinkedBar(item.linkedService, item.title, item.name);
                            }}
                            items={props.newItemData[item.name].map(linkedItem => (
                              <LinkedItem
                                onMouseEnter={() => console.log('hover')}
                                key={linkedItem._id || linkedItem.id}
                                address={linkedItem.name}
                                action={() => {
                                  const newItemData = { ...props.newItemData };
                                  newItemData[item.name] = newItemData[item.name]
                                    .filter(newItem => newItem.id !== linkedItem.id);

                                  props.setNewItemData(newItemData);
                                }}
                              />
                            ))}
                          />
                        ))} */}
                        {props.serviceForm.dropdowns.map((dropdown, i) => (
                          <FormListInputWrapper>
                            <Paragraph smallParagraph>
                              {dropdown.title}
                            </Paragraph>
                            <InputWrapper>
                              <SelectDropDown
                                selectedValue={dropdown.selectedValue}
                                clicked={() => console.log('click')}
                              >
                                {
                                  dropdown.values.map((item) => (
                                    <SelectDropDownItem
                                      title={item}
                                      selectOption={() => {
                                        const dropdowns = { ...props.newItemData}
                                        dropdowns[dropdown.name] = item;
                                        props.setNewItemData(dropdowns);
                                      }}
                                    />
                                  ))
                                }
                              </SelectDropDown>
                            </InputWrapper>
                          </FormListInputWrapper>
                        ))}
                        {props.serviceForm.inputs.map((input, i) => (
                          <FormListInputWrapper
                            key={`${i + 1}-${input.name}`}
                          >
                            <Paragraph smallParagraph>
                              {input.title}
                            </Paragraph>
                            <InputWrapper>
                              <Input
                                placeholder={input.title}
                                value={props.newItemData[input.name]}
                                changed={(value) => {
                                  const result = { ...props.newItemData };
                                  result[input.name] = value;
                                  props.setNewItemData(result);
                                }}
                              />
                            </InputWrapper>
                          </FormListInputWrapper>
                        ))}

                        {'canAddInput' in props.serviceForm && props.serviceForm.canAddInput && (
                          <AdditionalButtonWrapper>
                            <AdditionalButton
                              onClick={() => props.addAdditionalInput()}
                            >
                              + Поле для ввода
                            </AdditionalButton>
                          </AdditionalButtonWrapper>
                        )}

                        {props.serviceForm.checkboxes.map((checkbox, i) => (
                          <CheckboxWrapper
                            key={`${i + 1}-checkbox`}
                          >
                            <Checkbox
                              message={checkbox.title}
                              value={props.newItemData[checkbox.name]}
                              onClickItem={(value) => {
                                const newItemData = { ...props.newItemData };
                                newItemData[checkbox.name] = value;
                                props.setNewItemData(newItemData);
                              }}
                            />
                          </CheckboxWrapper>
                        ))}
                      </React.Fragment>
                    )
                }
              </Middle>
            </PayerInfo>
          </FormCardInfo>
        )
      }

      <FormCardInfo>
        {
          props.toggleOn
          && (
            <PayerInfo
              edit={props.edit || props.linkUser}
              name={props.blockData.name}
              closeButton={() => {
                if (props.edit || props.linkUser) {
                  props.setEdit(false);
                  props.setLinkUser(false);
                } else {
                  props.setActiveID('');
                  props.toggle(false);
                }
              }}
              addAction={() => props.addedAction()}
              removeAction={() => props.removeActoin(props.blockData.id)}
              showAddButton={props.edit && props.activeTab !== 0}
              needUpdateButton={props.blockData.inputs.length > 0 || props.blockData.checkboxes.length > 0}
              updateAction={() => props.updateAction()}
            >
              {/* {console.log('props.blockData.name', props.blockData.name)} */}
              <Middle>
                {props.linkUser && renderUserList()}
                {props.edit && renderEditCard()}
                {(!props.edit && !props.linkUser) && renderNormalCard()}
              </Middle>
            </PayerInfo>
          )
        }
      </FormCardInfo>
      <PopupMessage show={props.delPopup}>
        <Popup status="Warning">
          <p>Удалить сущность?</p>
          <div>
            <button
              type="button"
              onClick={() => props.removeActoin(props.toRemove)}
            >
              Да
            </button>
            <button
              type="button"
              onClick={() => {
                props.setToRemove(null);
                props.setPopupVisible(false);
              }}
            >
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
    </ContentGlobal>
  );
}

PayerPage.defaultProps = {
  activeID: '',
};
