import {
  apiGet,
} from '../ApiService';

export default {
  getApiName() {
    return 'roles';
  },

  getRoles() {
    return apiGet('/roles')
      .then(response => response.data.docs.map(role => ({
        id: role._id,
        name: role.name,
        url: role.rightName,
      })))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/roles?search=${body.search}`)
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        ...item,
      })))
      .catch(error => console.log('Not Search', error));
  },
};
