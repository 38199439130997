import styled from 'styled-components';

import {
  colors,
  screens,
  base,
} from '../../../styles';

export const Content = styled.div``;

export const UserName = styled.span`
  color: ${colors.primary};
`;

export const TimeZonesWrapper = styled.div`
  max-width: ${base * 58.5}px;
  background-color: ${colors.white};
  height: ${base * 26}px;
  border-radius: 12px;
  padding: ${base * 3.85}px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (max-width: ${screens.screen_md_mid}) {
    height: auto;
    padding: 24px;
    div {
      div {
        width: 100%;
        padding: 0 24px;
      }
    }
  }

  @media (max-width: ${screens.screen_xs_max}) {
    padding: 24px;
  }

`;

export const Greeting = styled.div`
  margin-top: -12px;
`;

export const TimeZonesTitle = styled.h4`
  color: ${colors.primary};
  font-size: 24px;
  margin-bottom: 24px;
  @media (max-width: ${screens.screen_md_mid}) {
    text-align: center;
  }
  @media (max-width: ${screens.screen_xs_max}) {
    font-size: 18px;
  }
`;
