import {
  apiGet,
  apiPut,
  apiPost,
  apiDelete,
} from '../ApiService';

export default {
  getApiName() {
    return 'payments';
  },

  getItems(p = 1, params) {
    return apiGet(`/payments?page=${p}`, { params })
      .then(response => console.log(response))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },


  getById(id) {
    return apiGet(`/payments?request=${id}`)
      .then((response) => {
        const result = response.data.docs.map(item => ({
          amount: item.amount,
          date: item.date,
          paymentNumber: item.paymentNumber,
          type: item.type,
          id: item._id,
        }));

        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  addPayment(data) {
    return apiPost('/payments', data)
      .then(response => ({ result: response.data, status: response.status }))
      .catch(error => console.log('Add request error', error));
  },

  deleteItem(id) {
    return apiDelete(`/payer/${id}`)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  updatePayment(id, fields) {
    return apiPut(`/payments/${id}`, fields)
      .then(response => console.log(response))
      .catch(error => console.log(error));
  },
};
