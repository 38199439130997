import { compose, withState, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// view
import DashboardLayoutView from './DashboardLayoutView';

// services
import RoleService from '../../services/RoleService';
import DirectoryService from '../../services/DirectoryService';

// actions
import {
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setSecondaryBarItems,
} from '../../store/actions/dashboard';

export default compose(
  withRouter,

  connect(
    state => ({
      activeItem: state.dashboard.activeItem,
      activeSecondaryItem: state.dashboard.activeSecondaryItem,
      secondaryBarItems: state.dashboard.secondaryBarItems,
    }),
    dispatch => ({
      setActiveItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondaryItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
    }),
  ),

  withState('openMenu', 'setOpenMenu', false),
  // withState('secondaryBarItems', 'setSecondaryBarItems', []),
  // withState('activeItem', 'setActiveItem', null),
  // withState('activeSecondaryItem', 'setActiveSecondaryItem', null),
  withHandlers({
    getSecondaryBarItems: props => async (entity) => {
      props.setActiveItem(entity);
      props.history.replace(`/dashboard/${entity}/`);
      if (entity === props.activeItem) return null;
      switch (entity) {
        case 'users': {
          const roles = await RoleService.getRoles();
          props.setSecondaryBarItems([{ id: '', name: 'Все', url: '' }, ...roles]);
          break;
        }
        case 'refueling': {
          props.setSecondaryBarItems([]);
          break;
        }
        case 'directories': {
          const directory = await DirectoryService.getDirectories();
          props.setSecondaryBarItems(directory);
          break;
        }
        case 'task': {
          props.setSecondaryBarItems([{id: '1', name: 'Актуальные задачи', url: '/dashboard/task/actual',}, {id: '2', name: 'Архив задач', url: '/dashboard/task/archive',}, {id: '3', name: 'Добавление задачи', url: '/dashboard/task/create',}]);
          break;
        }
        default:
          return props.setSecondaryBarItems([]);
      }
      return null;
    },
    goToPage: props => (item) => {
      const hashParts = item.url.split('#');
      console.log('item', item);
      if (hashParts.length === 2) {
        const hash = hashParts.pop();
        const element = document.querySelector(`#${hash}`);
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        props.setActiveSecondaryItem(item);
        return;
      }
      props.history.push({
        pathname: item.url,
        state: { entityId: item.id, entityName: item.name },
      });
      props.setActiveSecondaryItem(item);
    },
  }),
)(DashboardLayoutView);
