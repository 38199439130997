import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const SideBarWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${colors.white};
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  bottom: 0;
`;

export const Primary = styled.div`
  width: 78px;
  height: 100vh;
  padding: 82px 0 48px 0;
  overflow: hidden;
  transition: width .3s ease-in-out;
  box-shadow: -6px 0 24px 0 ${colors.primary_white_gray};
  z-index: 5;
  ${props => props.theme.isOpen && css`
    width: ${base * 20}px;
  `}

  @media (max-width: ${screens.screen_xs_max}) {
    margin-left: -${base * 7.5}px;
    transition: margin-left .3s ease-in-out;
    background-color: ${colors.white};
    box-shadow: none;
    padding: 0;
    width: ${base * 7.5}px;
  }
`;

export const SideBarDetailed = styled.div`
  display: none;
  ${props => props.theme.isOpen && css`
    display: block;
  `}
`;

export const SideBarExpander = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  color: #000;
  z-index: 10;
  cursor: pointer;

  @media(max-height: ${screens.screen_4xs_max}) {
    position: relative;
    bottom: 0;
  }

  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;

export const SecondaryBar = styled.div`
  width: 0;
  transition: width .3s ease-in-out;
  width: 200px;
  background-color: ${colors.white};
  box-shadow: 6px 0 12px 0 ${colors.white_smoke};
  padding: 82px 0px 48px 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* margin-left: 6px; */

  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;

// only mobile

export const SideBarLogo = styled.img.attrs({
  src: props => props.logoPath,
})`
  display: none;

  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
    margin: 24px auto ${base * 5}px auto;
    width: ${base * 3.75}px;
  }
`;

export const SideBarAddBtn = styled.div`
  display: none;
  @media (max-width: ${screens.screen_xs_max}) {
    display: block;

    & > button {
      margin: 12px auto;
    }
  }
`;
