/**
 * @flow
 */

import React from 'react';

import {
  Input,
  Button,
  SelectDropDown,
  SelectDropDownItem,
} from '../index';

import {
  Box,
  Top,
  Middle,
  Bottom,
  UserAvatar,
  Info,
  Paragraph,
  FormListInputWrapper,
  InputWrapper,
  Content,
} from './UserCardInfoStyled';

type UserCardInfoViewPropsType = {
  edit: boolean,
  firstName: string,
  lastName: string,
  position: string,
  login: string,
  password: string,
  repeatedPassword: string,
  phoneNumber: string,
  email: string,
  avatar?: string,
  saveAction: (any) => void,
  changeFirstName: (string) => void,
  changeLastName: (string) => void,
  changeLogin: (string) => void,
  changeNewPassword: (string) => void,
  changeNewPasswordRepeated: (string) => void,
  changePhoneNumber: (string) => void,
  changeEmail: (string) => void,
  deleteAction: () => void,
  addAction: () => void,
  cancelAction: () => void,
  roles: () => void,
  setRoleId: () => void,
};

export default function UserCardInfoView(props: UserCardInfoViewPropsType) {
  return (
    <Box>
      <Content>
      <Top>
        <UserAvatar avatar={props.avatar} />
        <Info>
          {props.firstName ? <Paragraph firstname>{props.firstName}</Paragraph>
            : <Paragraph firstname>Новый</Paragraph>}
          {props.lastName ? <Paragraph lastname>{props.lastName}</Paragraph>
            : <Paragraph lastname>Пользователь</Paragraph>}
          {props.position ? <Paragraph position>{props.position}</Paragraph>
            : <Paragraph position>{props.position}</Paragraph>}
        </Info>
      </Top>
      <Middle>
      {props.edit
          ? (
            <FormListInputWrapper>
              <Paragraph smallParagraph>Должность</Paragraph>
              <InputWrapper>
                <SelectDropDown
                  placeholder="Выберите должность"
                  selectedValue={props.position}
                  clicked={() => {
                    console.log('click');
                  }}
                >
                  {
                    props.roles.map(item => (
                      <SelectDropDownItem
                        key={item.id}
                        title={item.name}
                        selectOption={() => props.setRoleId(item.id)}
                      />
                    ))
                  }
                </SelectDropDown>
              </InputWrapper>
            </FormListInputWrapper>
          )
          : null
        }
        <FormListInputWrapper>
            <Paragraph smallParagraph>Имя</Paragraph>
            <InputWrapper>
            <Input
              type="text"
              placeholder="Имя работника"
              value={props.firstName}
              changed={(value) => {
                props.changeFirstName(value);
              }}
            />
            </InputWrapper>
        </FormListInputWrapper>
        <FormListInputWrapper>
          <Paragraph smallParagraph>Фамилия</Paragraph>
          <InputWrapper>
            <Input
              type="text"
              placeholder="Фамилия работника"
              value={props.lastName}
              changed={(value) => { props.changeLastName(value); }}
            />
          </InputWrapper>
        </FormListInputWrapper>
        <FormListInputWrapper>
          <Paragraph smallParagraph>Логин</Paragraph>
          <InputWrapper>
            <Input
              type="text"
              placeholder="Укажите логин"
              value={props.login}
              changed={(value) => { props.changeLogin(value); }}
            />
          </InputWrapper>
        </FormListInputWrapper>
        <FormListInputWrapper>
          <Paragraph smallParagraph>Новый пароль</Paragraph>
          <InputWrapper>
            <Input
              type="password"
              placeholder={props.edit ? '∗∗∗∗∗∗∗∗∗∗∗∗∗' : 'Укажите пароль'}
              value={props.password}
              changed={value => props.changeNewPassword(value)}
              valid={props.password === props.repeatedPassword}
            />
          </InputWrapper>
        </FormListInputWrapper>
        <FormListInputWrapper>
          <Paragraph smallParagraph>Повторите пароль</Paragraph>
          <InputWrapper>
            <Input
              type="password"
              placeholder={props.edit ? '∗∗∗∗∗∗∗∗∗∗∗∗∗' : 'Укажите пароль'}
              value={props.repeatedPassword}
              changed={value => props.changeNewPasswordRepeated(value)}
              valid={(props.password === props.repeatedPassword)}
            />
          </InputWrapper>
        </FormListInputWrapper>
        <FormListInputWrapper>
          <Paragraph smallParagraph>Телефон</Paragraph>
          <InputWrapper>
            <Input
              type="text"
              placeholder="Телефон работника"
              value={props.phoneNumber}
              changed={(value) => { props.changePhoneNumber(value); }}
            />
          </InputWrapper>
        </FormListInputWrapper>
        <FormListInputWrapper>
          <Paragraph smallParagraph>Электронная почта</Paragraph>
          <InputWrapper>
            <Input
              type="text"
              placeholder="Почта работника"
              value={props.email}
              changed={(value) => { props.changeEmail(value); }}
            />
          </InputWrapper>
        </FormListInputWrapper>
      </Middle>
      </Content>
      <Bottom>
        {props.edit
          && <Button mode="white" maxWidth clicked={() => props.deleteAction()}>Удалить</Button>
        }
        {props.edit
          ? <Button maxWidth mode="blue" clicked={() => props.saveAction()}>Сохранить</Button>
          : <Button maxWidth mode="blue" clicked={() => props.addAction()}>Добавить</Button>
        }
        <Button mode="white" maxWidth clicked={() => props.cancelAction()}>Отменить</Button>
      </Bottom>
    </Box>
  );
}

UserCardInfoView.defaultProps = {
  avatar: require('../../assets/images/default_avatar.png'),
};
