/**
 * @flow
 */

import React from 'react';
import Slider from 'react-slick';
import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';

import {
  MobileContent,
} from './SecondaryBarMobileStyled';

type SecondaryBarMobilePropsType = {
  children: any,
};

export default function SecondaryBarMobileView(props: SecondaryBarMobilePropsType) {
  const settings = {
    infinite: true,
    centerMode: false,
    speed: 500,
    focusOnSelect: true,
    variableWidth: true,
    slidesToShow: 3,
  };
  return (
    <MobileContent>
      <Slider {...settings}>
        {props.children}
      </Slider>
    </MobileContent>
  );
}
