import {
  compose, withState, withHandlers, lifecycle,
} from 'recompose';

import { connect } from 'react-redux';

import ChangePasswordView from './ChangePasswordView';

// services
import UserService from '../../../../services/UserService';

// actions
import { saveUserData } from '../../../../store/actions/auth';
import {
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setSecondaryBarItems,
  checkStatusPopup,
} from '../../../../store/actions/dashboard';

// const PASSWORD_PROFILE_CHECK = 'abcde123';

export default compose(
  withState('firstValue', 'setFvalue', null),
  withState('secondValue', 'setSvalue', null),
  withState('thirdValue', 'setTvalue', null),
  withState('buttonState', 'setButtonState', false),
  withState('saveFirstPasswod', 'setSaveFPassowrd', null),
  withState('saveSecondPasswod', 'setSaveSPassowrd', null),
  withState('saveThirdPasswod', 'setSaveTPassowrd', null),
  // withState('dataSaved', 'setDataSaved', false),
  withState('wrongState', 'setWrongState', false),
  withState('wrongText', 'setWrongText', ''),

  connect(
    state => ({
      user: state.auth.currentUser,
      checkStatus: state.dashboard.checkStatusPopup,
    }),
    dispatch => ({
      saveUserData: data => dispatch(saveUserData(data)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: item => dispatch(checkStatusPopup(item)),
    }),
  ),
  withHandlers({
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    savePasswordData: props => async () => {
      const body = {
        password: props.saveSecondPasswod,
        oldpassword: props.saveFirstPasswod,
      };

      try {
        const result = await UserService.updateUser(props.user._id, body);
        if (result.status === 200) {
          props.functionCheckTime('Success', 'Данные сохранены');
        }
      } catch (error) {
        console.log('error', error.message);
        props.functionCheckTime('Error', error.message);
      }
    },
  }),
  withHandlers({
    toggleButton: ({
      firstValue, secondValue, thirdValue, setButtonState,
    }) => () => {
      setButtonState(false);
      if (firstValue && secondValue && thirdValue >= 1) {
        setButtonState(true);
      }
    },
    statusCheck: ({
      saveFirstPasswod,
      saveSecondPasswod,
      saveThirdPasswod,
      secondValue,
      setWrongText,
      setWrongState,
      // setDataSaved,
      savePasswordData,
    }) => () => {
      if (saveFirstPasswod) {
        if (secondValue >= 6) {
          if (saveSecondPasswod === saveThirdPasswod) {
            // setDataSaved(true);
            setWrongState(false);
            savePasswordData();
          } else {
            setWrongText('Пароли не совпадают');
            // setDataSaved(false);
            setWrongState(true);
            setTimeout(() => {
              setWrongState(false);
            }, 3000);
          }
        } else {
          setWrongText('Пароль не может быть меньше 6 символов');
          // setDataSaved(false);
          setWrongState(true);
          setTimeout(() => {
            setWrongState(false);
          }, 3000);
        }
      } else {
        setWrongText('Неверный пароль');
        // setDataSaved(false);
        setWrongState(true);
        setTimeout(() => {
          setWrongState(false);
        }, 3000);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setSecondaryBarItems(
        [
          {
            id: '123',
            name: 'Личная информация',
            url: '/dashboard/profile/personalInfo',
          },
          {
            id: '1234',
            name: 'Смена пароля',
            url: '/dashboard/profile/changePassword',
          },
          {
            id: '12345',
            name: 'Настройка колонок',
            url: '/dashboard/profile/custColumns',
          },
        ]);
    },
  }),
)(ChangePasswordView);
