import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const Box = styled.form`
  height: calc(100vh - 112px);
  width: 426px;
  background-color: #fff;
  padding: 32px 0 0;
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100vw;
    padding-top: 48px;
  }
  ${props => props.modaleWindow && css`
    padding: 0;
    margin-top: 24px;
    align-items: center;
  `}
`;

export const Top = styled.div`
  display: flex;
  padding: 0 32px;
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${base * 2.5}px;
`;

export const ListBlock = styled.div`
  height: ${base * 23}px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const TextArea = styled.textarea`
  background-color: ${colors.white_smoke};
  border: none;
  border-radius: 6px;
  font-size: 12px;
  color: ${colors.light_gray};
  padding: 12px 16px;
  resize: none;
  outline: none;
  width: calc(100% - ${base * 2.5}px);
  height: 84px;
`;

export const InfoBlock = styled.div`
  margin-top: 18px;
  border: 1px solid ${colors.primary};
  &:first-child{
    margin-top: 0;
  }
  &:last-child{
    margin-bottom: 12px;
  }
`;

export const ListItem = styled.div`
  padding: 3px 32px;
  position: relative;
  flex-direction: row;
  display: flex;
  p {
    font-size: 18px;
    color: ${colors.black};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ${props => props.withoutHidden && css`
    display: block;
    p {
      overflow: visible;
      white-space: normal;
    }
  `}
  &:hover {
    background-color: ${colors.white_smoke};
  }
`;

export const Bottom = styled.div`
  padding: 20px 32px 30px;
  border-top: 1px solid #f8f8f8;
  justify-content: space-between;
  display: flex;
  // flex-direction: row-reverse;
  width: calc(100% - ${base * 6.5}px);
  margin: auto 0 0 0;
  ${props => props.onlyView && css`
    justify-content: center;
    button{
      width: 100%;
    }
  `}
  button {
    display: inline;
    width: calc(33.33% - 3px);
    margin-top: 12px;
    text-align: center;
    font-size: 14px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FullInfo = styled.p`
  display: none;
  position: absolute;
`;

export const Tabs = styled.p`
  flex-direction: row;
  padding: 0 32px;
`;

export const Tab = styled.button`
  margin-right: ${base * 6}px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: ${colors.light_gray};
  outline: none;
  &:hover {
    color: ${colors.primary};
  }
  &:active {
    color: ${colors.primary};
    outline: none;
    border: none;
  }
  ${props => props.active && css`
    color: ${colors.primary};
  `}
`;

export const FromList = styled.div`
  padding: 0 32px;
  margin: 18px 0 18px;
`;

export const Content = styled.div`
  overflow-y: auto;
  width: 100%;
  ${props => props.theme.needPadding && css`
    padding-left: ${base * 37}px;
  `}
`;

export const Paragraph = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: 24px;
  ${props => props.smallParagraph && css`
    /* font-family: 'MuseoSansCyrl light',sans-serif; */
    font-size: 18px;
    color: ${colors.light_gray};
    padding: 0 32px 0 32px;
  `}
`;
