import {
  compose,
  withState,
} from 'recompose';
import { connect } from 'react-redux';

// actions
import {
  saveUserInfoFirstNameData,
  saveUserInfoLastNameData,
} from '../../store/actions/users';

import PayerInfoView from './PayerInfoView';

export default compose(
  // withState('edit', 'setEdit', false),
  withState('itemToAdd', 'onItemSelect', ''),
  withState('activeTab', 'setActiveTab', 0),
  connect(
    state => ({
      usersInfoFirstName: state.users.usersInfoFirstName,
      usersInfoLastName: state.users.usersInfoLastName,
      needPadding: state.dashboard.needPadding,
    }),
    dispatch => ({
      saveUserInfoFirstNameData: items => dispatch(saveUserInfoFirstNameData(items)),
      saveUserInfoLastNameData: items => dispatch(saveUserInfoLastNameData(items)),
    }),
  ),
)(PayerInfoView);
