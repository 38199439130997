/**
 * @flow
 */
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { hideMenu } from 'react-contextmenu';

import {
  Form,
  ControlContainer,
  FiltersContainer,
  TableContainer,
  CalendarContainer,
  TitleContainer,
  TitleButton,
  CalendarButton,
  InputDate,
  InputSearch,
  DateFilterActive,
  FiltersValueContainer,
  FiltersValueTitle,
  FiltersValue,
  FiltersValueDelete,
  DateFiltersValueDelete,
  Select,
  ValueOptions,
  OptionValues,
  MobileControlContainer,
  MobileInputSearch,
  Heading,
  MobileInputDate,
  Loaders,
  ExpandButtonWrapper,
  IconButtonWrapper,
  ButtonBlock,
  AddSearch,
  ButtonAdd,
  Buttons,
} from './RequestTableStyles';

import {
  RequestTable,
  Calendar,
  Button,
  PageTitle,
  Input,
} from '../../../components/index';

type RequestTablePropsType = {
  requests: Array<any>,
  availableFields: Object,
  viewCalendar: boolean,
  updateTable: boolean,
  clickCalendar: () => void,
  setViewCalendar: (any) => void,
  changeDateRange: (any) => void,
  dateFilters: string,
  dateFiltersActive: boolean,
  deleteDateFilter: () => void,
  filters: Object,
  addFilter: () => void,
  applyFilters: () => void,
  changeFilters: (any, any) => void,
  selectedFiltersValue: (any, any) => void,
  deleteFilter: (any) => void,
  updateSorting: (any) => void,
  search: (any) => void,
  selectedRequests: Array<any>,
  selectRequest: () => void,
  typeFields: Object,
  showAutocompleteValues: Object,
  autocompleteValues: Object,
  openRequest: () => void,
  getExcel: (any) => void,
  addRequest: () => void,
  deleteRequests: () => void,
  amountSuccessfull: () => void,
  cancel: () => void,
  generateTable: (any) => void,
  page: number,
  selectAllRequests: (boolean) => void,
  filtersActivePoint: boolean,
  isLoading: boolean,
  expand: boolean,
  setExpand: (boolean) => void,
  checkboxView: boolean,
  setCheckboxView: () => void,
  moreLoading: boolean,
  editRequest: (string, string, string) => void,
  copyRequest: (string) => void,
};

const WINDOW_HEIGHT = window.innerHeight;

export default function RequestTableView(props: RequestTablePropsType) {
  const filters = [];
  const autocompleteValues = {};
  let dateFilters = '';
  // генерация автокомплитов фильтров
  Object.keys(props.autocompleteValues).forEach((field) => {
    autocompleteValues[field] = [];
    props.autocompleteValues[field].forEach((value) => {
      let data = '';
      Object.keys(value).forEach((key) => {
        if (key !== '_id') {
          if (key !== 'id') {
            if (value[key]) {
              if (!Array.isArray(value[key])) {
                data += value[key];
                data += ' ';
              }
            }
          }
        }
      });
      autocompleteValues[field].push(
        <OptionValues
          key={value.id || value._id}
          onClick={() => props.selectedFiltersValue(field, value)}
        >
          {data}
        </OptionValues>,
      );
    });
  });
  // генерация строки фильтрации
  if (Object.keys(props.filters).length !== 0) {
    Object.keys(props.filters).forEach((field) => {
      switch (props.typeFields[field].type) {
        case 'input': {
          let inputTextValue = '';
          const inputNumberValue = {
            begin: 0,
            end: 0,
          };
          if (props.filters[field]) {
            if (props.typeFields[field].inputType === 'date') {
              const dateSplit = props.filters[field].split('-');
              if (dateSplit.length === 3) {
                inputTextValue = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
              }
            } else if (props.typeFields[field].inputType === 'number') {
              inputNumberValue.begin = props.filters[field].begin || '';
              inputNumberValue.end = props.filters[field].end || '';
            }
          }
          if (props.typeFields[field].inputType !== 'number') {
            filters.push(
              <FiltersValueContainer key={field}>
                <FiltersValueTitle>
                  {props.availableFields[field]}
                </FiltersValueTitle>
                <FiltersValue>
                  <Input
                    type={props.typeFields[field].inputType}
                    value={inputTextValue}
                    changed={(value) => {
                      if (props.typeFields[field].inputType === 'date') {
                        const [year, month, day] = value.split('-');
                        value = `${day}-${month}-${year}`;
                      }
                      props.changeFilters(field, value);
                    }}
                  />
                  <FiltersValueDelete
                    onClick={() => props.deleteFilter(field)}
                    src={require('../../../assets/icons/close-default.svg')}
                    alt="delete"
                  />
                </FiltersValue>
              </FiltersValueContainer>,
            );
          } else {
            filters.push(
              <FiltersValueContainer
                key={field}
                style={{
                  width: 'auto',
                }}
              >
                <FiltersValueTitle>
                  {props.availableFields[field]}
                </FiltersValueTitle>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      marginRight: '2px',
                    }}
                  >
                    от
                  </div>
                  <FiltersValue
                    style={{
                      width: '76px',
                      margin: '0 6px 0 0',
                    }}
                  >
                    <Input
                      type={props.typeFields[field].inputType}
                      value={inputNumberValue.begin}
                      changed={value => props.changeFilters(field, { begin: value })}
                    />
                    <FiltersValueDelete
                      onClick={() => props.deleteFilter(field)}
                      src={require('../../../assets/icons/close-default.svg')}
                      alt="delete"
                    />
                  </FiltersValue>
                  <div
                    style={{
                      marginRight: '2px',
                    }}
                  >
                    до
                  </div>
                  <FiltersValue
                    style={{
                      width: '76px',
                    }}
                  >
                    <Input
                      type={props.typeFields[field].inputType}
                      value={inputNumberValue.end}
                      changed={value => props.changeFilters(field, { end: value })}
                    />
                    <FiltersValueDelete
                      onClick={() => props.deleteFilter(field)}
                      src={require('../../../assets/icons/close-default.svg')}
                      alt="delete"
                    />
                  </FiltersValue>
                </div>
              </FiltersValueContainer>,
            );
          }
          break;
        }
        case 'select': {
          let data = props.filters[field];
          const options = [];
          if (!data) {
            data = '';
          }
          props.typeFields[field].values.forEach((value) => {
            options.push(<option key={value}>{value}</option>);
          });
          filters.push(
            <FiltersValueContainer key={field}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <Select
                  defaultValue={data}
                  onChange={event => props.changeFilters(field, event.target.value)}
                >
                  {options}
                </Select>
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        case 'autocomplete': {
          let data = '';
          if (props.filters[field]) {
            if (typeof props.filters[field] === 'object') {
              Object.keys(props.filters[field]).forEach((key) => {
                if (key !== '_id') {
                  if (key !== 'id') {
                    if (!Array.isArray(props.filters[field][key])) {
                      data += props.filters[field][key];
                      data += ' ';
                    }
                  }
                }
              });
            } else {
              data = props.filters[field];
            }
          }
          filters.push(
            <FiltersValueContainer key={field}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <Input
                  type="text"
                  value={data}
                  changed={value => props.changeFilters(field, value)}
                />
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
                {(props.showAutocompleteValues[field])
                  ? (
                    <ValueOptions>
                      {(props.autocompleteValues[field]) ? autocompleteValues[field] : null}
                    </ValueOptions>
                  )
                  : null}
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        case 'checkbox': {
          const data = props.filters[field];
          filters.push(
            <FiltersValueContainer key={field}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <input
                  type="checkbox"
                  checked={data}
                  onChange={event => props.changeFilters(field, event.target.checked)}
                />
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
                {(props.showAutocompleteValues[field])
                  ? (
                    <ValueOptions>
                      {(props.autocompleteValues[field]) ? autocompleteValues[field] : null}
                    </ValueOptions>
                  )
                  : null}
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        default:
          break;
      }
    });
  }
  // генерация строки диапазона дат
  if (props.dateFilters !== '') {
    try {
      const date = JSON.parse(props.dateFilters);
      date[0] = date[0].split('-');
      date[1] = date[1].split('-');
      dateFilters = `${date[0][2]}.${date[0][1]}.${date[0][0]}`;
      dateFilters += ' - ';
      dateFilters += `${date[1][2]}.${date[1][1]}.${date[1][0]}`;
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Form style={{ position: 'relative' }}>
      <ControlContainer>
        <TitleContainer>
          <Heading>Архив задач</Heading>
        </TitleContainer>
        <div>
          <div style={{display: 'flex', alignItems: 'center', padding: '0 0 12px 0'}}>
          {(Object.keys(props.filters).length !== 0)
              ? (
                <TitleButton filtersActivePoint={props.filtersActivePoint}>
                  <Button
                    circle
                    clicked={() => props.applyFilters()}
                  >
                    <img
                      style={{ width: '14px' }}
                      src={require('../../../assets/icons/filters.svg')}
                      alt="filters"
                    />
                  </Button>
                </TitleButton>
              )
              : null
            }
            {(props.selectedRequests.length !== 0)
              ? (
                <TitleButton>
                  <Buttons
                  onClick={() => props.deleteRequests()}
                  >
                    <p>Удалить</p>
                  </Buttons>
                </TitleButton>
              )
              : null
            }
            {(props.selectedRequests.length !== 0 || Object.keys(props.filters).length !== 0)
              ? (
                <TitleButton>
                <Buttons
                  onClick={() => props.cancel()}
                >
                  <p>Закрыть</p>
                </Buttons>
                </TitleButton>
              )
              : null
            }
              <TitleButton>
                <Buttons
                  onClick={() => props.getExcel()}
                >
                  <p>Выгрузить</p>
                </Buttons>
              </TitleButton>
          {(props.dateFilters !== '')
            ? (
              <InputDate>
                <Input
                  type="text"
                  value={dateFilters}
                  changed={() => props.setViewCalendar(true)}
                />
                <DateFiltersValueDelete
                  onClick={() => props.deleteDateFilter()}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
              </InputDate>
            )
            : null}
            <InputSearch>
            <Input
              type="search"
              placeholder="Поиск"
              search="true"
              changed={value => props.search(value)}
            />
          </InputSearch>
          {
            <CalendarButton filterActive={props.dateFiltersActive}>
              <Buttons
                  onClick={() => props.clickCalendar()}
                >
                  <p>Календарь</p>
                </Buttons>
            </CalendarButton>
          }
          {(props.viewCalendar)
            ? (
              <CalendarContainer>
                <Calendar eventOnOk={props.changeDateRange} />
              </CalendarContainer>
            )
            : null}
          <TitleButton>
            <Button
              circle
              iconExpand
              clicked={() => {
                props.setExpand(!props.expand);
                const element = document.querySelector('.main-dashboard');
                const scrollArea = document.querySelector('.scroll-area');
                if (element && element.classList.contains('hidden')) {
                  if (element) {
                    element.classList.remove('hidden');
                  }
                  if (scrollArea) {
                    scrollArea.style.height = `${window.innerHeight - 225}px`;
                  }
                } else {
                  if (element) {
                    element.classList.add('hidden');
                  }
                  if (scrollArea) {
                    scrollArea.style.height = `${window.innerHeight - 100}px`;
                  }
                }
              }}
            >
              {
                !props.expand
                  ? (
                    <img
                      style={{ width: '12px' }}
                      src={require('../../../assets/icons/expand.svg')}
                      alt="calendar"
                    />
                  ) : (
                    <img
                      style={{ width: '12px' }}
                      src={require('../../../assets/icons/despand.svg')}
                      alt="calendar"
                    />
                  )
              }
            </Button>
          </TitleButton>
          </div>
        </div>
        <MobileControlContainer>
          <MobileInputSearch>
            <Input
              type="search"
              placeholder="Поиск"
              search="true"
              changed={value => props.search(value)}
            />
          </MobileInputSearch>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {(props.dateFilters !== '')
              ? (
                <InputDate>
                  <Input
                    type="text"
                    value={dateFilters}
                    changed={() => props.setViewCalendar(true)}
                  />
                  <DateFiltersValueDelete
                    onClick={() => props.deleteDateFilter()}
                    src={require('../../../assets/icons/close-default.svg')}
                    alt="delete"
                  />
                </InputDate>
              )
              : null}
          </div> */}
        </MobileControlContainer>
      </ControlContainer>
      {(Object.keys(props.filters).length !== 0)
        ? (
          <FiltersContainer>
            {filters}
          </FiltersContainer>
        )
        : null
      }
      <TableContainer className="table-container">
        <div
          style={{ minHeight: 'calc(100vh - 288px)', height: `${WINDOW_HEIGHT - 288}px`, overflow: 'auto' }}
          className="scroll-area"
          onScroll={(event) => {
            const element = document.querySelector('.table-header-wrapper');
            hideMenu();
            if (element) {
              element.style.left = `${-event.currentTarget.scrollLeft}px`;
            }
          }}
        >
          {
            props.isLoading
              ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <Loaders  className="lds-circle"/>
                </div>
              )
              : (
                <InfiniteScroll
                  pageStart={1}
                  loadMore={(p) => {
                    props.generateTable(p);
                  }}
                  threshold={1000}
                  hasMore={
                    Array.isArray(props.requests) && !props.moreLoading
                      ? props.page * 10 <= props.requests.length
                      : false
                  }
                  useWindow={false}
                >
                  <RequestTable
                    addFilter={props.addFilter}
                    setSorting={props.updateSorting}
                    selectedRequests={props.selectedRequests}
                    selectRequest={props.selectRequest}
                    openRequest={props.openRequest}
                    columnTitles={props.availableFields}
                    data={props.requests}
                    selectAllRequests={props.selectAllRequests}
                    checkboxView={props.checkboxView}
                    setCheckboxView={props.setCheckboxView}
                    updateRequest={props.editRequest}
                    deleteRequests={props.deleteRequests}
                    amountSuccessfull={props.amountSuccessfull}
                    copyRequest={props.copyRequest}
                    updateTable={props.updateTable}
                  />
                </InfiniteScroll>
              )
          }
        </div>
      </TableContainer>
    </Form>
  );
}
