import styled from 'styled-components';

import { base, colors, screens } from '../../styles';

export const NavBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  height: 32px;
  width: calc(100% - 48px);
  box-shadow: 1px 1px 6px #e8e8e8;
  padding: 24px;
  position: fixed;
  z-index: 10;
`;

export const LogoWrapper = styled.div`
  width: ${base * 14}px;
  margin-left: 12px;
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  & > button{
    margin-right: 12px;
  }
`;

export const MobileNavbar = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: ${screens.screen_xs_max}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const AddButtonWrapper = styled.div`
  margin-right: 24px;
  & > button {
    height: 32px;
    width: 32px;
    padding: 0;
    border-radius: 50%;
  }
`;

// only mobile
export const HideComponent = styled.div`
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;
