import moment from 'moment';
import {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
  } from '../ApiService';

  export default {
    getApiName() {
      return 'shifts';
    },

    getItems(p = 1, params) {
      return apiGet(`/shifts?page=${p}`, { params })
        .then(response => ({
            data: response.data.docs.map(shifts => ({
                ...shifts,
                id: shifts._id,
                name: `Тип задачи ${shifts.type}, ${shifts.user.firstName} ${shifts.user.lastName}, Начало: ${moment(shifts.start.date).format('DD-MM-YYYY HH:mm:ss')}, Окончание: ${(shifts.type === 'закрыта') ? `${moment(shifts.end.date).format('DD-MM-YYYY HH:mm:ss')}` : 'нет данных'}`,
            })),
          records: response.data.records.all,
        }))
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },

    getById(id) {
      return apiGet(`/shifts/${id}`)
        .then((response) => {
          const result = {
            id: response.data._id,
            name: `${response.data.user.firstName} ${response.data.user.lastName}`,
            relations: [],
            inputs: [],
            checkboxes: [],
            dropdowns: [],
          };

          result.inputs.push({
            title: 'Тип задачи',
            name: 'type',
            value: response.data.type,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Дата создания',
            name: 'start',
            value: moment(response.data.start[`date`]).format('DD-MM-YYYY HH:mm:ss'),
            readOnly: true,
          });

          result.inputs.push({
            title: 'Дата окончания',
            name: 'end',
            value: (response.data.type === 'закрыта') ? (moment(response.data.end[`date`]).format('DD-MM-YYYY HH:mm:ss')) : 'нет данных',
            readOnly: true,
          });

          result.inputs.push({
            title: 'Имя',
            name: 'firstName',
            value: response.data.user.firstName,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Фамилия',
            name: 'lastName',
            value: response.data.user.lastName,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Номер телефона',
            name: 'phone',
            value: response.data.user.phone,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Электронная почта',
            name: 'email',
            value: response.data.user.email,
            readOnly: true,
          });

          
          return result;
        })
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },

    // removeLinkedItem() {
    //   return null;
    // },

    // addLinkedItem() {
    //   return null;
    // },

    // deleteItem(id) {
    //   return apiDelete(`/refueling/${id}`)
    //     .then(response => response)
    //     .catch((error) => {
    //       throw new Error(error.response.data.error.message);
    //     });
    // },

    getFields() {
      return {
        relations: [],
        inputs: [
          {
            title: 'Тип задачи',
            name: 'type',
          },
          {
            title: 'Дата создания',
            name: 'start',
          },
          {
            title: 'Дата окончания',
            name: 'end',
          },
          {
            title: 'Имя',
            name: 'firstName',
          },
          {
            title: 'Фамилия',
            name: 'lastName',
          },
          {
            title: 'Код страны',
            name: 'phone',
          },
          {
            title: 'Электронная почта',
            name: 'email',
          },
        ],
        checkboxes: [],
      };
    },

    // updateItem(id, body) {
    //   return apiPut(`/refueling/${id}`, body)
    //     .then(response => response)
    //     .catch((error) => {
    //       throw new Error(error.response.data.error.message);
    //     });
    // },

    // createItem(body) {
    //   return apiPost('/refueling', body)
    //     .then(response => ({
    //       status: response.status,
    //       data: {
    //         id: response.data._id,
    //         name: ` ${response.data.fuel}, ${response.data.liters} лт, ${response.data.cost} руб, ${response.data.user.firstName} ${response.data.user.lastName}, ${response.data.car.name}-${response.data.car.number}`,
    //         ...response.data,
    //       },
    //     }))
    //     .catch((error) => {
    //       console.log('error', error);
    //       throw new Error(error.response.data.error.message);
    //     });
    // },

    searchItem(body) {
      return apiGet(`/shifts?search=${body.search}`)
        .then(response => response.data.docs.map(shifts => ({
          id: shifts._id,
          name: `Тип задачи ${shifts.type}, ${shifts.user.firstName} ${shifts.user.lastName}, Начало ${shifts.start.date}, Окончание ${shifts.end.date}`,
          ...shifts,
        })))
        .catch(error => console.log('Not Search', error));
    },
  };
