import {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
  } from '../ApiService';

  export default {
    getApiName() {
      return 'refueling';
    },

    getItems(p = 1, params) {
      return apiGet(`/refueling?page=${p}`, { params })
        .then(response => ({
            data: response.data.docs.map(refueling => ({
                id: refueling._id,
                name: ` ${refueling.liters} л, ${refueling.cost} р, ${refueling.user.firstName} ${refueling.user.lastName}, ${refueling.car.name}-${refueling.car.number}-${refueling.car.country}`,
                ...refueling,
            })),
          records: response.data.records.all,
        }))
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },

    getById(id) {
      return apiGet(`/refueling/${id}`)
        .then((response) => {
          const result = {
            id: response.data._id,
            name: `${response.data.user.firstName} ${response.data.user.lastName}`,
            relations: [],
            inputs: [],
            checkboxes: [],
            dropdowns: [],
            fuel: response.data.fuel,
          };

          result.inputs.push({
            title: 'Вид топлива',
            name: 'fuel',
            value: response.data.car.fuel,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Кол-во, л.',
            name: 'liters',
            value: response.data.liters,
            readOnly: false,
          });

          result.inputs.push({
            title: 'Стоимость, руб.',
            name: 'cost',
            value: response.data.cost,
            readOnly: false,
          });

          result.inputs.push({
            title: 'Имя',
            name: 'firstName',
            value: response.data.user.firstName,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Фамилия',
            name: 'lastName',
            value: response.data.user.lastName,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Марка',
            name: 'name',
            value: response.data.car.name,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Номер машины',
            name: 'number',
            value: response.data.car.number,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Код страны',
            name: 'country',
            value: response.data.car.country,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Описание',
            name: 'station',
            value: response.data.station,
            readOnly: false,
          });

          result.inputs.push({
            title: 'Пробег на момент заправки, км.',
            name: 'mileage',
            value: response.data.mileage,
            readOnly: false,
          });

          result.inputs.push({
            title: 'Пробег прошлой заправки, км.',
            name: 'lastMileage',
            value: response.data.lastMileage,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Разница в пробеге, км.',
            name: 'mileageDifference',
            value: response.data.mileageDifference,
            readOnly: true,
          });

          result.inputs.push({
            title: 'Расход топлива, л/км',
            name: 'consumption',
            value: response.data.consumption,
            readOnly: true,
          });
          
          return result;
        })
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },

    removeLinkedItem() {
      return null;
    },

    addLinkedItem() {
      return null;
    },

    deleteItem(id) {
      return apiDelete(`/refueling/${id}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },

    getFields() {
      return {
        relations: [],
        dropdowns: [
          {
            title: 'Вид топлива',
            name: 'fuel',
            values: ['ДТ-К5', 'АИ-95-К5', 'ГАЗ ПБА*'],
            selectedValue: null,
          },
        ],
        inputs: [
          {
            title: 'Стоимость, руб.',
            name: 'cost',
          },
          {
            title: 'Имя',
            name: 'firstName',
          },
          {
            title: 'Фамилия',
            name: 'lastName',
          },
          {
            title: 'Марка',
            name: 'name',
          },
          {
            title: 'Номер машины',
            name: 'number',
          },
          {
            title: 'Код страны',
            name: 'country',
          },
          {
            title: 'Пробег на момент заправки, км.',
            name: 'mileage',
          },
          {
            title: 'Описание',
            name: 'station',
          },
        ],
        checkboxes: [],
      };
    },

    updateItem(id, body) {
      return apiPut(`/refueling/${id}`, body)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },

    createItem(body) {
      return apiPost('/refueling', body)
        .then(response => ({
          status: response.status,
          data: {
            id: response.data._id,
            name: ` ${response.data.fuel}, ${response.data.liters} л, ${response.data.cost} р, ${response.data.user.firstName} ${response.data.user.lastName}, ${response.data.car.name}-${response.data.car.number}`,
            ...response.data,
          },
        }))
        .catch((error) => {
          console.log('error', error);
          throw new Error(error.response.data.error.message);
        });
    },

    searchItem(body) {
      return apiGet(`/refueling?search=${body.search}`)
        .then(response => response.data.docs.map(refueling => ({
          id: refueling._id,
          name: ` ${refueling.fuel}, ${refueling.liters} л, ${refueling.cost} р, ${refueling.user.firstName} ${refueling.user.lastName}, ${refueling.car.name}-${refueling.car.number}`,
          ...refueling,
        })))
        .catch(error => console.log('Not Search', error));
    },
  };
