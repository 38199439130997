import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const DropdownWraper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${base + 2}px;
  cursor: pointer;
  outline: none;
  background: url(../assets/icons/dropdown-arrow.svg);
  position: relative;
`;

export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownOptions = styled.div`
  position: absolute;
  top: ${base * 4.25}px;
  right: 0;
  width: 100%;
  min-width: ${base * 10}px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 8px 0px;
  background: ${colors.white};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const UserName = styled.div`
  color: ${colors.black};
  align-self: center;
  margin-right: 12px;
  margin-left: 6px;
  font-size: ${base * 1.3}px;

  // @media(max-width: ${screens.screen_xs_max}){
  //   display: none;
  // }
`;

export const UserAvatar = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  ${props => props.avatar && css`
    background: url(${props.avatar});
    background-position: 50%;
    background-size: cover;
    background-color: #fff;
  `}
`;
