/**
 * @flow
 */
import React from 'react';
import moment from 'moment';
import {
  Form,
  Title,
  TitleText,
  FieldContainer,
  FieldTitle,
  FieldValue,
  ExportButton,
  EditButton,
  DeleteButton,
  MobileButtons,
  MobileButton,
  ButtonS,
} from './RequestStyles';

import {
  Button,
  Checkbox,
} from '../index';

type RequestPropsType = {
  data: Object,
  columnTitles: Object,
  export: () => void,
  edit: () => void,
  delete: () => void,
};

export default function RequestView(props: RequestPropsType) {
  let color;
  const data = [];
  Object.keys(props.columnTitles).forEach((key) => {
    if (key !== 'state') {
      if (key !== 'number') {
        let fieldValue;
        if (props.data[key] !== null) {
          if (typeof props.data[key] !== 'object') {
            fieldValue = (key.includes('buh'))
              ? <FieldValue>{(props.data[key]).toLocaleString()}</FieldValue>
              : <FieldValue>{props.data[key]}</FieldValue>;
          } else {
            let value = '';
            Object.keys(props.data[key]).forEach((field) => {
              if (field !== '_id' && field !== 'id') {
                if (props.data[key][field]) {
                  value += props.data[key][field];
                  value += ' ';
                }
              }
            });
            fieldValue = <FieldValue>{(value !== '') ? value : 'нет данных'}</FieldValue>;
          }
          if (typeof props.data[key] === 'boolean') {
            fieldValue = (
              <FieldValue>
                <Checkbox
                  value={props.data[key]}
                  disabled
                />
              </FieldValue>
            );
          }
        } else {
          fieldValue = <FieldValue>нет данных</FieldValue>;
        }
        switch (key) {
          case 'dateLoad':
            data.push(
              <FieldContainer
                style={{
                  borderTop: '86px solid transparent',
                  marginTop: '-86px',
                }}
                id="customerservice"
                key={key}
              >
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>);
            break;
          case 'railCarrier':
            data.push(
              <FieldContainer
                style={{
                  borderTop: '86px solid transparent',
                  marginTop: '-86px',
                }}
                id="shipmentdepartment"
                key={key}
              >
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>);
            break;
          case 'numberContainer':
            data.push(
              <FieldContainer
                style={{
                  borderTop: '86px solid transparent',
                  marginTop: '-86px',
                }}
                id="autodepartment"
                key={key}
              >
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>);
            break;
          case 'agentAutoCall':
            data.push(
              <FieldContainer
                style={{
                  borderTop: '86px solid transparent',
                  marginTop: '-86px',
                }}
                id="documentdepartment"
                key={key}
              >
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>);
            break;
          case 'buh_carrier':
            data.push(
              <FieldContainer
                style={{
                  borderTop: '86px solid transparent',
                  marginTop: '-86px',
                }}
                id="financialdepartment"
                key={key}
              >
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>);
            break;
          case 'customerAccountNumber':
            data.push(
              <FieldContainer
                style={{
                  borderTop: '86px solid transparent',
                  marginTop: '-86px',
                }}
                id="billnumbers"
                key={key}
              >
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
            if ('buh_amount' in props.columnTitles) {
              data.push(
                <FieldContainer key="buh_amount-2">
                  <FieldTitle>Сумма счета клиента (сумма)</FieldTitle>
                  <FieldValue>
                    {props.data.buh_amount ? (props.data.buh_amount).toLocaleString() : 'нет данных'}
                  </FieldValue>
                </FieldContainer>,
              );
            }
            break;
          case 'number':
            data.push(
              <FieldContainer
                style={{
                  borderTop: '86px solid transparent',
                  marginTop: '-86px',
                }}
                id="income"
                key={key}
              >
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>);
            break;
          default:
            data.push(
              <FieldContainer key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>);
            break;
        }
      }
    }
  });

  switch (props.data.status) {
    case 'formation':
        color = '#A9D08E';
        break;
      case 'make':
        color = '#FFFF00';
        break;
      case 'work':
        color = '#FFD966';
        break;
      case 'preclose':
        color = '#F4B084';
        break;
      case 'close':
        color = '#808080';
        break;
      default:
        color = '#A9D08E';
        break;
  }

  return (
    <Form>
      <MobileButtons>
        <MobileButton>
          <Button circle clicked={() => props.export()}>
            <img
              style={{ width: '12px' }}
              src={require('../../assets/icons/export.svg')}
              alt="export"
            />
          </Button>
        </MobileButton>
        <MobileButton>
          <Button circle clicked={() => props.edit()}>
            <img
              style={{ width: '14px' }}
              src={require('../../assets/icons/edit.svg')}
              alt="edit"
            />
          </Button>
        </MobileButton>
        <MobileButton>
          <Button circle clicked={() => props.delete()}>
            <img
              style={{ width: '26px' }}
              src={require('../../assets/icons/delete-white.svg')}
              alt="delete"
            />
          </Button>
        </MobileButton>
      </MobileButtons>
      <Title>
        <TitleText>
          №{(props.data.number) ? props.data.number : ' -'} {props.data.state}
        </TitleText>
        <ExportButton>
          {/* <ButtonS onClick={() => props.export()}>
            <p>Скачать</p>
          </ButtonS> */}
        </ExportButton>
        <EditButton>
          <ButtonS onClick={() => props.edit()}>
            <p>Редактировать</p>
          </ButtonS>
        </EditButton>
        <DeleteButton>
          <ButtonS onClick={() => props.delete()}>
            <p>Удалить</p>
          </ButtonS>
        </DeleteButton>
      </Title>
      {data}
    </Form>
  );
}

RequestView.defaultProps = {
};
