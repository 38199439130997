import styled, { css } from 'styled-components';

import { base, colors } from '../../../styles';

export const SideBarItemWrapper = styled.div`
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: ${base * 20}px;;
  height: 48px;
  border-left: 6px solid transparent;
  ${props => props.active && css`
    border-left: 6px solid transparent;
    background-color: #f4f4f4;
    color: ${colors.primary};
  `}
  ${props => props.name === 'Добавление задачи' && css`
    color: white;
    background: #2e83ec;
  `}
`;

export const IconSection = styled.div`
  margin: 0 0 0 24px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const TextSection = styled.div`
  width: ${base * (20 - 7)}px;
  text-align: left;
  margin: 0 0 0 20px;
`;

export const Image = styled.img.attrs({
  src: props => props.icon,
  alt: 'icon',
})`
  width: 18px;
  height: 18px;
`;

export const Name = styled.p`
  height: auto;
  font-size: 16px;
`;
