import {
  apiPost,
  apiGet,
  apiPut,
  apiDelete,
} from '../ApiService';

import { BASE_URL, DEFAUT_AVATAR } from '../../constants';

import { RoleService } from '../index';

export default {
  getApiName() {
    return 'users';
  },

  getAllUsers() {
    return apiGet('/users')
      .then(response => response.data.docs.map(user => ({
        ...user,
        id: user._id,
        avatar: user.avatar ? BASE_URL + user.avatar : DEFAUT_AVATAR,
      })))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getUserById(id) {
    return apiGet(`/users/${id}`)
      .then(response => ({
        ...response.data,
        avatar: response.data.avatar ? BASE_URL + response.data.avatar : DEFAUT_AVATAR,
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getUsersByRole(role) {
    return apiGet('/users', { params: { role } })
      .then(response => response.data.docs.map(user => ({
        ...user,
        id: user._id,
        avatar: user.avatar ? BASE_URL + user.avatar : DEFAUT_AVATAR,
      })))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  addUser(data) {
    return apiPost('/users', data)
      .then(response => ({ result: response.data, status: response.status, }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  updateUser(id, fields, config) {
    return apiPut(`/users/${id}`, fields, config)
      .then(response => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  deleteUser(id) {
    return apiDelete(`/users/${id}`)
      .then(response => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/users?search=${(body && body.search) ? body.search : ''}`)
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        name: `${item.firstName} ${item.lastName}`,
        ...item,
      })))
      .catch(error => console.log('Not Search', error));
  },

  getUsersByRoleName(roleName) {
    return RoleService.getRoles()
      .then((response) => {
        let roleId = null;
        response.forEach((role) => {
          if (role.url === roleName) {
            roleId = role.id;
          }
        });
        return this.getUsersByRole(roleId);
      })
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
};
