import styled, { css } from 'styled-components';

import { base, screens, colors } from '../../styles';

export const Page = styled.div`
  margin: 0;
  width: 100%;
  position: relative;

  @media (max-width: ${screens.screen_xs_max}) {
    transition: transform .3s ease-in-out;
    ${props => props.theme.openMenu && css`
      transform: translateX(${base * 7.5}px);
    `}
  }
`;

export const MainSection = styled.div`
  width: 100%;
  display: flex;
  padding-top: 82px;
`;

export const SideBarPage = styled.div`
  height: 100%;
  position: sticky;
  top: 0;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-top: -${base * 5.6}px;
  }
`;

export const ContentArea = styled.div`
  //чисто для того чтобы увидеть всю таблицу. но найти ошибку
  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 84px);
  box-sizing: border-box;
  padding: ${base * 4.25}px;
  margin-top: -12px;
  background-color: ${colors.primary_gray};
  transition: margin-left .3s ease-in-out;
  padding: 48px;
  ${(props) => {
    let styles = null;
    if (props.theme.openMenu && props.theme.secondaryBar.length > 0) {
      styles = css`margin-left: 486px;`;
    } else if (props.theme.openMenu) {
      styles = css`margin-left: 240px;`;
    } else if (props.theme.secondaryBar.length > 0) {
      styles = css`margin-left: 272px;`;
    } else {
      styles = css`margin-left: 72px;`;
    }
    return styles;
  }}

  @media (max-width: ${screens.screen_xs_max}) {
    margin-left: 0;
    padding: 24px 24px 0;
  }
`;

export const MobileViewSidebar = styled.span`
  display: none;
  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
    margin-bottom: 12px;
    overflow: hidden;
  }
`;
