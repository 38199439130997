/**
 * @flow
 */
import React from 'react';

import {
  Checkbox,
  Button,
  Popup,
} from '../../../../components';

import {
  MainPage,
  TitleCust,
  CustColumnsMain,
  NameColumns,
  CheckboxContainer,
  HeaderTitle,
  PopupCustTitle,
  ColumnsSection,
  CheckBoxWrapper,
  SectionTitleWrapper,
} from './CustColumnsStyled';

type CustColumnsPropsType = {
  custList: Array<Object>,
  setCheckValue: (any) => void,
  saveCustColumnsData: (any) => void,
  custSaved: any,
  selectAllInSection: (string, boolean) => void,
};

const renderCustList = (custList, setCheckValue, selectAllInSection) => (custList.map(item => (
  <ColumnsSection key={item.name}>
    <SectionTitleWrapper>
      <NameColumns>{item.title}</NameColumns>
      <Checkbox
        message="Выбрать все"
        name={item.name}
        onClickItem={(value) => { selectAllInSection(item.name, value); }}
      />
    </SectionTitleWrapper>
    <CheckboxContainer>
      {item.items.map(checbox => (
        <CheckBoxWrapper>
          <Checkbox
            message={checbox.title}
            value={checbox.value}
            name={checbox.name}
            onClickItem={() => { setCheckValue(checbox.name); }}
          />
        </CheckBoxWrapper>
      ))}
    </CheckboxContainer>
  </ColumnsSection>
)));

export default function CustColumns(props: CustColumnsPropsType) {
  return (
    <MainPage>
      <HeaderTitle>
        <TitleCust>
          <span>Настройка</span>
          <span>колонок</span>
        </TitleCust>
        <Button
          clicked={() => {
            props.saveCustColumnsData();
          }}
        >
        Сохранить
        </Button>
      </HeaderTitle>
      <CustColumnsMain>
        <p></p>
        {renderCustList(props.custList, props.setCheckValue, props.selectAllInSection)}
      </CustColumnsMain>
      <PopupCustTitle>
        {props.custSaved && (<Popup status="Success">Данные сохранены</Popup>)}
      </PopupCustTitle>
    </MainPage>
  );
}
