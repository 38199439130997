import styled, { css } from 'styled-components';
import { base, colors, screens } from '../../../styles';

export const Form = styled.div`
  width: 100%;
  font-size: ${base * 1.333333333}px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 16px;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-bottom: 12px;
  }
`;

export const ButtonS = styled.div`
  background-color: ${colors.primary};
  border-radius: 6px;
  cursor: pointer;
  & > p {
    font-size: 12px;
    color: ${colors.white};
    padding: 12px 16px;
  }
  ${props => props.white && css`
    border-radius: 6px;
    & > p {
      color: ${colors.primary};
    }
    background-color: ${colors.white};
    border: 1px solid ${colors.primary};
  `}
`;

export const Title = styled.h1`
  align-items: center;
  font-size: 46px;
  color: black;
  font-weight: 300;
`;

export const TitleButton = styled.div`
    margin-right: auto;
    margin-left: ${base * 4.25}px;

  ${props => props.white && css`
    margin-right: 0;
    margin-left: 0;

    @media (max-width: ${screens.screen_xs_max}) {
      display: none;
    }
  `}
`;

export const Loader = styled.div`
display: none;
transform: translateZ(1px);
margin-left: 20px;
display: inline-block;
width: ${base + 4}px;
height: ${base + 4}px;
border-radius: 50%;
background: #2E83EC;
animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
opacity: 0.65;
@keyframes lds-circle {
0%, 100% {
animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
}
0% {
transform: rotateY(0deg);
}
50% {
transform: rotateY(1800deg);
animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
}
100% {
transform: rotateY(3600deg);
}
}
`;

export const PaymentsWrapper = styled.div`
  max-width: 100%;
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${screens.screen_2xs_min}) {
    justify-content: space-between;
  }

  @media (max-width: ${screens.screen_3xs_min}) {
    flex-direction: row;
    justify-content: center;
  }

  .slick-slider {
    padding: 6px 0;
  }
  .slick-slide {
    padding: 6px 0;
  }
  .slick-slider {
    width: 750px;
  }
`;

export const MobileTitleButton = styled.div`
  display: none;
  margin-bottom: ${base * 2.5}px;

  button {
    background-color: ${colors.white};
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
  }

  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
  }
`;

export const HistoryContainer = styled.div`
  background-color: ${colors.primary_gray};
  overflow-y: scroll;
  width: 40%;
  min-height: 100%;
  @media (max-width: ${screens.screen_md_max}) {
    width: 100%;
  }
`;

export const RequestWrapper = styled.div`
  width: 60%;
  @media (max-width: ${screens.screen_md_max}) {
    width: 100%;
  }
`;

export const ContainerRequest = styled.div`
  display: flex;
  @media (max-width: ${screens.screen_md_max}) {
    flex-direction: column;
  }
`;
