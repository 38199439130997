import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
} from '../ApiService';

export default {
  getApiName() {
    return 'cars';
  },

  getItems(p = 1, params) {
    return apiGet(`/cars?page=${p}`, { params })
      .then(response => ({
        data: response.data.docs.map(car => ({
          ...car,
          id: car._id,
          name: `Марка: ${car.name}, Номер: ${car.number}, Тип топлива: ${car.fuel}, Пробег: ${car.mileage}, Расход: ${car.consumption}, Расход зимой: ${car.consumptionWinter}`,
        })),
        records: response.data.records.all,
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getById(id) {
    return apiGet(`/cars/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: `${response.data.name}; ${response.data.number}`,
          relations: [],
          inputs: [],
          checkboxes: [],
          dropdowns: [],
        };

        result.inputs.push({
          title: 'Марка машины',
          name: 'name',
          value: response.data.name,
        });

        result.inputs.push({
          title: 'Номер машины',
          name: 'number',
          value: response.data.number,
        });

        result.inputs.push({
          title: 'Пробег, км.',
          name: 'mileage',
          value: response.data.mileage,
        });

        result.inputs.push({
          title: 'Норма расхода топлива на 100 км. летом',
          name: 'consumption',
          value: response.data.consumption,
        });

        result.inputs.push({
          title: 'Норма расхода топлива на 100 км. зимой',
          name: 'consumptionWinter',
          value: response.data.consumptionWinter,
        });

        result.dropdowns.push({
          title: 'Вид топлива',
          name: 'fuel',
          values: ['ДТ-К5', 'АИ-95-К5'],
          selectedValue: response.data.fuel,
        });

        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  removeLinkedItem() {
    return null;
  },

  addLinkedItem() {
    return null;
  },

  deleteItem(id) {
    return apiDelete(`/cars/${id}`)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getFields() {
    return {
      relations: [],
      dropdowns: [
        {
          title: 'Вид топлива',
          name: 'fuel',
          values: ['ДТ-К5', 'АИ-95-К5'],
          selectedValue: null,
        },
      ],
      inputs: [
        {
          title: 'Марка машины',
          name: 'name',
        },
        {
          title: 'Номер машины',
          name: 'number',
        },
        {
          title: 'Пробег, км.',
          name: 'mileage',
        },
        {
          title: 'Норма расхода топлива на 100 км.',
          name: 'consumption',
        },
        {
          title: 'Норма расхода топлива на 100 км. (зимой)',
          name: 'consumptionWinter',
        }
      ],
      checkboxes: [],

    };
  },

  updateItem(id, body) {
    return apiPut(`/cars/${id}`, body)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  createItem(body) {
    return apiPost('/cars', body)
      .then(response => ({
        status: response.status,
        data: {
          ...response.data,
          id: response.data._id,
          name: `Марка: ${response.data.name}, Номер: ${response.data.number}, Тип топлива: ${response.data.fuel}, Пробег: ${response.data.mileage}, Расход: ${response.data.consumption}, Расход зимой: ${response.data.consumptionWinter}`,
        },
      }))
      .catch((error) => {
        console.log('error', error);
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/cars?search=${body.search}`)
      .then(response => response.data.docs.map(car => ({
        ...car,
        id: car._id,
        name: `Марка: ${car.name}, Номер: ${car.number}, Тип топлива: ${car.fuel}, Пробег: ${car.mileage}, Расход: ${car.consumption}, Расход зимой: ${car.consumptionWinter}`,
      })))
      .catch(error => console.log('Not Search', error));
  },
};
