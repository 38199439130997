import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const Paragraph = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: 24px;
  ${props => props.smallParagraph && css`
    /* font-family: 'MuseoSansCyrl light',sans-serif; */
    margin-top: 4px;
    font-size: 14px;
    color: ${colors.light_gray};
  `}
`;

export const Container = styled.div`
  margin-bottom: 12px;
`;

export const AddItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 32px;
  button {
    width: 20px;
    height: 20px;
    text-align: center;
    color: ${colors.primary};
    background-color: white;
    border: 1px solid ${colors.primary};
    cursor: pointer;
  }
`;

export const ListBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
