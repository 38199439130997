import {
  compose,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose';
import { connect } from 'react-redux';

// services
import RoleService from '../../services/RoleService';

// actions
import {
  saveUserInfoFirstNameData,
  saveUserInfoLastNameData,
  saveUserInfoLoginData,
  saveUserInfoPhoneNumberData,
  saveUserInfoEmailData,
  saveUserInfoPositionData,
  saveUserInfoAvatarData,
  saveUserInfoPasswordData,
  saveUserInfoPasswordRepeatData,
} from '../../store/actions/users';

import UserCardInfoView from './UserCardInfoView';

export default compose(
  connect(
    state => ({
      usersInfoFirstName: state.users.usersInfoFirstName,
      usersInfoLastName: state.users.usersInfoLastName,
      usersInfoLogin: state.users.usersInfoLogin,
      usersInfoPhoneNumber: state.users.usersInfoPhoneNumber,
      usersInfoEmail: state.users.usersInfoEmail,
      usersInfoPosition: state.users.usersInfoPosition,
      usersInfoAvatar: state.users.usersInfoAvatar,
      usersInfoPassword: state.users.usersInfoPassword,
      usersInfoPasswordR: state.users.usersInfoPasswordR,
    }),
    dispatch => ({
      saveUserInfoFirstNameData: items => dispatch(saveUserInfoFirstNameData(items)),
      saveUserInfoLastNameData: items => dispatch(saveUserInfoLastNameData(items)),
      saveUserInfoLoginData: items => dispatch(saveUserInfoLoginData(items)),
      saveUserInfoPhoneNumberData: items => dispatch(saveUserInfoPhoneNumberData(items)),
      saveUserInfoEmailData: items => dispatch(saveUserInfoEmailData(items)),
      saveUserInfoPositionData: items => dispatch(saveUserInfoPositionData(items)),
      saveUserInfoAvatarData: items => dispatch(saveUserInfoAvatarData(items)),
      saveUserInfoPasswordData: items => dispatch(saveUserInfoPasswordData(items)),
      saveUserInfoPasswordRepeatData: items => dispatch(saveUserInfoPasswordRepeatData(items)),
    }),
  ),
  withState('roles', 'setRoles', []),
  lifecycle({
    async componentDidMount() {
      const roles = await RoleService.getRoles();
      this.props.setRoles(roles);
    },
  }),

)(UserCardInfoView);
