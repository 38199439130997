import styled from 'styled-components';
import { base, screens, colors } from '../../../../styles';

export const ContentProfile = styled.div`
    max-width: ${base * 78}px;
    max-height: ${base * 29.75}px;
    // background: #FFFFFF;
`;
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 32px 0;
    @media (max-width: ${screens.screen_xs_max}) {
      margin: 0 0 24px 0;
    }
`;
export const PagePasswordTitle = styled.div`
    font-size: 46px;
    font-weight: 300;

    span{
        margin-right: 6px;
        &:last-child{
            color: ${colors.primary};;
        }
    }
`;
export const InputPassword = styled.div`
    max-height: ${base * 23}px;
    background: #FFFFFF;
    box-shadow: 1px 1px 32px rgba(0, 0, 0, 0.05);
    border-radius: ${base - 6}px;
    padding: ${base * 3.75}px;
    display: flex;
    div {
        background-color: white;
        display: inline;
    }
    input {
        border: 1px solid #9B9B9B;
        box-sizing: border-box;
        border-radius: 8px;
        height: 36px;
        background-color: white;
        font-size: 24px;
        margin-bottom: 12px;
    }
    @media (max-width: ${screens.screen_sm_max}) {
        display: flex;
        flex-direction: column;
    }
    @media (max-width: ${screens.screen_xs_max}) {
      padding: 24px;
    }
`;

export const UpBlock = styled.div`
    margin-right: 32px;
    // background: #ffffff;
    width: 50%;
    @media (max-width: ${screens.screen_sm_max}) {
        margin-right: 0;
    }
    @media (max-width: ${screens.screen_xs_max}) {
      width: 100%;
    }
`;
export const DownBlock = styled.div`
    width: 50%;
    margin-top: auto;
    @media (max-width: ${screens.screen_xs_max}) {
      width: 100%;
    }
`;

export const TitlePassword = styled.p`
    font-size: 16px;
    color: #ACACAC;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
`;

export const NewPassword = styled.div`
    span:last-child{
        color: #252525;
    }
`;

export const NewRepeatPassword = styled.div``;

export const PopupTitle = styled.div`
    min-width: 324px;
    max-width: 594px;
    div{
        position: fixed;
        cursor: pointer;
        left: 50%;
        transform: translateX(-50%);
        bottom: -100px;
        transition: bottom .3s ease-in-out;
        bottom: 20px;
        border-radius: 8px;
        font-size: 20px;
    }
`;

export const GlobalContent = styled.div``;
