/**
 * @flow
 */

import React from 'react';
import Calendar from 'rc-calendar';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { InputComponent, InputWrapper } from '../Input/InputStyled';

import {
    InputMaskAndCalendar,
} from './InputMaskAndCalendarStyled';
import {
    CalendarContainer,
} from '../RequestForm/RequestFormStyles';

type InputViewPropsType = {
    mask: String,
    formatChars: Object,
    value: any,
    valueCalendar: any,
    placeholder?: string,
    changed: (any) => void,
    paymentDateCalendar: (any) => void,
    setPaymentDateCalendar: (any) => void,
    formatStr: String,
    onFocusChanged: (any) => void,
    onChangeCalendar: (any) => void,
    onClearCalendar: (any) => void,
};

export default function InputView(props: InputViewPropsType) {
  return (
    <InputMaskAndCalendar>
        <InputMask
            mask={props.mask}
            formatChars={props.formatChars}
            value={props.value}
            onChange={(event) => {
                props.changed(event);
            }}
            placeholder={props.placeholder}
            onFocus={()=> {
                props.onFocusChanged();
                props.setPaymentDateCalendar(true);
            }}
        >
            {inputProps => (
                <InputWrapper>
                    <InputComponent
                        {...inputProps}
                        valid
                    />
                </InputWrapper>
            )}
        </InputMask>
            
        {props.paymentDateCalendar
            && (
                <CalendarContainer>
                    <Calendar
                        onChange={(value) => {
                            props.onChangeCalendar(value, props.setPaymentDateCalendar);
                        }}
                        onClear={() => {
                            props.setPaymentDateCalendar(false);
                        }}
                        format={props.formatStr}
                        value={props.valueCalendar}
                    />
                </CalendarContainer>
                    )
        }
    </InputMaskAndCalendar>
  );
}

InputView.defaultProps = {
};
