import io from 'socket.io-client';

// eslint-disable-next-line import/no-mutable-exports
//* /  deploy server
const SOCKET_SERVER = 'https://agroslide-api.tapston.com/';
// */

/* /  dev server
const SOCKET_SERVER = 'https://agroslide-backend-dev.herokuapp.com/';
// */
let socket;

function connection() {
  socket = io.connect(SOCKET_SERVER);
}

export {
  connection,
  socket,
};
