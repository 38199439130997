/**
 * @flow
 */
import React from 'react';

import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import ruRu from 'rc-calendar/lib/locale/ru_RU';
import '../../styles/calendar/index.css';

import moment from 'moment';
import 'moment/locale/ru';


import { } from './CalendarStyled';

type CalendarViewPropsType = {
  eventOnOk: (any) => void
};

moment.locale('ru');
const now = moment();
now.utcOffset(3);
const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');
const formatStr = 'DD-MM-YYYY';

export default function CalendarView(props: CalendarViewPropsType) {
  return (
    <RangeCalendar
      showToday
      dateInputPlaceholder={['Дата начала', 'Дата конца']}
      locale={ruRu}
      showOk
      showClear
      format={formatStr}
      onOk={(value) => { props.eventOnOk(value); }}
      renderFooter={() => <span />}
    />
  );
}
