import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const TextArea = styled.textarea`
  background-color: ${colors.white};
  width: calc(100% - ${base * 2}px);
  min-height: 150px;
  font-size: 16px;
  padding: 10px 12px;
  border: 1px solid #efefef;
  border-radius: 6px;
`;
