import styled from 'styled-components';

import { base, screens, colors } from '../../../../styles';

export const ContentPersonal = styled.div`
    max-width: ${base * 78}px;
    max-height: ${base * 60.5}px;
`;

export const InfoTitle = styled.div`
    font-size: 46px;
    font-weight: 300;
    color: #252525;
    span{
        margin-right: ${base - 6}px;
        &:last-child{
            color: ${colors.primary};;
        }
    }
    @media (max-width: ${screens.screen_xs_max}) {
      margin-bottom: 24px;
    }
`;

export const InfoMain = styled.div`
    max-height: ${base * 53.75}px;
    padding: ${base * 3.75}px;
    background: #FFFFFF;
    box-shadow: 1px 1px 32px rgba(0, 0, 0, 0.05);
    border-radius: ${base - 6}px;
    @media (max-width: ${screens.screen_xs_max}) {
      max-height: ${base * 90.75}px;
      padding: 24px;
    }
`;

export const Header = styled.div`
    margin-bottom: 24px;
`;

export const UserAvatar = styled.div`
    height: ${base * 8}px;
    width: ${base * 8}px;
    border-radius: 50%;
    background-color: #fff;
    background: url(${props => props.avatar});
    background-size: cover;
    background-position: 50%;
    margin: auto;
    margin-bottom: 12px;
`;

export const OptionProfile = styled.div`
    display: flex;
    justify-content: center;
`;

export const DownloadAvatar = styled.div`
    width: 20px;
    height: 20px;
    background: url(${require('../../../../assets/icons/upload.svg')});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    margin-right: ${base + 4}px;
    input[type="file"] {
        width: ${base * 2.5}px;
        height: ${base * 2.5}px;
        opacity: 0;
        cursor: pointer;
    }
`;

export const DeleteAvatar = styled.div`
    width: 20px;
    height: 20px;
    background: url(${require('../../../../assets/icons/delete.svg')});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
`;

export const Main = styled.div`
    display: flex;
    @media (max-width: ${screens.screen_xs_max}) {
      flex-direction: column;
    }
`;

export const LeftBlockInfo = styled.div`
    width: 100%;
    margin-right: 32px;
    div{
        margin-bottom: ${base * 2.75}px;
        background-color: white;
        border: 1px solid #9B9B9B;
        &:last-child{
            margin-bottom: 0;
        }
        &:nth-child(4n){
            background-color: #F2F2F2;
            border: none;
        }
    }
    input{
        box-sizing: border-box;
        border-radius: ${base - 4}px;
        font-size: 18px;
    }
    @media (max-width: ${screens.screen_xs_max}) {
      margin-bottom: ${base * 2.75}px;
    }
`;

export const RightBlockInfo = styled.div`
    width: 100%;
    div{
        margin-bottom: ${base * 2.75}px;
        background-color: white;
        border: 1px solid #9B9B9B;
        &:nth-child(4n){
            background-color: #F2F2F2;
            border: none;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    input{
        box-sizing: border-box;
        border-radius: ${base - 4}px;
        font-size: 18px;
    }
`;

export const TitleInfoProgile = styled.p`
    font-size: ${base + 4}px;
    color: #ACACAC;
    margin-bottom: ${base - 6}px;
`;

export const InfoHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 32px 0;
    @media (max-width: ${screens.screen_xs_max}) {
      margin: 0 0 24px 0;
    }
`;

export const PopupSaveResult = styled.div`
    min-width: 324px;
    max-width: 594px;
    div{
        position: fixed;
        cursor: pointer;
        left: 50%;
        transform: translateX(-50%);
        bottom: -100px;
        transition: bottom .3s ease-in-out;
        bottom: 20px;
        border-radius: 8px;
        font-size: 20px;
    }
`;
