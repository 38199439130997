import {
  apiGet,
  apiPut,
  apiPost,
  apiDelete,
} from '../ApiService';

// directory services
import {
  UserPayersService,
  ConsigneeService,
  ShipperService,
} from '../index';

import { ROLES_NAME } from '../../constants';

export default {
  getApiName() {
    return 'payers';
  },

  getItems(p = 1, params) {
    return apiGet(`/payers?page=${p}`, { params })
      .then(response => ({
        data: response.data.docs.map(item => ({
          id: item._id,
          ...item,
        })),
        records: response.data.pages.allRecords,
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getItem(id) {
    return apiGet(`/payer/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: response.data.name,
          counters: [],
        };
        const lengthConsignees = response.data.consignees.length;
        const lengthShippers = response.data.shippers.length;
        if (lengthConsignees > 0) {
          switch (lengthConsignees) {
            case 11:
              result.counters.push(`${lengthConsignees} грузополучателей`);
              break;
            case 1:
            case Number(`${Math.floor(lengthConsignees / 10)}1`):
              result.counters.push(`${lengthConsignees} грузополучатель`);
              break;
            case 2:
            case 3:
            case 4:
              result.counters.push(`${lengthConsignees} грузополучателя`);
              break;
            default:
              result.counters.push(`${lengthConsignees} грузополучателей`);
              break;
          }
        }
        if (lengthShippers > 0) {
          switch (lengthShippers) {
            case 11:
              result.counters.push(`${lengthShippers} грузоотправителей`);
              break;
            case 1:
            case Number(`${Math.floor(lengthShippers / 10)}1`):
              result.counters.push(`${lengthShippers} грузоотправитель`);
              break;
            case 2:
            case 3:
            case 4:
              result.counters.push(`${lengthShippers} грузоотправителя`);
              break;
            default:
              result.counters.push(`${lengthShippers} грузоотправителей`);
              break;
          }
        }
        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getById(id) {
    return apiGet(`/payer/${id}`)
      .then(async (response) => {
        const result = {
          id: response.data._id,
          name: response.data.name,
          relations: [
            {
              title: 'Грузополучатели',
              name: 'consignees',
              items: null,
              linkedService: ConsigneeService,
            },
            {
              title: 'Грузоотправители',
              name: 'shippers',
              items: null,
              linkedService: ShipperService,
            },
          ],
          users: [
            {
              title: 'Менеджеры',
              name: ROLES_NAME.manager,
              items: await UserPayersService.getLinkedUsers(response.data._id),
              linkedUserService: UserPayersService,
            },
          ],
          inputs: [],
          checkboxes: [],
        };

        if (response.data.consignees.length > 0) {
          result.relations[0].items = response.data.consignees.map(item => ({
            id: item._id,
            ...item,
          }));
        }

        if (response.data.shippers.length > 0) {
          result.relations[1].items = response.data.shippers.map(item => ({
            id: item._id,
            ...item,
          }));
        }

        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  removeLinkedItem(id, item, itemId) {
    let result = null;
    if (item === 'consignees') {
      result = apiPut(`/payer/${id}?delconsignee=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    } else if (item === 'shippers') {
      result = apiPut(`/payer/${id}?delshipper=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    }

    return result;
  },

  addLinkedItem(id, item, itemId) {
    let result = null;
    if (item === 'consignees') {
      result = apiPut(`/payer/${id}?addconsignee=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    } else if (item === 'shippers') {
      result = apiPut(`/payer/${id}?addshipper=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    }

    return result;
  },

  deleteItem(id) {
    return apiDelete(`/payer/${id}`)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getFields() {
    return {
      relations: [
        {
          title: 'Грузополучатели',
          name: 'consignees',
          items: null,
          linkedService: ConsigneeService,
        },
        {
          title: 'Грузоотправители',
          name: 'shippers',
          items: null,
          linkedService: ShipperService,
        },
      ],
      inputs: [
        {
          title: 'Наименование',
          name: 'name',
        },
      ],
      checkboxes: [],
    };
  },

  updateItem(id, body) {
    return apiPut(`/payer/${id}`, body)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  createItem(body) {
    return apiPost('/payer', body)
      // .then(response => ({
      //   status: response.status,
      //   data: {
      //     id: response.data._id,
      //     name: response.data.name,
      //     addresses: response.data.addresses,
      //   },
      // }))
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/payers?search=${body.search}`)
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        ...item,
      })))
      .catch(error => console.log('Not Search', error));
  },
};
