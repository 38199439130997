import {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
  } from '../ApiService';
  
  export default {
    getApiName() {
      return 'products';
    },
  
    getItems(p = 1, params) {
      return apiGet(`/products?page=${p}`, { params })
        .then(response => ({
          data: response.data.docs.map(products => ({
            ...products,
            id: products._id,
            name: `Название продукта ${products.name}, Описание ${(products.description) ? products.description : 'нет данных'}`,
          })),
          records: response.data.records.all,
        }))
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },


  
    getById(id) {
      return apiGet(`/products/${id}`)
        .then((response) => {
          const result = {
            id: response.data._id,
            name: `${response.data.name}`,
            relations: [],
            inputs: [],
            checkboxes: [],
            dropdowns: [],
          };
  
          result.inputs.push({
            title: 'Название продукта',
            name: 'name',
            value: response.data.name,
          });
  
          result.inputs.push({
            title: 'Описание',
            name: 'description',
            value: response.data.description,
          });
          console.log('result', result);
          return result;
        })
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },
  
    removeLinkedItem() {
      return null;
    },
  
    addLinkedItem() {
      return null;
    },
  
    deleteItem(id) {
      return apiDelete(`/products/${id}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },
  
    getFields() {
      return {
        relations: [],
        inputs: [
            {
              title: 'Название продукта',
              name: 'name',
            },
            {
              title: 'Описание',
              name: 'description',
            },
          ],
          checkboxes: [],
          dropdowns: [],
      };
    },
  
    updateItem(id, body) {
      return apiPut(`/products/${id}`, body)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    },
  
    createItem(body) {
      return apiPost('/products', body)
        .then(response => ({
          status: response.status,
          data: {
            ...response.data,
            id: response.data._id,
            name: `Название продукта ${response.data.name}, Описание ${response.data.description}`,
          },
        }))
        .catch((error) => {
          console.log('error', error);
          throw new Error(error.response.data.error.message);
        });
    },
  
    searchItem(body) {
      return apiGet(`/products?search=${body.search}`)
        .then(response => response.data.docs.map(products => ({
          ...products,
          id: products._id,
          name:`${products.name}, ${products.description}`,
        })))
        .catch(error => console.log('Not Search', error));
    },
  };
  