/* eslint-disable no-underscore-dangle */
import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';

import HistoryView from './HistoryView';

export default compose(
  withState('viewCalendar', 'setViewCalendar', false),
  withHandlers({
    changeCalendarView: props => () => {
      if (!props.viewCalendar) {
        props.setViewCalendar(true);
      } else {
        props.setViewCalendar(false);
      }
    },
    changeDateRangeForHistory: props => (dates) => {
      if (dates) {
        props.setViewCalendar(false);
        let dateRange = [
          `${dates[0]._d.getFullYear()}-${dates[0]._d.getMonth() + 1}-${dates[0]._d.getDate()}`,
          `${dates[1]._d.getFullYear()}-${dates[1]._d.getMonth() + 1}-${dates[1]._d.getDate()}`,
        ];
        try {
          props.setViewCalendar(false);
          dateRange = JSON.stringify(dateRange);
          props.changeDateHistory(dateRange);
        } catch (err) {
          throw new Error(err);
        }
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.changeDateRangeForHistory(null);
    },
  }),
)(HistoryView);
