/**
 * @flow
 */

import React from 'react';

import {
  ThemeProvider,
} from 'styled-components';
import {
  Button,
} from '../index';


import {
  Box,
  Top,
  Bottom,
  Info,
  Paragraph,
  Content,
} from './PayerInfoStyled';

type PayerInfoViewPropsType = {
  edit: any;
  name?: any,
  // itemToAdd: string,
  // setEdit: (boolean) => void,
  closeButton: () => void,
  children: any,
  create?: boolean,
  needUpdateButton?: boolean,
  updateAction: () => void,
  addAction: () => void,
  removeAction: () => void,
  createAction: () => void,
  createLinkedItem: () => void,
  showAddButton?: boolean,
  needPadding: any,
  modaleWindow?: Boolean,
  onlyView?: Boolean,
};

export default function PayerInfoView(props: PayerInfoViewPropsType) {
  const theme = {
    needPadding: props.needPadding,
  };
  return (
    <ThemeProvider
      theme={theme}
    >
      <Box modaleWindow={props.modaleWindow}>
        <Content>
          <Top>
            <Info>
              {props.name
                ? <Paragraph>{props.name}</Paragraph>
                : <Paragraph>Добавить</Paragraph>
            }
            </Info>
          </Top>
          {props.children}
        </Content>
        {
        !props.create && (
          <Bottom
            needUpdateButton={props.needUpdateButton}
            onlyView={props.onlyView}
          >
            {console.log('onlyView', props.onlyView)}
            {
              props.needUpdateButton && !props.showAddButton && !props.onlyView &&(
                <Button clicked={() => props.updateAction()}>Обновить</Button>
              )
            }
            {props.edit
              ? (props.showAddButton
                && (
                  <Button
                    clicked={() => {
                      props.addAction();
                    }}
                    maxWidth
                    mode="blue"
                  >
                    Добавить
                  </Button>
                ))
              : (<Button maxWidth mode="blue" clicked={() => props.closeButton()}>Закрыть</Button>)
            }
            {props.edit
              ? ((props.onlyView) ? null : <Button maxWidth mode="white" clicked={() => props.closeButton()}>Отменить</Button>)
              : ((props.onlyView) ? null : <Button maxWidth mode="white" clicked={() => props.removeAction()}>Удалить</Button>)
            }
          </Bottom>
        )
      }

        {
          props.create && (
            <Bottom>
              {props.edit
                ? (props.showAddButton
                  && (
                    <Button clicked={() => {
                      props.createLinkedItem();
                    }}
                    >
                      Добавить
                    </Button>
                  ))
                : <Button clicked={() => props.createAction()}>Добавить</Button>
              }
              {!props.modaleWindow ? <Button secondary clicked={() => props.closeButton()}>Отменить</Button>
                : null
              }
            </Bottom>
          )
        }
      </Box>
    </ThemeProvider>
  );
}

PayerInfoView.defaultProps = {
  name: '',
  create: false,
  needUpdateButton: false,
  showAddButton: false,
  modaleWindow: false,
  onlyView: false,
};
