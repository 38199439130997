import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const Cross = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  margin-left: 6px;
  position: absolute;
  height: 40px;
  overflow: hidden;
  ${props => !props.isActive && css`
    &>button {
      height: 0;
      margin: 0;
      display: none;
      background-size: 0%;
    }
    &>button:hover {
      background: url(${require('../../assets/icons/close-hover.svg')});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 0%;
      height: 0;
    }
  `}
  ${props => props.isActive && css`
    &>button{
      display: none;
    }
    &>button:hover{
      display
    }
  `}
`;

export const PayerForm = styled.div`
  height: 40px;
  width: 100%;
  padding-left: 24px;
  background: ${colors.white};
  font-weight: 500;
  display: flex;
  flex-direction:column;
  justify-content: center;
  font-size: 13px;
`;

export const Payer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 64px;
    background-color: ${colors.white};
  }
  &:nth-child(2n){
    ${PayerForm} {
      background: ${colors.primary_gray};
    };
    &::before {
      background-color: ${colors.primary_gray};
    }
    ${props => props.isActive && css`
      &::before {
        background-color: ${colors.primary};
      }
    `};
  };
  &:hover {
    ${Cross} {
      &>button{
        display: flex;
      }
      ${props => props.isActive && css`
        &>button{
          display: none;
        }
      `}
    };
  };
  ${props => props.isActive && css`
    &::before {
      background-color: ${colors.primary};
    }
  `};
`;

export const NameCompany = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CountersCompany = styled.div`
  display: flex;
  font-weight: normal;
  margin-top: 2px;
  font-size: 11px;
`;

export const Counter = styled.p`
  margin: 0;
  margin-right: 24px;
  color: #878787;
`;

export const Name = styled.p`
  margin: 0;
`;
