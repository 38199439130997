/**
 * @flow
 */
import React from 'react';

import {
  Form,
  CalendarStyle,
  HistoryControl,
  ListItems,
  ControlCalendar,
  HistoryTitle,
  MobileHistoryControl,
  MobileHistoryTitle,
  MobileNumberRequest,
  MobileButton,
} from './HistoryStyled';

import {
  Calendar,
  Button,
} from '../index';

type HistoryPropsType = {
  children: any,
  requestNumber?: number,
  viewCalendar?: boolean,
  changeDateRangeForHistory: any,
  changeCalendarView: () => void
};

export default function HistoryView(props: HistoryPropsType) {
  return (
    <Form>
      {(props.viewCalendar)
        ? <CalendarStyle><Calendar eventOnOk={props.changeDateRangeForHistory} /></CalendarStyle>
        : null}
      <MobileButton>
        <Button circle clicked={() => props.changeCalendarView()}>
          <img
            style={{ width: '14px' }}
            src={require('../../assets/icons/calendar-history-white.svg')}
            alt="calendar-history"
          />
        </Button>
      </MobileButton>
      <HistoryControl>
        <h2>История изменений</h2>
      </HistoryControl>
      <MobileHistoryControl>
        <MobileHistoryTitle>
          <h1>История</h1><h1 style={{ paddingLeft: '12px' }}>изменений</h1>
        </MobileHistoryTitle>
        <MobileNumberRequest>№{props.requestNumber}</MobileNumberRequest>
      </MobileHistoryControl>
      <ListItems>{props.children}</ListItems>
    </Form>
  );
}

HistoryView.defaultProps = {
  requestNumber: null,
  viewCalendar: false,
};
