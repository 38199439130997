/**
 * @flow
 */
import React from 'react';
import { ThemeProvider } from 'styled-components';

import {
  Button,
} from '../index';
import SideBarItem from './SideBarItem/SideBarItemContainer';
import {
  SideBarWrapper,
  SideBarExpander,
  Primary,
  SecondaryBar,
  SideBarLogo,
  SideBarAddBtn,
} from './SideBarStyled';

type SideBarViewPropsType = {
  isOpen?: boolean,
  secondaryBarItems: any,
  activeSecondaryItemHandler: (string) => void,
  activeSecondaryItem?: Object,
  secondaryBarHandler: (string) => void,
  menuOpenHandler: (any) => void,
  activeItem?: string,
  user: Object,
};

export default function SideBarView(props: SideBarViewPropsType) {
  const theme = {
    isOpen: props.isOpen,
  };
  let secondaryItems = null;

  if (props.secondaryBarItems.length > 0) {
    secondaryItems = props.secondaryBarItems.map(item => (
      <SideBarItem
        name={item.name}
        key={item.id}
        id={item.id}
        clicked={() => props.activeSecondaryItemHandler(item)}
        active={props.activeSecondaryItem && props.activeSecondaryItem.id === item.id}
      />
    ));
  }

  return (
    <ThemeProvider theme={theme}>
      <SideBarWrapper className="side-bar">
        <Primary>
          <SideBarLogo
            logoPath={require('../../assets/icons/logo-black.png')}
          />
          <SideBarItem
            icon={
              props.activeItem === 'task'
                ? require('../../assets/icons/wheel.svg')
                : require('../../assets/icons/wheel-active.svg')
            }
            name="Задачи водителей"
            clicked={() => props.secondaryBarHandler('task')}
            active={props.activeItem === 'task'}
          />
          <SideBarItem
            icon={
              props.activeItem === 'refueling'
                ? require('../../assets/icons/gas-station-active.svg')
                : require('../../assets/icons/gas-station.svg')
            }
            name="Заправки"
            clicked={() => props.secondaryBarHandler('refueling')}
            active={props.activeItem === 'refueling'}
          />
          <SideBarItem
            icon={
              props.activeItem === 'shifts'
                ? require('../../assets/icons/time-active.svg')
                : require('../../assets/icons/time.svg')
            }
            name="Смены"
            clicked={() => props.secondaryBarHandler('shifts')}
            active={props.activeItem === 'shifts'}
          />
          <SideBarItem
            icon={
              props.activeItem === 'directories'
                ? require('../../assets/icons/folder_active.svg')
                : require('../../assets/icons/folder.svg')
            }
            name="Справочники"
            clicked={() => props.secondaryBarHandler('directories')}
            active={props.activeItem === 'directories'}
          />
          <SideBarItem
            icon={
              props.activeItem === 'messages'
                ? require('../../assets/icons/support-active.svg')
                : require('../../assets/icons/support.svg')
            }
            name="Сообщения"
            clicked={() => props.secondaryBarHandler('messages')}
            active={props.activeItem === 'messages'}
          />
          {
            props.user.roleRightName === 'admin' && (
              <SideBarItem
                icon={
                  props.activeItem === 'users'
                    ? require('../../assets/icons/users_active.svg')
                    : require('../../assets/icons/users.svg')
                }
                name="Пользователи"
                clicked={() => props.secondaryBarHandler('users')}
                active={props.activeItem === 'users'}
              />
            )
          }
          <SideBarExpander
            onClick={props.menuOpenHandler}
          >
            <SideBarItem
              icon={
                props.isOpen
                  ? require('../../assets/icons/expander_active.svg')
                  : require('../../assets/icons/expander.svg')
              }
            />
          </SideBarExpander>
        </Primary>
        {
          secondaryItems && (
            <SecondaryBar>
              {secondaryItems}
            </SecondaryBar>
          )
        }
      </SideBarWrapper>
    </ThemeProvider>
  );
}

SideBarView.defaultProps = {
  isOpen: false,
  activeItem: '',
  activeSecondaryItem: {},
};
