import styled from 'styled-components';
import { base, colors, screens } from '../../styles';

export const Form = styled.div`
  background: ${colors.primary_gray};
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: ${base * 1.333333333}px;

  @media (max-width: ${screens.screen_md_max}) {
    background: ${colors.white};
    border-radius: 6px;
  }
`;

export const CalendarStyle = styled.div`
  position: absolute;
  z-index: 3;
  top: 80px;
  left: 12px;

  .rc-calendar-panel {
    display: flex;
    flex-direction: column;
  }

  .rc-calendar-range {
    width: 256px;
  }

  @media (max-width: ${screens.screen_md_max}) {
    .rc-calendar-range {
      width: 502px;
    }
  }
`;

export const HistoryControl = styled.div`
  display: block;
  margin: 0 0 20px 20px;

  @media (max-width: ${screens.screen_md_max}) {
    display: none;
  }
`;

export const ListItems = styled.div`
  margin: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

export const ControlCalendar = styled.button`
  display: flex;
  align-items: center;
  color: ${colors.history_gray};
  background-color: ${colors.primary_gray};
  outline: none;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: ${base + 2}px;
  border: 1px solid #9b9b9b;
  @media (max-width: ${screens.screen_md_max}) {
    display: none;
  }
`;

export const HistoryTitle = styled.div`
  margin: 0 0 8px 0;
  color: ${colors.history_gray};

  @media (max-width: ${screens.screen_md_max}) {
    display: none;
  }
`;

export const MobileHistoryControl = styled.div`
  display: none;
  margin: 0 0 20px 20px;

  h1 {
    margin: 0px;
  }

  @media (max-width: ${screens.screen_md_max}) {
    display: block;
  }
`;

export const MobileHistoryTitle = styled.div`
  display: none;

  @media (max-width: ${screens.screen_md_max}) {
    display: flex;
    flex-direction: row;
  }
`;

export const MobileNumberRequest = styled.div`
  display: none;
  margin-top: 12px;

  @media (max-width: ${screens.screen_md_max}) {
    display: block;
  }
`;

export const MobileButton = styled.div`
  display: none;
  position: fixed;
  z-index: 3;
  bottom: 12px;
  right: 12px;

  @media (max-width: ${screens.screen_md_max}) {
    display: block;
  }
`;
