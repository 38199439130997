import styled from 'styled-components';

import { base, colors } from '../../styles';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: ${base * 36.5}px;
  width: 100%;
  box-sizing: border-box;

  font-weight: 900;
`;

export const InputMargin = styled.div`
  margin-bottom: 18px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 32px;
  border-radius: 6px;

  input {
    font-size: ${base * 1.333333}px
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0 0;

  button {
    transition: all 0.3s ease-in-out;
    justify-content: center;
    width: 100%;
    text-align: center;
    &:hover {
      background-color: ${colors.primary_hover}
    }
  }

  @media (max-width: 800px) {
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const FormHeading = styled.h4`
  margin: 0;
  color: ${colors.black};
  font-size: 24px;
  margin-bottom: ${base * 1.8}px;
  margin-top: -3px;
`;
