/**
 * @flow
 */

import React from 'react';

import {
  ModaleWindow,
  ModaleWindowClose,
  ModaleWindowBackDrop,
} from './ModaleWindowStyled';

type ModaleWindowViewPropsType = {
    width: any,
    height: any,
    status?: boolean,
    closeAction: () => void,
    childred: any,
};

export default function ModaleWindowView(props: ModaleWindowViewPropsType) {
  return (
    <div>
      <ModaleWindow
        width={props.width}
        height={props.height}
        status={props.status}
      >
        <ModaleWindowClose onClick={() => props.closeAction()}>
          <img
            style={{ width: '14px' }}
            src={require('../../assets/icons/close-white.svg')}
            alt="close"
          />
        </ModaleWindowClose>
        {props.children}
      </ModaleWindow>
      <ModaleWindowBackDrop status={props.status} />
    </div>
  );
}

ModaleWindow.defaultProps = {
  width: 300,
  height: 300,
  status: false,
};
