/**
 * @flow
 */
import React from 'react';

import {
    TextArea,
} from './TextAreaStyled';

type TextAreaViewPropsType = {
    value: any,
  disabled: Boolean,
};

export default function TextAreaView(props: TextAreaViewPropsType) {
  return (
    <TextArea disabled={props.disabled} value={props.value}/>
  );
}

TextAreaView.defaultProps = {
    disabled: false,
};